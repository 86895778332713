export default {
  zh: {
    text1: '军事行业翻译解决方案',
    text2:
      '我们的优译信息军事行业翻译解决方案专注于为国防、军事技术和安全领域的客户提供高质量的翻译和本地化服务。无论是军事报告、技术文档、训练材料还是国际合作文件，我们都能够为您提供准确的翻译，确保信息的机密性、一致性和国际传达。',
    text3: '咨询服务',
    text4: '特点',
    text5: '军事专业知识',
    text6: '我们的翻译团队拥有军事领域的专业知识，了解军事术语和领域内的文化背景',
    text7: '军事报告翻译',
    text8: '为军事报告、情报分析和战略计划等提供翻译，确保军事信息在不同语言中的一致性和准确性',
    text9: '技术文档翻译',
    text10: '为军事技术文档、装备说明书等提供翻译支持，支持军事技术传播和合作',
    text11: '训练材料翻译',
    text12: '提供军事训练材料、演示文稿等翻译，促进军事人员的跨国培训',
    text13: '国际合作文件翻译',
    text14: '为国际军事合作文件、协议等提供翻译，支持国际间的军事合作',
    text15: '术语管理',
    text16: '管理军事领域的术语数据库，确保在不同语言版本中使用一致的军事术语',
    text17: '价值',
    text18: '机密性保护',
    text19: '我们严格遵守保密协议，确保军事信息在翻译过程中的机密性和安全性。',
    text20: '国际合作',
    text21: '准确的翻译有助于国际军事合作和联合行动，促进国际安全和稳定。',
    text22: '技术传播',
    text23: '为军事技术文档提供翻译，支持技术信息在国际范围内的传播和合作。',
    text24: '训练支持',
    text25: '提供训练材料翻译，帮助军事人员进行跨国培训和合作。',
    text26: '优势',
    text27: '军事专业团队',
    text28: '我们的团队由军事领域的专业翻译人员组成，能够确保军事翻译的准确性和机密性。',
    text29: '我们管理军事领域术语数据库，确保在不同语言版本中使用一致的军事术语。',
    text30: '在处理敏感军事信息时，我们严格遵守保密协议，确保信息安全。',
    text31: '本地化支持',
    text32: '考虑不同国家和地区的文化和军事背景，确保翻译内容适应各种情况。',
    text33: '紧急支持',
    text34: '我们的团队能够在紧急情况下提供快速的修复和支持，确保您的信息传达及时。',
    text35: '我们致力于为国防和军事领域客户提供优秀的翻译支持，满足您的全球传播需求。',
    text36: '通过选择我们的军事行业翻译解决方案，您将获得专业、保密性高的服务，有助于您的军事信息在国际范围内安全传播和合作。'
  },
  en: {
    text1: 'Translation solutions for the military industry',
    text2:
      "Our Youyi Information Military Industry Translation Solution focuses on providing high-quality translation and localization services to customers in the fields of national defense, military technology, and security.Whether it's military reports, technical documents, training materials, or international cooperation documents, we can provide you with accurate translations to ensure the confidentiality, consistency, and international communication of information.",
    text3: 'consulting service',
    text4: 'characteristic',
    text5: 'Military expertise',
    text6:
      'Our translation team has professional knowledge in the military field, understanding military terminology and cultural backgrounds within the field',
    text7: 'Translation of Military Reports',
    text8:
      'Provide translation for military reports, intelligence analysis, and strategic plans, ensuring consistency and accuracy of military information in different languages',
    text9: 'Translation of technical documents',
    text10:
      'Provide translation support for military technical documents, equipment manuals, etc., and support military technology dissemination and cooperation',
    text11: 'Translation of training materials',
    text12: 'Provide translation of military training materials, presentations, and other materials to promote cross-border training of military personnel',
    text13: 'Translation of international cooperation documents',
    text14: 'Provide translation for international military cooperation documents, agreements, etc., and support international military cooperation',
    text15: 'Term management',
    text16: 'Manage terminology databases in the military field to ensure consistent use of military terminology across different language versions',
    text17: 'value',
    text18: 'confidentiality protection ',
    text19:
      'We strictly abide by the confidentiality agreement to ensure the confidentiality and security of military information during the translation process.',
    text20: 'international co-operation',
    text21: 'Accurate translation contributes to international military cooperation and joint operations, promoting international security and stability.',
    text22: 'Technology dissemination',
    text23:
      'Provide translation for military technical documents, support the dissemination and cooperation of technical information on an international scale.',
    text24: 'Training support',
    text25: 'Provide translation of training materials to assist military personnel in cross-border training and cooperation.',
    text26: 'advantage',
    text27: 'Military Professional Team',
    text28: 'Our team is composed of professional translators in the military field, who can ensure the accuracy and confidentiality of military translations.',
    text29: 'We manage a military terminology database to ensure consistent use of military terminology across different language versions.',
    text30: 'When dealing with sensitive military information, we strictly adhere to confidentiality agreements to ensure information security.',
    text31: 'Localization support',
    text32:
      'Consider the cultural and military backgrounds of different countries and regions to ensure that the translated content adapts to various situations.',
    text33: 'Emergency support',
    text34: 'Our team is able to provide rapid repair and support in emergency situations, ensuring timely communication of your information.',
    text35:
      'We are committed to providing excellent translation support to clients in the defense and military fields to meet your global communication needs.',
    text36:
      'By choosing our military industry translation solution, you will receive professional and highly confidential services that help ensure the safe dissemination and cooperation of your military information on an international scale.'
  },
  ja: {
    text1: '軍事業界翻訳ソリューション',
    text2:
      'デルの優訳情報軍事業界翻訳ソリューションは、国防、軍事技術、安全分野のお客様に質の高い翻訳とローカライズサービスを提供することに重点を置いています。軍事報告書、技術文書、訓練資料、国際協力文書にかかわらず、情報の機密性、一貫性、国際的な伝達を確実にするための正確な翻訳を提供することができます。',
    text3: 'コンサルティングサービス',
    text4: '特徴',
    text5: '軍事専門知識',
    text6: '私たちの翻訳チームは軍事分野の専門知識を持ち、軍事用語と分野内の文化的背景を理解しています。',
    text7: '軍事報告書の翻訳',
    text8: '軍事報告、情報分析、戦略計画などに翻訳を提供し、異なる言語における軍事情報の一貫性と正確性を確保する',
    text9: 'テクニカルドキュメントの翻訳',
    text10: '軍事技術文書、装備説明書などの翻訳サポートを提供し、軍事技術の伝播と協力をサポートする',
    text11: '訓練材料の翻訳',
    text12: '軍事訓練資料、プレゼンテーションなどの翻訳を提供し、軍事人員の多国籍訓練を促進する',
    text13: '国際協力文書翻訳',
    text14: '国際軍事協力文書、協議などの翻訳を提供し、国際間の軍事協力を支持する',
    text15: '用語管理',
    text16: '軍事分野の用語データベースを管理し、異なる言語バージョンで一貫した軍事用語を使用することを確実にする',
    text17: 'の価値判断を',
    text18: '機密保護',
    text19: 'デルは機密保持契約を厳格に遵守し、翻訳中の軍事情報の機密性と安全性を確保しています。',
    text20: '国際協力',
    text21: '正確な翻訳は国際軍事協力と共同行動に役立ち、国際安全と安定を促進する。',
    text22: '技術伝播',
    text23: '軍事技術文書の翻訳を提供し、技術情報の国際的な伝播と協力をサポートする。',
    text24: 'トレーニングサポート',
    text25: '訓練資料の翻訳を提供し、軍事関係者の多国籍訓練と協力を支援する。',
    text26: '利点',
    text27: '軍事専門チーム',
    text28: '私たちのチームは軍事分野の専門翻訳者で構成されており、軍事翻訳の正確性と機密性を確保することができます。',
    text29: '私たちは軍事分野の用語データベースを管理し、異なる言語バージョンで一貫した軍事用語を使用することを保証します。',
    text30: '敏感な軍事情報を処理する際には、機密保持契約を厳格に遵守し、情報の安全を確保します。',
    text31: 'ローカリゼーションのサポート',
    text32: '異なる国や地域の文化や軍事的背景を考慮し、翻訳内容がさまざまな状況に適応することを確保する。',
    text33: '緊急サポート',
    text34: 'デルのチームは、緊急時に迅速な修復とサポートを提供し、お客様の情報をタイムリーに伝えることができます。',
    text35: 'SDLは、国防分野および軍事分野のお客様に優れた翻訳サポートを提供し、世界中の伝播ニーズに対応するよう努めています。',
    text36:
      '私たちの軍事業界翻訳ソリューションを選択することで、専門的で機密性の高いサービスを受けることができ、あなたの軍事情報の国際的な安全な伝播と協力に役立ちます。'
  },
  ko: {
    text1: '군사 산업 번역 솔루션',
    text2:
      '우리의 우수한 번역 정보 군사 산업 번역 솔루션은 국방, 군사 기술 및 보안 분야의 고객에게 고품질의 번역 및 현지화 서비스를 제공하는 데 중점을 둡니다.군사 보고서, 기술 문서, 훈련 자료 또는 국제 협력 문서에 관계없이 정확한 번역을 제공하여 정보의 기밀성, 일관성 및 국제 전달을 보장할 수 있습니다.',
    text3: '컨설팅 서비스',
    text4: '특징',
    text5: '군사 전문 지식',
    text6: '우리 번역팀은 군사 분야의 전문 지식을 가지고 있으며, 군사 용어와 분야 내의 문화 배경을 이해하고 있다',
    text7: '군사 보고서 번역',
    text8: '군사 보고서, 정보 분석 및 전략 계획 등의 번역을 제공하여 다양한 언어에서 군사 정보의 일관성과 정확성을 보장',
    text9: '기술 문서 번역',
    text10: '군사 기술 문서, 장비 설명서 등의 번역 지원, 군사 기술 전파 및 협력 지원',
    text11: '훈련 자료 번역',
    text12: '군사 훈련 자료, 프레젠테이션 등의 번역을 제공하여 군사 인원의 다국적 훈련을 촉진하다',
    text13: '국제 협력 문서 번역',
    text14: '국제 군사 협력 문서, 협의 등에 번역을 제공하고, 국제 간의 군사 협력을 지지한다',
    text15: '용어 관리',
    text16: '군사 분야의 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일관된 군사 용어를 사용하도록 보장',
    text17: '가치',
    text18: '기밀성 보호',
    text19: '우리는 기밀 유지 계약을 엄격히 준수하여 번역 과정에서 군사 정보의 기밀성과 안전성을 확보합니다.',
    text20: '국제 협력',
    text21: '정확한 번역은 국제 군사 협력과 공동 행동에 도움이 되며 국제 안보와 안정을 촉진합니다.',
    text22: '기술 전파',
    text23: '군사기술문서에 번역을 제공하고 기술정보의 국제적범위에서의 전파와 협력을 지원한다.',
    text24: '훈련 지원',
    text25: '훈련 자료 번역을 제공하여 군사 인원의 다국적 훈련과 협력을 돕는다.',
    text26: '이점',
    text27: '군사 전문 팀',
    text28: '우리 팀은 군사 분야의 전문 번역가로 구성되어 군사 번역의 정확성과 기밀성을 확보할 수 있다.',
    text29: '우리는 군사 분야 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일관된 군사 용어를 사용하도록 보장합니다.',
    text30: '민감한 군사 정보를 처리할 때, 우리는 비밀 유지 협의를 엄격히 준수하여 정보 안전을 확보한다.',
    text31: '현지화 지원',
    text32: '서로 다른 국가와 지역의 문화와 군사적 배경을 고려하여 번역 내용이 각종 상황에 적응하도록 확보한다.',
    text33: '긴급 지원',
    text34: '우리 팀은 긴급 상황에서 신속한 복구와 지원을 제공하여 귀하의 정보가 적시에 전달되도록 보장할 수 있습니다.',
    text35: '우리는 국방 및 군사 분야의 고객에게 우수한 번역 지원을 제공하여 귀하의 글로벌 전파 수요를 만족시키기 위해 노력합니다.',
    text36:
      '우리의 군사 산업 번역 솔루션을 선택함으로써 당신은 전문적이고 비밀이 높은 서비스를 받게 될 것이며, 당신의 군사 정보가 국제적으로 안전하게 전파되고 협력하는 데 도움이 될 것입니다.'
  },
  de: {
    text1: 'Übersetzungslösungen für die Militärindustrie',
    text2:
      'Unsere Youyi Information Military Industry Translation Solution konzentriert sich auf die Bereitstellung qualitativ hochwertiger Übersetzungs- und Lokalisierungsdienste für Kunden in den Bereichen Landesverteidigung, Militärtechnologie und Sicherheit.Ob militärische Berichte, technische Dokumente, Schulungsmaterialien oder Dokumente der internationalen Zusammenarbeit – wir können Ihnen genaue Übersetzungen liefern, um die Vertraulichkeit, Konsistenz und internationale Kommunikation von Informationen zu gewährleisten.',
    text3: 'Beratung',
    text4: 'Charakteristik',
    text5: 'Militärische Expertise',
    text6:
      'Unser Übersetzungsteam verfügt über Fachkenntnisse im militärischen Bereich und versteht militärische Terminologie und kulturelle Hintergründe in diesem Bereich',
    text7: 'Übersetzung von Militärberichten',
    text8:
      'Bereitstellung von Übersetzungen für Militärberichte, nachrichtendienstliche Analysen und strategische Pläne, um Konsistenz und Genauigkeit militärischer Informationen in verschiedenen Sprachen zu gewährleisten',
    text9: 'Übersetzung technischer Dokumente',
    text10:
      'Übersetzungsunterstützung für militärische technische Dokumente, Ausrüstungshandbücher usw. und Unterstützung der Verbreitung und Zusammenarbeit militärischer Technologien',
    text11: 'Übersetzung von Schulungsmaterialien',
    text12:
      'Übersetzung von militärischen Schulungsmaterialien, Präsentationen und anderen Materialien zur Förderung der grenzüberschreitenden Ausbildung von Militärpersonal',
    text13: 'Übersetzung von Dokumenten der internationalen Zusammenarbeit',
    text14:
      'Übersetzen von Dokumenten, Vereinbarungen usw. für internationale militärische Zusammenarbeit und Unterstützung der internationalen militärischen Zusammenarbeit',
    text15: 'Terminverwaltung',
    text16:
      'Verwaltung von Terminologiedatenbanken im militärischen Bereich, um eine konsistente Verwendung militärischer Terminologie in verschiedenen Sprachversionen zu gewährleisten',
    text17: 'Wert',
    text18: 'Vertraulichkeitsschutz',
    text19:
      'Wir halten uns strikt an die Vertraulichkeitsvereinbarung, um die Vertraulichkeit und Sicherheit militärischer Informationen während des Übersetzungsprozesses zu gewährleisten.',
    text20: 'internationale Zusammenarbeit',
    text21:
      'Eine genaue Übersetzung trägt zur internationalen militärischen Zusammenarbeit und gemeinsamen Operationen bei und fördert die internationale Sicherheit und Stabilität.',
    text22: 'Verbreitung von Technologien',
    text23:
      'Bereitstellung von Übersetzungen militärischer technischer Dokumente, Unterstützung der Verbreitung und Zusammenarbeit technischer Informationen auf internationaler Ebene.',
    text24: 'Ausbildungsförderung',
    text25: 'Übersetzung von Schulungsmaterialien zur Unterstützung des Militärpersonals bei grenzüberschreitender Ausbildung und Zusammenarbeit.',
    text26: 'Vorteil',
    text27: 'Militärisches Berufsteam',
    text28:
      'Unser Team besteht aus professionellen Übersetzern im militärischen Bereich, die die Genauigkeit und Vertraulichkeit von militärischen Übersetzungen gewährleisten können.',
    text29:
      'Wir verwalten eine militärische Terminologiedatenbank, um eine konsistente Verwendung militärischer Terminologie in verschiedenen Sprachversionen zu gewährleisten.',
    text30:
      'Beim Umgang mit sensiblen militärischen Informationen halten wir uns strikt an Vertraulichkeitsvereinbarungen, um die Informationssicherheit zu gewährleisten.',
    text31: 'Unterstützung bei der Lokalisierung',
    text32:
      'Berücksichtigen Sie die kulturellen und militärischen Hintergründe verschiedener Länder und Regionen, um sicherzustellen, dass sich die übersetzten Inhalte an verschiedene Situationen anpassen.',
    text33: 'Nothilfe',
    text34:
      'Unser Team ist in der Lage, eine schnelle Reparatur und Unterstützung in Notsituationen zu bieten, um sicherzustellen, dass Ihre Informationen rechtzeitig kommuniziert werden.',
    text35:
      'Wir sind bestrebt, Kunden im Verteidigungs- und Militärbereich exzellente Übersetzungsunterstützung zu bieten, um Ihre globalen Kommunikationsanforderungen zu erfüllen.',
    text36:
      'Wenn Sie sich für unsere Übersetzungslösung für die Militärindustrie entscheiden, erhalten Sie professionelle und vertrauliche Dienstleistungen, die Ihnen helfen, die sichere Verbreitung und Zusammenarbeit Ihrer militärischen Informationen auf internationaler Ebene zu gewährleisten.'
  },
  fr: {
    text1: "Solutions de traduction pour l'industrie militaire",
    text2:
      "Nos solutions de traduction pour l'industrie militaire se concentrent sur la fourniture de services de traduction et de localisation de haute qualité à nos clients dans les domaines de la défense, de la technologie militaire et de la sécurité.Qu'il s'agisse de rapports militaires, de documents techniques, de matériel d'entraînement ou de documents de coopération internationale, nous sommes en mesure de vous fournir des traductions précises qui garantissent la confidentialité, la cohérence et la communication internationale de vos informations.",
    text3: 'Services consultatifs',
    text4: 'Caractéristiques',
    text5: 'Expertise militaire',
    text6:
      'Notre équipe de traducteurs possède une expertise dans le domaine militaire et comprend la terminologie militaire et le contexte culturel dans le domaine',
    text7: 'Traduction de rapports militaires',
    text8:
      "Fournir des traductions pour les rapports militaires, l'analyse du renseignement et les plans stratégiques, etc., assurant la cohérence et l'exactitude de l'information militaire dans différentes langues",
    text9: 'Traduction de documents techniques',
    text10:
      "Soutien à la traduction de documents techniques militaires, d'instructions d'équipement, etc., soutien à la diffusion et à la coopération techniques militaires",
    text11: 'Traduction de matériel de formation',
    text12: 'Fournir des traductions de matériel de formation militaire, de présentations, etc., faciliter la formation transnationale du personnel militaire',
    text13: 'Traduction de documents de coopération internationale',
    text14:
      'Fournir des traductions pour les documents de coopération militaire internationale, les accords, etc., soutenir la coopération militaire internationale',
    text15: 'Gestion terminologique',
    text16:
      "Gestion d'une base de données terminologiques dans le domaine militaire, garantissant l'utilisation d'une terminologie militaire cohérente dans les différentes versions linguistiques",
    text17: 'Valeur',
    text18: 'Protection de la confidentialité',
    text19:
      'Nous respectons scrupuleusement les accords de confidentialité et assurons la confidentialité et la sécurité des informations militaires pendant le processus de traduction.',
    text20: 'Coopération internationale',
    text21:
      'Une traduction précise facilite la coopération militaire internationale et les opérations conjointes, contribuant ainsi à la sécurité et à la stabilité internationales.',
    text22: 'Diffusion de la technologie',
    text23:
      "Fournir des traductions de documents techniques militaires, soutenir la diffusion de l'information technique et la coopération à l'échelle internationale.",
    text24: 'Soutien à la formation',
    text25: 'Fournir des traductions de matériel de formation pour aider le personnel militaire dans la formation transnationale et la coopération.',
    text26: 'Avantages',
    text27: 'Équipe professionnelle militaire',
    text28:
      'Notre équipe, composée de traducteurs spécialisés dans le domaine militaire, est en mesure de garantir la précision et la confidentialité des traductions militaires.',
    text29:
      "Nous gérons la base de données terminologiques du domaine militaire, en veillant à ce qu'une terminologie militaire cohérente soit utilisée dans les différentes versions linguistiques.",
    text30:
      'Lorsque nous traitons des informations militaires sensibles, nous veillons à la sécurité des informations en respectant strictement les accords de confidentialité.',
    text31: 'Soutien à la localisation',
    text32:
      'Prenez en compte les contextes culturels et militaires des différents pays et régions et assurez - vous que le contenu traduit est adapté à chaque situation.',
    text33: "Soutien d'urgence",
    text34:
      "Notre équipe est en mesure de fournir une réparation rapide et un soutien en cas d'urgence, en veillant à ce que votre message soit transmis en temps opportun.",
    text35:
      "Nous nous engageons à fournir un excellent support de traduction à nos clients dans les domaines de la défense et de l'armée pour répondre à vos besoins de communication dans le monde entier.",
    text36:
      "En choisissant nos solutions de traduction pour l'industrie militaire, vous bénéficiez d'un service professionnel et hautement confidentiel qui contribue à la diffusion sécurisée et à la coopération de vos informations militaires à l'échelle internationale."
  },
  ru: {
    text1: 'Переводческие решения для военной промышленности',
    text2:
      'Наши решения по переводу для военной промышленности ориентированы на предоставление высококачественных услуг по переводу и локализации для клиентов в области обороны, военных технологий и безопасности.Будь то военные отчеты, технические документы, учебные материалы или документы международного сотрудничества, мы можем предоставить вам точный перевод, гарантируя конфиденциальность, последовательность и международную передачу информации.',
    text3: 'Консультативные услуги',
    text4: 'Особенности',
    text5: 'Военный опыт',
    text6: 'Наша команда переводчиков обладает опытом в военной области, знанием военной терминологии и культурных традиций в этой области',
    text7: 'Перевод военных отчетов',
    text8:
      'Перевод военных отчетов, разведывательного анализа и стратегических планов для обеспечения согласованности и точности военной информации на различных языках',
    text9: 'Перевод технической документации',
    text10:
      'Оказание переводческой поддержки военно - техническим документам, спецификациям оборудования и т.д., содействие распространению военной техники и сотрудничеству',
    text11: 'Перевод учебных материалов',
    text12: 'Перевод учебных материалов военного характера, презентаций и т.д. и содействие трансграничной подготовке военного персонала',
    text13: 'Перевод документов по международному сотрудничеству',
    text14: 'Перевод документов и соглашений о международном военном сотрудничестве, поддержка международного военного сотрудничества',
    text15: 'Терминология',
    text16:
      'Управление терминологической базой данных в военной области для обеспечения последовательного использования военной терминологии в различных языковых версиях',
    text17: 'Стоимость',
    text18: 'Защита конфиденциальности',
    text19: 'Мы строго соблюдаем соглашение о конфиденциальности, обеспечивая конфиденциальность и безопасность военной информации в процессе перевода.',
    text20: 'Международное сотрудничество',
    text21:
      'Точный перевод способствует международному военному сотрудничеству и совместным действиям по укреплению международной безопасности и стабильности.',
    text22: 'Распространение технологий',
    text23:
      'Обеспечивает перевод военно - технических документов, поддерживает распространение технической информации и сотрудничество в международном масштабе.',
    text24: 'Поддержка профессиональной подготовки',
    text25: 'Обеспечение перевода учебных материалов и оказание помощи военнослужащим в организации трансграничной подготовки и сотрудничества.',
    text26: 'Преимущество',
    text27: 'Группа военных специалистов',
    text28: 'Наша команда состоит из профессиональных переводчиков в военной области, способных обеспечить точность и конфиденциальность военных переводов.',
    text29:
      'Мы управляем терминологической базой данных в военной области, обеспечивая последовательное использование военной терминологии в различных языковых версиях.',
    text30: 'При обработке чувствительной военной информации мы строго соблюдаем соглашения о конфиденциальности и обеспечиваем информационную безопасность.',
    text31: 'Поддержка локализации',
    text32:
      'Учитывайте культурные и военные особенности различных стран и регионов, обеспечивая, чтобы содержание перевода было адаптировано к различным ситуациям.',
    text33: 'Экстренная поддержка',
    text34: 'Наша команда может обеспечить быстрое восстановление и поддержку в чрезвычайных ситуациях, гарантируя своевременную передачу вашей информации.',
    text35:
      'Мы стремимся обеспечить отличную переводческую поддержку для наших клиентов в оборонном и военном секторах, чтобы удовлетворить ваши глобальные коммуникационные потребности.',
    text36:
      'Выбирая наши решения для перевода военной промышленности, вы получаете профессиональные и конфиденциальные услуги, которые способствуют безопасному распространению и сотрудничеству вашей военной информации в международном масштабе.'
  },
  th: {
    text1: 'โซลูชั่นการแปลอุตสาหกรรมทหาร',
    text2:
      'โซลูชั่นการแปลอุตสาหกรรมการทหารสำหรับข้อมูลการแปลที่ยอดเยี่ยมของเรามุ่งเน้นไปที่การให้บริการการแปลคุณภาพสูงและการแปลภาษาสำหรับลูกค้าในด้านการป้องกันประเทศเทคโนโลยีทางทหารและการรักษาความปลอดภัยไม่ว่าจะเป็นรายงานทางทหารเอกสารทางเทคนิคสื่อการฝึกอบรมหรือเอกสารความร่วมมือระหว่างประเทศเราสามารถให้การแปลที่ถูกต้องเพื่อให้แน่ใจว่าข้อมูลที่เป็นความลับสอดคล้องและการสื่อสารระหว่างประเทศ',
    text3: 'บริการให้คำปรึกษา',
    text4: 'คุณสมบัติ',
    text5: 'ความเชี่ยวชาญทางทหาร',
    text6: 'ทีมนักแปลของเรามีความเชี่ยวชาญในด้านการทหารและเข้าใจบริบททางวัฒนธรรมของคำศัพท์ทางทหารในสาขา',
    text7: 'แปลรายงานทางทหาร',
    text8: 'ให้บริการแปลรายงานทางทหารการวิเคราะห์ข่าวกรองและแผนยุทธศาสตร์ ฯลฯ เพื่อให้มั่นใจว่าข้อมูลทางทหารมีความสอดคล้องและแม่นยำในภาษาที่แตกต่างกัน',
    text9: 'การแปลเอกสารทางเทคนิค',
    text10: 'การสนับสนุนการแปลสำหรับเอกสารทางเทคนิคทางทหารคำแนะนำเกี่ยวกับอุปกรณ์และอื่น ๆ สนับสนุนการถ่ายทอดเทคโนโลยีทางทหารและความร่วมมือ',
    text11: 'แปลเอกสารการฝึกอบรม',
    text12: 'จัดหาล่ามสื่อการฝึกทหาร การนำเสนอ ฯลฯ เพื่อส่งเสริมการฝึกข้ามประเทศของบุคลากรทางทหาร',
    text13: 'การแปลเอกสารความร่วมมือระหว่างประเทศ',
    text14: 'การแปลเอกสารความร่วมมือทางทหารระหว่างประเทศ ความตกลง ฯลฯ เพื่อสนับสนุนความร่วมมือทางทหารระหว่างประเทศ',
    text15: 'การจัดการคำศัพท์',
    text16: 'จัดการฐานข้อมูลคำศัพท์ในพื้นที่ทางทหารเพื่อให้แน่ใจว่ามีการใช้คำศัพท์ทางทหารที่สอดคล้องกันในเวอร์ชันภาษาต่างๆ',
    text17: 'ความคุ้มค่า',
    text18: 'การป้องกันความลับทางเพศ',
    text19: 'เราปฏิบัติตามข้อตกลงการรักษาความลับอย่างเคร่งครัดเพื่อให้มั่นใจว่าข้อมูลทางทหารเป็นความลับและความปลอดภัยในระหว่างการแปล',
    text20: 'ความร่วมมือระหว่างประเทศ',
    text21: 'การแปลที่ถูกต้องก่อให้เกิดความร่วมมือทางทหารระหว่างประเทศและปฏิบัติการร่วม ส่งเสริมความมั่นคงและเสถียรภาพระหว่างประเทศ',
    text22: 'การสื่อสารเทคโนโลยี',
    text23: 'ให้บริการแปลเอกสารทางเทคนิคทางทหาร สนับสนุนการเผยแพร่และความร่วมมือด้านข้อมูลทางเทคนิคในระดับสากล',
    text24: 'การสนับสนุนการฝึกอบรม',
    text25: 'จัดหาล่ามสื่อการฝึกอบรมเพื่อช่วยเหลือบุคลากรทางทหารในการฝึกอบรมและความร่วมมือข้ามประเทศ',
    text26: 'ข้อดี',
    text27: 'ทีมงานมืออาชีพทางทหาร',
    text28: 'ทีมงานของเราประกอบด้วยนักแปลมืออาชีพในด้านการทหารซึ่งสามารถรับประกันความถูกต้องและความลับของการแปลทางทหาร',
    text29: 'เราจัดการฐานข้อมูลคำศัพท์ทางทหารเพื่อให้แน่ใจว่ามีการใช้คำศัพท์ทางทหารที่สอดคล้องกันในเวอร์ชันภาษาต่างๆ',
    text30: 'เมื่อจัดการกับข้อมูลทางทหารที่ละเอียดอ่อนเราปฏิบัติตามข้อตกลงการรักษาความลับอย่างเคร่งครัดและรับรองความปลอดภัยของข้อมูล',
    text31: 'การสนับสนุนการแปล',
    text32: 'พิจารณาบริบททางวัฒนธรรมและการทหารของประเทศและภูมิภาคต่าง ๆ เพื่อให้แน่ใจว่าเนื้อหาการแปลเหมาะสมกับสถานการณ์ต่าง ๆ',
    text33: 'การสนับสนุนฉุกเฉิน',
    text34: 'ทีมงานของเราสามารถให้การแก้ไขและการสนับสนุนที่รวดเร็วในกรณีฉุกเฉินเพื่อให้แน่ใจว่าข้อความของคุณได้รับการสื่อสารอย่างทันท่วงที',
    text35: 'เรามุ่งมั่นที่จะให้การสนับสนุนการแปลที่ยอดเยี่ยมแก่ลูกค้าในด้านการป้องกันประเทศและการทหารเพื่อตอบสนองความต้องการด้านการสื่อสารทั่วโลกของคุณ',
    text36:
      'ด้วยการเลือกโซลูชันการแปลอุตสาหกรรมการทหารของเราคุณจะได้รับบริการระดับมืออาชีพและเป็นความลับซึ่งจะช่วยให้ข้อมูลทางทหารของคุณเผยแพร่ได้อย่างปลอดภัยและทำงานร่วมกันในระดับสากล'
  },
  es: {
    text1: 'Soluciones de traducción para la industria militar',
    text2:
      'Nuestras soluciones de traducción de la industria militar de Youyi Information se centran en proporcionar servicios de traducción y localización de alta calidad a clientes en los campos de la defensa nacional, la tecnología militar y la seguridad.Ya sea informes militares, documentos técnicos, materiales de entrenamiento o documentos de cooperación internacional, podemos proporcionarle una traducción precisa para garantizar la confidencialidad, coherencia y comunicación internacional de la información.',
    text3: 'Servicios de consultoría',
    text4: 'Características',
    text5: 'Experiencia militar',
    text6: 'Nuestro equipo de traductores tiene experiencia en el campo militar, entiende la terminología militar y el contexto cultural en el campo.',
    text7: 'Traducción de informes militares',
    text8:
      'Traducción de informes militares, análisis de inteligencia y planes estratégicos, etc., para garantizar la coherencia y precisión de la información militar en diferentes idiomas',
    text9: 'Traducción de documentos técnicos',
    text10:
      'Proporcionar apoyo de traducción para documentos técnicos militares, instrucciones de equipo, etc., y apoyar la difusión y cooperación de tecnología militar.',
    text11: 'Traducción de materiales de formación',
    text12:
      'Proporcionar traducción de materiales de entrenamiento militar, presentaciones, etc., para promover la capacitación transnacional del personal militar.',
    text13: 'Traducción de documentos de cooperación internacional',
    text14: 'Proporcionar traducción de documentos y acuerdos de cooperación militar internacional y apoyar la cooperación militar internacional.',
    text15: 'Gestión de la terminología',
    text16:
      'Gestionar una base de datos de términos en el ámbito militar para garantizar el uso de términos militares consistentes en diferentes versiones lingüísticas',
    text17: 'Valor',
    text18: 'Protección de la confidencialidad',
    text19:
      'Cumplimos estrictamente el Acuerdo de confidencialidad y garantizamos la confidencialidad y seguridad de la información militar en el proceso de traducción.',
    text20: 'Cooperación internacional',
    text21:
      'Una traducción precisa contribuye a la cooperación militar internacional y a las operaciones conjuntas para promover la seguridad y la estabilidad internacionales.',
    text22: 'Difusión de la tecnología',
    text23: 'Proporcionar traducción para documentos técnicos militares y apoyar la difusión y cooperación de información técnica a nivel internacional.',
    text24: 'Apoyo a la formación',
    text25:
      'Proporcionar traducción de materiales de entrenamiento para ayudar al personal militar a llevar a cabo capacitación y cooperación transfronterizas.',
    text26: 'Ventaja',
    text27: 'Equipo profesional militar',
    text28:
      'Nuestro equipo está compuesto por traductores profesionales en el campo militar y puede garantizar la precisión y confidencialidad de la traducción militar.',
    text29:
      'Gestionamos una base de datos de términos militares para garantizar el uso de términos militares consistentes en diferentes versiones lingüísticas.',
    text30:
      'Al procesar información militar sensible, cumplimos estrictamente los acuerdos de confidencialidad para garantizar la seguridad de la información.',
    text31: 'Soporte localizado',
    text32:
      'Considerar los antecedentes culturales y militares de diferentes países y regiones para garantizar que el contenido de la traducción se adapte a las diversas situaciones.',
    text33: 'Apoyo urgente',
    text34: 'Nuestro equipo puede proporcionar reparaciones y apoyo rápidos en situaciones de emergencia para garantizar que su mensaje se transmita a tiempo.',
    text35:
      'Estamos comprometidos a proporcionar un excelente apoyo de traducción a nuestros clientes en el campo de la defensa nacional y el ejército para satisfacer sus necesidades de comunicación global.',
    text36:
      'Al elegir nuestras soluciones de traducción de la industria militar, obtendrá servicios profesionales y confidenciales que ayudarán a que su información militar se difunda y coopere de manera segura a nivel internacional.'
  },
  pt: {
    text1: 'Soluções de tradução para a indústria militar',
    text2:
      'Nossa solução de tradução da indústria militar da informação Youyi concentra-se no fornecimento de serviços de tradução e localização de alta qualidade para clientes nas áreas de defesa nacional, tecnologia militar e segurança.Seja relatórios militares, documentos técnicos, materiais de treinamento ou documentos de cooperação internacional, podemos fornecer traduções precisas para garantir a confidencialidade, consistência e comunicação internacional das informações.',
    text3: 'serviço de consultoria',
    text4: 'característica',
    text5: 'Peritos militares',
    text6: 'Nossa equipe de tradução tem conhecimento profissional no campo militar, compreendendo terminologia militar e origens culturais dentro do campo',
    text7: 'Tradução de Relatórios Militares',
    text8:
      'Fornecer tradução para relatórios militares, análises de inteligência e planos estratégicos, garantindo consistência e precisão das informações militares em diferentes idiomas',
    text9: 'Tradução de documentos técnicos',
    text10:
      'Fornecer suporte de tradução para documentos técnicos militares, manuais de equipamentos, etc., e apoiar a disseminação e cooperação de tecnologia militar',
    text11: 'Tradução de materiais de formação',
    text12: 'Fornecer tradução de materiais de treinamento militar, apresentações e outros materiais para promover treinamento transfronteiriço de militares',
    text13: 'Tradução de documentos de cooperação internacional',
    text14: 'Fornecer tradução para documentos de cooperação militar internacional, acordos, etc., e apoiar a cooperação militar internacional',
    text15: 'Gestão de prazos',
    text16:
      'Gerencie bancos de dados terminológicos no campo militar para garantir o uso consistente da terminologia militar em diferentes versões linguísticas',
    text17: 'valor',
    text18: 'protecção da confidencialidade',
    text19:
      'Cumprimos estritamente o acordo de confidencialidade para garantir a confidencialidade e segurança das informações militares durante o processo de tradução.',
    text20: 'cooperação internacional',
    text21:
      'A tradução precisa contribui para a cooperação militar internacional e operações conjuntas, promovendo a segurança e a estabilidade internacionais.',
    text22: 'Divulgação tecnológica',
    text23: 'Fornecer tradução de documentos técnicos militares, apoiar a divulgação e cooperação de informações técnicas em escala internacional.',
    text24: 'Apoio à formação',
    text25: 'Fornecer tradução de materiais de treinamento para ajudar o pessoal militar na formação e cooperação transfronteiriças.',
    text26: 'vantagem',
    text27: 'Equipa Profissional Militar',
    text28: 'Nossa equipe é composta por tradutores profissionais no campo militar, que podem garantir a precisão e confidencialidade das traduções militares.',
    text29: 'Gerenciamos um banco de dados de terminologia militar para garantir o uso consistente da terminologia militar em diferentes versões linguísticas.',
    text30: 'Ao lidar com informações militares sensíveis, aderimos estritamente a acordos de confidencialidade para garantir a segurança da informação.',
    text31: 'Suporte à localização',
    text32: 'Considere as origens culturais e militares de diferentes países e regiões para garantir que o conteúdo traduzido se adapte a várias situações.',
    text33: 'Apoio de emergência',
    text34: 'Nossa equipe é capaz de fornecer reparos rápidos e suporte em situações de emergência, garantindo a comunicação oportuna de suas informações.',
    text35:
      'Estamos empenhados em fornecer excelente suporte de tradução aos clientes nos campos da defesa e militar para atender às suas necessidades globais de comunicação.',
    text36:
      'Ao escolher nossa solução de tradução da indústria militar, você receberá serviços profissionais e altamente confidenciais que ajudam a garantir a disseminação segura e cooperação de suas informações militares em escala internacional.'
  }
}
