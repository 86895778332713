import { createRouter, createWebHistory, RouteLocationNormalized, Router } from 'vue-router'
import { CommonStore } from '@/store/modules/common'
import { Routes } from './router.config'

// 路由实例
const router: Router = createRouter({
  history: createWebHistory(),
  routes: Routes
})

// 检测发布版本与缓存版本是否一致
const handleDiffVersion = () => {
  // 当前发布版本号
  const currrentVersion = process.env.VUE_APP_VERSION
  // 当前本地缓存版本号
  const localVersion = localStorage.getItem('sysVersion')
  if (currrentVersion !== localVersion) {
    console.log('发布版本与缓存版本不一致')
    localStorage.setItem('sysVersion', currrentVersion as string)
    localStorage.removeItem('languageKey')
  }
}

// 获取地址前缀
const getPathPrefix = (path: string) => {
  const splits = path.split('/')
  let prefix = splits.find((item) => {
    if (!item || item != '#') {
      return item
    }
  })
  if (prefix && prefix.split('?').length) {
    prefix = prefix.split('?')[0]
  }
  return prefix
}

// 设置当前渠道
const setLocalChannel = (to: RouteLocationNormalized) => {
  if (to.path.indexOf('/home') > -1) {
    localStorage.setItem('channel', (to.name as string) || 'home')
  } else {
    localStorage.setItem('channel', 'home')
  }
}

router.beforeEach(async (to, from, next) => {
  if (from.name || from.path == '/') {
    setLocalChannel(to)
  }
  const channel = localStorage.getItem('channel') as string
  // 保存当前页面搜索信息
  CommonStore().setMetaInfo(to.meta?.metaInfo || {})
  handleDiffVersion()

  // 全局拦截/home跳转，分发到对应渠道首页
  if (channel && to.name == 'home' && !['undefined', 'null', 'home'].includes(channel)) {
    next(`/${channel}`)
  }

  next()
})

export default router
