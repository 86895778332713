import { RouteRecordRaw } from 'vue-router'
import { RouteLayout, BlankLayout } from '@/layouts'

import LocalTrans from '@/views/localTrans/index.vue'
import MedicalTrans from '@/views/medicalTrans/index.vue'
import TechnicalDoc from '@/views/technicalDoc/index.vue'
import Localization from '@/views/solution/localization'
import Pharmaceutical from '@/views/solution/pharmaceutical'
import ArtificialIntelligence from '@/views/solution/artificialIntelligence'
import GeologicalMetallurgy from '@/views/solution/geologicalMetallurgy'
import MilitaryIndustry from '@/views/solution/militaryIndustry'
import Uestc from '@/views/successCase/uestc'
import Hinova from '@/views/successCase/hinova'
import Wisdom from '@/views/successCase/wisdom'
import ServicePrice from '@/views/servicePrice/index.vue'
import AboutUs from '@/views/aboutUs/index.vue'
import JitaGuest from '@/views/successCase/JitaGuest.vue'
import BarleyWeb from '@/views/successCase/barleyWeb.vue'
import IEnglish from '@/views/successCase/IEnglish.vue'
import Vehicle from '@/views/successCase/vehicle.vue'
import Pharmacy from '@/views/successCase/pharmacy.vue'
import Haooubo from '@/views/successCase/haooubo.vue'
import TrustEconomy from '@/views/successCase/trustEconomy.vue'
import Power from '@/views/successCase/power.vue'
import LiveEra from '@/views/successCase/liveEra.vue'
import SuccessCase from '@/views/successCase/index.vue'

// 不同渠道对应路由
import { Router06 } from '@/views/baidu/router.config'
import { Router08 } from '@/views/sogou/router.config'
import { Router } from '@/views/default/router.config'

const Routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home',
    component: RouteLayout,
    children: [
      {
        path: '/personalTran',
        name: 'personalTran',
        component: BlankLayout,
        meta: { title: '人工翻译', link: '/client/order', hidden: false },
        children: [
          {
            path: '/docTran',
            name: 'docTran',
            component: BlankLayout,
            meta: { title: '文档翻译', link: '/client/order', hidden: false }
          },
          {
            path: '/medicineTran',
            name: 'medicineTran',
            component: BlankLayout,
            meta: { title: '医疗医药翻译', link: '/client/order', hidden: false }
          },
          {
            path: '/localTran',
            name: 'localTran',
            component: BlankLayout,
            meta: { title: '软件/游戏本地化', link: '/client/order', hidden: false }
          },
          {
            path: '/idCardTran',
            name: 'idCardTran',
            component: BlankLayout,
            meta: { title: '证件翻译', link: '/client/order', hidden: false }
          }
        ]
      },
      {
        path: '/aiTran',
        name: 'aiTran',
        component: BlankLayout,
        meta: { title: 'AI翻译', link: '/client/machineOrder', hidden: false }
      },
      {
        path: '/pharmaceutical',
        name: 'Pharmaceutical',
        component: Pharmaceutical,
        meta: {
          title: 'menu.text20',
          hidden: false,
          metaInfo: {
            title: '医药翻译'
          }
        }
      },
      {
        path: '/localization',
        name: 'Localization',
        component: Localization,
        meta: {
          title: 'menu.text21',
          hidden: false,
          metaInfo: {
            title: '软件游戏本地化'
          }
        }
      },

      // {
      //   path: '/localTrans',
      //   name: 'localTrans',
      //   component: LocalTrans,
      //   meta: {
      //     title: '本地化翻译',
      //     hidden: false,
      //     metaInfo: {
      //       title: '译马网——订单中心',
      //       keywords:
      //         '企业翻译，协同翻译、多人翻译，机辅翻译，智能翻译，翻译生产，格式转排，一键去重，稿件分派，低错检查，译审同步，进度监控，业务统计，语料回收，对照导出',
      //       description: '为企业提供企业版机辅翻译平台，基于简便、高效、灵活、专业、智能的产品特性优化翻译项目流程，降低企业翻译成本，助力企业智慧翻译生产。'
      //     }
      //   }
      // },
      // {
      //   path: '/medicalTrans',
      //   name: 'medicalTrans',
      //   component: MedicalTrans,
      //   meta: {
      //     title: '医药翻译',
      //     hidden: false,
      //     metaInfo: {
      //       title: '译马网翻译平台_企业版机辅翻译平台|协同翻译|项目管理|企业团队',
      //       keywords:
      //         '企业翻译，协同翻译、多人翻译，机辅翻译，智能翻译，翻译生产，格式转排，一键去重，稿件分派，低错检查，译审同步，进度监控，业务统计，语料回收，对照导出',
      //       description: '为企业提供企业版机辅翻译平台，基于简便、高效、灵活、专业、智能的产品特性优化翻译项目流程，降低企业翻译成本，助力企业智慧翻译生产。'
      //     }
      //   }
      // },
      // {
      //   path: '/technicalDoc',
      //   name: 'technicalDoc',
      //   component: TechnicalDoc,
      //   meta: { title: '技术性文档', hidden: false }
      // },
      // {
      //   path: '/solution',
      //   name: 'solution',
      //   meta: { title: 'menu.text5', hidden: false, subTitle: 'menu.text23' },
      //   children: [
      //     // {
      //     //   path: '/solution/localization',
      //     //   name: 'localization',
      //     //   component: Localization,
      //     //   meta: {
      //     //     title: 'menu.text12',
      //     //     hidden: false,
      //     //     icon: 'rjbdh',
      //     //     desc: 'menu.text12',
      //     //     metaInfo: {
      //     //       title: '语言服务|行业领域',
      //     //       keywords:
      //     //         '计算机辅助翻译，解决方案，按需定制，个性化服务，行业需求，机器翻译，神经网络语言服务，情报档案，外语高校，事业单位，生命科学，人工智能',
      //     //       description: '为有语言服务以及翻译需求的客户提供定制版机辅翻译平台，基于不同的行业领域，根据客户个性化需求对机辅翻译平台提供定制服务。'
      //     //     }
      //     //   }
      //     // },
      //     // {
      //     //   path: '/solution/pharmaceutical',
      //     //   name: 'pharmaceutical',
      //     //   component: Pharmaceutical,
      //     //   meta: {
      //     //     title: 'menu.text6',
      //     //     hidden: false,
      //     //     icon: 'yyhy',
      //     //     desc: 'menu.text12',
      //     //     metaInfo: {
      //     //       title: '优译网医药翻译_专业医药翻译平台 | 药品翻译 | 医疗器械翻译|医药规范标准翻译',
      //     //       keywords: '医药翻译，医学翻译，医学论文翻译，药品翻译，医药产品翻译，药品说明书翻译，医疗器械翻译，医药规范标准翻译，临床测试翻译',
      //     //       description:
      //     //         '依托强大的人工智能技术，快速、准确、专业母语翻译，为医药行业提供专业的本地化和翻译服务，包括医学论文、药物说明、临床测试、医疗器械以及医药规范标准的翻译。'
      //     //     }
      //     //   }
      //     // },
      //     {
      //       path: '/solution/artificialIntelligence',
      //       name: 'artificialIntelligence',
      //       component: ArtificialIntelligence,
      //       meta: { title: 'menu.text7', hidden: false, icon: 'rgzn', desc: 'menu.text12' }
      //     },
      //     {
      //       path: '/solution/geologicalMetallurgy',
      //       name: 'geologicalMetallurgy',
      //       component: GeologicalMetallurgy,
      //       meta: { title: 'menu.text8', hidden: false, icon: 'dkyj', desc: 'menu.text12' }
      //     }
      //     // {
      //     //   path: '/solution/militaryIndustry',
      //     //   name: 'militaryIndustry',
      //     //   component: MilitaryIndustry,
      //     //   meta: { title: '军事行业翻译解决方案', hidden: false, icon: 'jshy', desc: '软件本地化解决方案' }
      //     // }
      //   ]
      // },
      {
        path: '/successCase',
        name: 'successCase',
        component: SuccessCase,
        meta: { title: 'menu.text9', hidden: false, subTitle: 'menu.text24' },
        children: []
      },
      {
        path: '/successCase/uestc',
        name: 'uestc',
        component: Uestc,
        meta: { title: 'menu.text22', hidden: false }
      },
      {
        path: '/successCase/wisdom',
        name: 'wisdom',
        component: Wisdom,
        meta: { title: 'menu.text26', hidden: false }
      },
      {
        path: '/successCase/hinova',
        name: 'hinova',
        component: Hinova,
        meta: { title: 'menu.text25', hidden: false }
      },
      {
        path: '/successCase/JitaGuest',
        name: 'JitaGuest',
        component: JitaGuest,
        meta: { title: 'menu.text27', hidden: false }
      },
      {
        path: '/successCase/barleyWeb',
        name: 'barleyWeb',
        component: BarleyWeb,
        meta: { title: 'menu.text28', hidden: false }
      },
      {
        path: '/successCase/IEnglish',
        name: 'IEnglish',
        component: IEnglish,
        meta: { title: 'menu.text29', hidden: false }
      },
      {
        path: '/successCase/vehicle',
        name: 'vehicle',
        component: Vehicle,
        meta: { title: 'menu.text30', hidden: false }
      },
      {
        path: '/successCase/pharmacy',
        name: 'pharmacy',
        component: Pharmacy,
        meta: { title: 'menu.text31', hidden: false }
      },
      {
        path: '/successCase/haooubo',
        name: 'haooubo',
        component: Haooubo,
        meta: { title: 'menu.text31', hidden: false }
      },
      {
        path: '/successCase/trustEconomy',
        name: 'trustEconomy',
        component: TrustEconomy,
        meta: { title: 'menu.text32', hidden: false }
      },
      {
        path: '/successCase/power',
        name: 'power',
        component: Power,
        meta: { title: 'menu.text33', hidden: false }
      },
      {
        path: '/successCase/liveEra',
        name: 'liveEra',
        component: LiveEra,
        meta: { title: 'menu.text34', hidden: false }
      },
      {
        path: '/servicePrice',
        name: 'servicePrice',
        component: ServicePrice,
        meta: { title: 'menu.text18', hidden: false }
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: AboutUs,
        meta: { title: 'menu.text11', hidden: false }
      },
      ...Router,
      ...Router06,
      ...Router08
    ]
  }
]

export { Routes }
