import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { loadScript, removeScript } from '@/utils/util'

export const useAiff = () => {
  const route = useRoute()
  
  onMounted(() => {
    setTimeout(() => {
      if (route.name) {
        // 动态加载咨询框
        loadScript('https://hm.baidu.com/hm.js?891c0a2123fb3f82caeeeb6483f11701')
      } else {
        // 子应用删除咨询框
        removeScript('https://hm.baidu.com/hm.js?891c0a2123fb3f82caeeeb6483f11701')
      }
    }, 3000)
  })
}
