export default {
  zh: {
    text1: '软件本地化解决方案',
    text2:
      '我们的优译信息软件本地化解决方案专注于帮助您的软件产品在全球范围内取得成功。无论您是开发桌面应用、移动应用还是云服务，我们都提供全面的本地化支持，包括用户界面、文档、营销文案等。我们的目标是确保您的软件在不同语言环境中一致、易用，并符合当地文化。',
    text3: '咨询服务',
    text4: '特点',
    text5: '多语言界面本地化',
    text6: '我们将用户界面本地化为多种语言，使全球用户能够在其首选语言中使用您的软件',
    text7: '文档翻译',
    text8: '提供用户手册、帮助文档和教程的翻译服务，以帮助用户更好地理解软件的功能和使用方法',
    text9: '多平台支持',
    text10: '无论您的软件在哪些操作系统、平台和设备上运行，我们都提供全面的本地化支持',
    text11: '本地化测试',
    text12: '我们进行全面的本地化测试，确保软件在各种语言环境下运行正常，文本不会溢出或导致布局问题',
    text13: '术语管理',
    text14: '我们维护术语数据库，确保在不同语言版本中使用一致的术语，提高用户界面的一致性',
    text15: '文化适应性',
    text16: '考虑不同文化背景的差异，调整颜色、图标、日期格式等，以适应各个地区的习惯和文化',
    text17: '价值',
    text18: '全球市场进入',
    text19: '通过软件本地化，您可以轻松进入全球市场，吸引更多用户，扩大市场份额。',
    text20: '用户满意度',
    text21: '本地化使用户更容易理解和使用软件，从而提高用户满意度和忠诚度。',
    text22: '增加销售',
    text23: '通过满足用户的语言需求，您可以提高用户的购买意愿，从而增加销售额。',
    text24: '品牌认知',
    text25: '在不同语言的市场上提供本地化版本，有助于提高品牌在全球范围内的认知度。',
    text26: '用户增长',
    text27: '通过提供多语言支持，您可以吸引更多来自不同国家和地区的用户，实现持续的用户增长。',
    text28: '优势',
    text29: '专业团队',
    text30: '我们拥有经验丰富的翻译专家和本地化工程师，能够确保翻译和本地化的准确性和一致性。',
    text31: '术语管理',
    text32: '我们管理术语数据库，确保在不同语言版本中使用一致的术语，提高用户界面的一致性。',
    text33: '文化适应性',
    text34: '我们了解不同文化背景的差异，确保翻译内容和界面设计在不同地区都合适。',
    text35: '快速发布',
    text36: '我们的团队将帮助您迅速本地化软件，以适应快速变化的市场需求。',
    text37: '紧急支持',
    text38: '我们的团队能够在紧急情况下提供快速的修复和支持，确保您的软件能够持续稳定运行。',
    text39: '我们致力于帮助您打开全新的机会，实现跨足国际市场的愿望。',
    text40: '选择我们的软件本地化解决方案，您将获得一个专业、灵活和可信赖的合作伙伴，为您的软件产品在全球范围内获得成功提供支持。'
  },
  en: {
    text1: 'Software Localization Solution',
    text2:
      'Our Youyi Information Software Localization Solution focuses on helping your software products achieve success globally.Whether you are developing desktop applications, mobile applications, or cloud services, we provide comprehensive localization support, including user interfaces, documents, marketing copywriting, and more.Our goal is to ensure that your software is consistent, user-friendly, and culturally appropriate across different language environments.',
    text3: 'consulting service',
    text4: 'characteristic',
    text5: 'Localization of multilingual interfaces',
    text6: 'We have localized the user interface to multiple languages, enabling global users to use your software in their preferred languages',
    text7: 'Document translation',
    text8:
      'Provide translation services for user manuals, help documents, and tutorials to help users better understand the functionality and usage of the software',
    text9: 'Multi platform support',
    text10: 'No matter which operating system, platform, and device your software runs on, we provide comprehensive localization support',
    text11: 'Localization testing ',
    text12:
      'We conduct comprehensive localization testing to ensure that the software runs normally in various language environments and that text does not overflow or cause layout issues',
    text13: 'Term management',
    text14:
      'We maintain a terminology database to ensure consistent terminology usage across different language versions and improve user interface consistency',
    text15: 'Cultural adaptability',
    text16:
      'Considering the differences in cultural backgrounds, adjust colors, icons, date formats, etc. to adapt to the customs and cultures of different regions',
    text17: 'value',
    text18: 'Global market entry',
    text19: 'Through software localization, you can easily enter the global market, attract more users, and expand market share.',
    text20: 'User satisfaction',
    text21: 'Localization makes it easier for users to understand and use the software, thereby improving user satisfaction and loyalty.',
    text22: 'Increase sales',
    text23: "By meeting users' language needs, you can increase their willingness to purchase, thereby increasing sales.",
    text24: 'Brand awareness',
    text25: 'Providing localized versions in markets with different languages can help increase brand awareness globally.',
    text26: 'User growth',
    text27: 'By providing multilingual support, you can attract more users from different countries and regions, achieving sustained user growth.',
    text28: 'advantage',
    text29: 'Professional team',
    text30: 'We have experienced translation experts and localization engineers who can ensure the accuracy and consistency of translation and localization.',
    text31: 'Term management',
    text32:
      'We manage a terminology database to ensure consistent terminology usage across different language versions and improve user interface consistency.',
    text33: 'Cultural adaptability',
    text34:
      'We understand the differences in cultural backgrounds and ensure that the translated content and interface design are appropriate in different regions.',
    text35: 'Quick Release',
    text36: 'Our team will help you quickly localize software to adapt to rapidly changing market demands.',
    text37: 'Emergency support',
    text38: 'Our team is able to provide quick repairs and support in emergency situations, ensuring that your software can continue to run stably.',
    text39: 'We are committed to helping you open up new opportunities and realize your desire to enter the international market.',
    text40:
      'By choosing our software localization solution, you will receive a professional, flexible, and trustworthy partner to support the success of your software products worldwide.'
  },
  ja: {
    text1: 'ソフトウェアのローカライズソリューション',
    text2:
      'EMCの翻訳情報ソフトウェアのローカライズソリューションは、お客様のソフトウェア製品が世界的に成功するよう支援することに重点を置いています。デスクトップアプリケーション、モバイルアプリケーション、クラウドサービスの開発にかかわらず、ユーザーインタフェース、ドキュメント、マーケティングファイルなど、包括的なローカライズサポートを提供しています。EMCの目標は、お客様のソフトウェアが異なる言語環境で一貫して使いやすく、地域の文化に合っていることを確認することです。',
    text3: 'コンサルティングサービス',
    text4: '特徴',
    text5: '多言語インタフェースのローカライズ',
    text6: 'ユーザーインタフェースを複数の言語にローカライズし、世界中のユーザーが優先言語でソフトウェアを使用できるようにしました',
    text7: 'ドキュメント翻訳',
    text8:
      'ユーザーマニュアル、ヘルプドキュメント、チュートリアルの翻訳サービスを提供して、ユーザーがソフトウェアの機能と使用方法をよりよく理解できるようにします',
    text9: 'マルチプラットフォームサポート',
    text10: 'ソフトウェアがどのオペレーティングシステム、プラットフォーム、デバイス上で動作しているかにかかわらず、包括的なローカライズサポートを提供します',
    text11: 'ローカリゼーションテスト',
    text12:
      'SDLでは、ソフトウェアがさまざまな言語環境で正常に動作し、テキストがオーバーフローしたりレイアウトの問題にならないようにするための包括的なローカリゼーションテストを実施しています',
    text13: '用語管理',
    text14: '用語データベースを維持し、異なる言語バージョンで一貫性のある用語を使用してユーザーインタフェースの一貫性を向上させる',
    text15: '文化的適応性',
    text16: '異なる文化的背景の違いを考慮し、各地域の習慣や文化に適応するために色、アイコン、日付フォーマットなどを調整する',
    text17: 'の価値判断を',
    text18: 'グローバル市場参入',
    text19: 'ソフトウェアのローカライズにより、世界市場に簡単に参入し、より多くのユーザーを誘致し、市場シェアを拡大することができます。',
    text20: 'ユーザー満足度',
    text21: 'ローカライズにより、ソフトウェアの理解と使用が容易になり、ユーザー満足度とロイヤルティが向上します。',
    text22: '売上の増加',
    text23: 'ユーザーの言語要件を満たすことで、ユーザーの購買意欲を高め、売上を増やすことができます。',
    text24: 'ブランド認知',
    text25: '異なる言語の市場でローカライズされたバージョンを提供することで、ブランドの世界的な認知度を高めることができます。',
    text26: 'ユーザーの増加',
    text27: '多言語サポートを提供することで、さまざまな国や地域からより多くのユーザーを誘致し、持続的なユーザー成長を実現することができます。',
    text28: '利点',
    text29: '専門チーム',
    text30: 'SDLには経験豊富な翻訳専門家とローカリゼーションエンジニアがおり、翻訳とローカリゼーションの正確性と一貫性を確保しています。',
    text31: '用語管理',
    text32: '用語データベースを管理して、異なる言語バージョンで一貫性のある用語を使用して、ユーザーインタフェースの一貫性を高めることを確認します。',
    text33: '文化的適応性',
    text34: '私たちは異なる文化的背景の違いを理解し、翻訳内容とインタフェース設計が異なる地域で適切であることを確認します。',
    text35: 'クイックリリース',
    text36: 'デルのチームは、急速に変化する市場ニーズに対応するために、ソフトウェアを迅速にローカライズするのを支援します。',
    text37: '緊急サポート',
    text38: 'デルのチームは、緊急時に迅速な修復とサポートを提供し、お客様のソフトウェアが継続的に安定して動作することを保証します。',
    text39: '私たちは、国際市場に足を踏み入れるための新しい機会を開くことに力を入れています。',
    text40:
      'デルのソフトウェアローカライズソリューションを選択すると、専門的で柔軟で信頼性の高いパートナーが得られ、ソフトウェア製品の世界的な成功をサポートします。'
  },
  ko: {
    text1: '소프트웨어 현지화 솔루션',
    text2:
      'Dell의 유용한 정보 소프트웨어 현지화 솔루션은 귀사의 소프트웨어 제품이 전 세계적으로 성공할 수 있도록 지원하는 데 중점을 두고 있습니다.데스크탑, 모바일 또는 클라우드 서비스를 개발하는 경우에도 사용자 인터페이스, 문서, 마케팅 문서 등 포괄적인 현지화 지원을 제공합니다.우리의 목표는 소프트웨어가 서로 다른 언어 환경에서 일관되고 사용하기 쉬우며 현지 문화에 부합되도록 하는 것입니다.',
    text3: '컨설팅 서비스',
    text4: '특징',
    text5: '다국어 인터페이스 현지화',
    text6: '우리는 사용자 인터페이스를 여러 언어로 현지화하여 전 세계 사용자들이 선호 언어에서 소프트웨어를 사용할 수 있도록 합니다',
    text7: '문서 번역',
    text8: '사용자가 소프트웨어의 기능과 사용 방법을 더 잘 이해할 수 있도록 사용자 안내서, 문서 및 자습서 번역 서비스 제공',
    text9: '다중 플랫폼 지원',
    text10: '소프트웨어가 어떤 운영 체제, 플랫폼 및 장치에서 실행되든 포괄적인 현지화 지원 제공',
    text11: '현지화 테스트',
    text12: '포괄적인 현지화 테스트를 통해 다양한 로켈에서 소프트웨어가 제대로 작동하고 텍스트가 넘치거나 레이아웃 문제가 발생하지 않도록 합니다.',
    text13: '용어 관리',
    text14: '용어 데이터베이스를 유지 관리하여 다양한 언어 버전에서 일관된 용어를 사용하고 사용자 인터페이스의 일관성을 높입니다.',
    text15: '문화적 적응성',
    text16: '서로 다른 문화 배경의 차이를 고려하여 색깔, 아이콘, 날짜 형식 등을 조정하여 각 지역의 습관과 문화에 적응시키다',
    text17: '가치',
    text18: '글로벌 시장 진출',
    text19: '소프트웨어 현지화를 통해 글로벌 시장에 쉽게 진출하여 더 많은 사용자를 유치하고 시장 점유율을 확대할 수 있습니다.',
    text20: '사용자 만족도',
    text21: '현지화는 사용자가 소프트웨어를 쉽게 이해하고 사용할 수 있게 하여 사용자 만족도와 충성도를 높인다.',
    text22: '판매 증가',
    text23: '사용자의 언어 요구를 충족시킴으로써 사용자의 구매 의사를 높여 매출을 늘릴 수 있습니다.',
    text24: '브랜드 인식',
    text25: '다양한 언어의 시장에서 현지화된 버전을 제공하면 전 세계적으로 브랜드의 인지도를 높이는 데 도움이 된다.',
    text26: '사용자 증가',
    text27: '다국어 지원을 통해 다양한 국가에서 더 많은 사용자를 유치하고 지속적인 사용자 증가를 실현할 수 있습니다.',
    text28: '이점',
    text29: '전문 팀',
    text30: 'Dell은 번역 및 현지화의 정확성과 일관성을 보장하는 숙련된 번역 전문가와 현지화 엔지니어를 보유하고 있습니다.',
    text31: '용어 관리',
    text32: '우리는 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일치하는 용어를 사용하고 사용자 인터페이스의 일치성을 향상시킵니다.',
    text33: '문화적 적응성',
    text34: '우리는 서로 다른 문화 배경의 차이를 이해하고 번역 내용과 인터페이스 디자인이 서로 다른 지역에서 모두 적합하도록 확보한다.',
    text35: '빠른 출시',
    text36: '우리 팀은 빠르게 변화하는 시장 요구에 맞게 소프트웨어를 신속하게 현지화할 수 있도록 도와드립니다.',
    text37: '긴급 지원',
    text38: '우리 팀은 긴급 상황에서 신속한 복구와 지원을 제공하여 소프트웨어가 지속적으로 안정적으로 작동할 수 있도록 할 수 있습니다.',
    text39: '우리는 당신이 새로운 기회를 열어 국제 시장에 진출하려는 소망을 실현할 수 있도록 돕기 위해 노력합니다.',
    text40: '소프트웨어 현지화 솔루션을 선택하면 전 세계적으로 소프트웨어 제품을 성공적으로 지원할 수 있는 전문적이고 유연하며 신뢰할 수 있는 파트너가 됩니다.'
  },
  de: {
    text1: 'Softwarelokalisierungslösung',
    text2:
      'Unsere Youyi Information Software Lokalisierungslösung konzentriert sich darauf, Ihren Softwareprodukten dabei zu helfen, weltweit erfolgreich zu sein.Egal, ob Sie Desktop-Anwendungen, mobile Anwendungen oder Cloud-Services entwickeln, wir bieten umfassende Lokalisierungsunterstützung, einschließlich Benutzeroberflächen, Dokumente, Marketing-Copywriting und mehr.Unser Ziel ist es, sicherzustellen, dass Ihre Software in verschiedenen Sprachumgebungen konsistent, benutzerfreundlich und kulturell angemessen ist.',
    text3: 'Beratung',
    text4: 'Charakteristik',
    text5: 'Lokalisierung mehrsprachiger Schnittstellen',
    text6:
      'Wir haben die Benutzeroberfläche auf mehrere Sprachen lokalisiert, sodass globale Benutzer Ihre Software in ihren bevorzugten Sprachen verwenden können',
    text7: 'Übersetzung von Dokumenten',
    text8:
      'Bereitstellung von Übersetzungsdiensten für Bedienungsanleitungen, Hilfedokumente und Tutorials, um Benutzern zu helfen, die Funktionalität und Nutzung der Software besser zu verstehen',
    text9: 'Unterstützung für mehrere Plattformen',
    text10:
      'Egal auf welchem Betriebssystem, auf welcher Plattform und auf welchem Gerät Ihre Software läuft, wir bieten umfassende Lokalisierungsunterstützung',
    text11: 'Lokalisierungstests',
    text12:
      'Wir führen umfassende Lokalisierungstests durch, um sicherzustellen, dass die Software in verschiedenen Sprachumgebungen normal läuft und dass Text nicht überläuft oder Layoutprobleme verursacht',
    text13: 'Terminverwaltung',
    text14:
      'Wir pflegen eine Terminologiedatenbank, um eine konsistente Terminologieverwendung über verschiedene Sprachversionen hinweg zu gewährleisten und die Konsistenz der Benutzeroberfläche zu verbessern',
    text15: 'Kulturelle Anpassungsfähigkeit',
    text16:
      'Unter Berücksichtigung der Unterschiede in kulturellen Hintergründen, passen Sie Farben, Symbole, Datumsformate usw. an, um sich an die Bräuche und Kulturen der verschiedenen Regionen anzupassen',
    text17: 'Wert',
    text18: 'Markteintritt weltweit',
    text19: 'Durch Softwarelokalisierung können Sie leicht in den globalen Markt eintreten, mehr Benutzer anziehen und Marktanteile vergrößern.',
    text20: 'Benutzerzufriedenheit',
    text21:
      'Die Lokalisierung erleichtert es Benutzern, die Software zu verstehen und zu verwenden, wodurch die Zufriedenheit und Loyalität der Benutzer verbessert wird.',
    text22: 'Umsatz steigern',
    text23: 'Indem Sie die Sprachbedürfnisse der Nutzer erfüllen, können Sie ihre Kaufbereitschaft erhöhen und dadurch den Umsatz steigern.',
    text24: 'Markenbekanntheit',
    text25: 'Die Bereitstellung lokalisierter Versionen in Märkten mit verschiedenen Sprachen kann dazu beitragen, die Markenbekanntheit weltweit zu erhöhen.',
    text26: 'Nutzerwachstum',
    text27:
      'Durch mehrsprachige Unterstützung können Sie mehr Nutzer aus verschiedenen Ländern und Regionen anziehen und ein nachhaltiges Nutzerwachstum erzielen.',
    text28: 'Vorteil',
    text29: 'Professionelles Team',
    text30:
      'Wir verfügen über erfahrene Übersetzungsexperten und Lokalisierungsingenieure, die die Genauigkeit und Konsistenz der Übersetzung und Lokalisierung gewährleisten können.',
    text31: 'Terminverwaltung',
    text32:
      'Wir verwalten eine Terminologiedatenbank, um eine konsistente Terminologieverwendung über verschiedene Sprachversionen hinweg zu gewährleisten und die Konsistenz der Benutzeroberfläche zu verbessern.',
    text33: 'Kulturelle Anpassungsfähigkeit',
    text34:
      'Wir verstehen die Unterschiede in kulturellen Hintergründen und stellen sicher, dass übersetzte Inhalte und Schnittstellendesign in verschiedenen Regionen angemessen sind.',
    text35: 'Schnellspanner',
    text36: 'Unser Team hilft Ihnen, Software schnell zu lokalisieren, um sich schnell an sich ändernde Marktanforderungen anzupassen.',
    text37: 'Nothilfe',
    text38:
      'Unser Team ist in der Lage, schnelle Reparaturen und Support in Notsituationen zu leisten, um sicherzustellen, dass Ihre Software weiterhin stabil läuft.',
    text39:
      'Wir sind bestrebt, Ihnen dabei zu helfen, neue Möglichkeiten zu eröffnen und Ihren Wunsch zu verwirklichen, den internationalen Markt zu betreten.',
    text40:
      'Mit unserer Software-Lokalisierungslösung erhalten Sie einen professionellen, flexiblen und vertrauenswürdigen Partner, der den Erfolg Ihrer Softwareprodukte weltweit unterstützt.'
  },
  fr: {
    text1: 'Solutions de localisation de logiciels',
    text2:
      "Nos solutions de localisation de logiciels d'information optimisés sont axées sur la réussite de vos produits logiciels dans le monde entier.Que vous développiez des applications de bureau, des applications mobiles ou des services Cloud, nous offrons un support de localisation complet, y compris l'interface utilisateur, la documentation, la rédaction marketing et plus encore.Notre objectif est de veiller à ce que votre logiciel soit cohérent, facile à utiliser dans différents contextes linguistiques et conforme à la culture locale.",
    text3: 'Services consultatifs',
    text4: 'Caractéristiques',
    text5: "Localisation d'interface multilingue",
    text6:
      "Nous localisons l'interface utilisateur dans plusieurs langues pour permettre aux utilisateurs du monde entier d'utiliser votre logiciel dans leur langue préférée",
    text7: 'Traduction de documents',
    text8:
      "Fournit des services de traduction de manuels d'utilisation, de documentation d'aide et de tutoriels pour aider les utilisateurs à mieux comprendre les fonctionnalités et les méthodes d'utilisation du logiciel",
    text9: 'Support multiplateforme',
    text10:
      "Quel que soit le système d'exploitation, la plate - forme et le périphérique sur lesquels votre logiciel s'exécute, nous offrons un support complet de localisation",
    text11: 'Tests de localisation',
    text12:
      'Nous effectuons des tests de localisation complets pour nous assurer que le logiciel fonctionne correctement dans divers environnements linguistiques et que le texte ne déborde pas ou ne provoque pas de problèmes de mise en page.',
    text13: 'Gestion terminologique',
    text14:
      "Nous maintenons une base de données terminologique, assurons l'utilisation d'une terminologie cohérente dans les différentes versions linguistiques et améliorons la cohérence de l'interface utilisateur",
    text15: 'Adaptabilité culturelle',
    text16:
      'Tenir compte des différences entre les différents contextes culturels, ajuster les couleurs, les icônes, les formats de date, etc. aux habitudes et à la culture de chaque région',
    text17: 'Valeur',
    text18: 'Entrée sur le marché mondial',
    text19:
      "Avec la localisation du logiciel, vous pouvez facilement accéder au marché mondial, attirer plus d'utilisateurs et augmenter votre part de marché.",
    text20: 'Satisfaction des utilisateurs',
    text21:
      "La localisation facilite la compréhension et l'utilisation du logiciel par les utilisateurs, ce qui augmente la satisfaction et la fidélité des utilisateurs.",
    text22: 'Augmenter les ventes',
    text23: "En répondant aux besoins linguistiques de vos utilisateurs, vous pouvez augmenter leur volonté d'acheter et donc augmenter leurs ventes.",
    text24: 'Perception de la marque',
    text25:
      'La disponibilité de versions localisées sur le marché dans différentes langues contribue à accroître la notoriété de la marque dans le monde entier.',
    text26: 'Croissance des utilisateurs',
    text27:
      "En offrant un support multilingue, vous pouvez attirer plus d'utilisateurs de différents pays et régions, ce qui permet une croissance continue des utilisateurs.",
    text28: 'Avantages',
    text29: 'Équipe professionnelle',
    text30:
      "Nous disposons d'experts en traduction et d'ingénieurs de localisation expérimentés capables d'assurer la précision et la cohérence de la traduction et de la localisation.",
    text31: 'Gestion terminologique',
    text32:
      "Nous gérons des bases de données terminologiques qui garantissent une terminologie cohérente dans les différentes versions linguistiques et améliorent la cohérence de l'interface utilisateur.",
    text33: 'Adaptabilité culturelle',
    text34:
      "Nous comprenons les différences entre les différents contextes culturels et nous nous assurons que le contenu traduit et la conception de l'interface sont appropriés dans différentes régions.",
    text35: 'Publication rapide',
    text36: "Notre équipe vous aidera à localiser rapidement votre logiciel pour l'adapter aux besoins changeants du marché.",
    text37: "Soutien d'urgence",
    text38:
      "Notre équipe est en mesure de fournir une réparation rapide et une assistance en cas d'urgence, garantissant ainsi un fonctionnement stable et continu de votre logiciel.",
    text39: 'Nous nous engageons à vous aider à ouvrir de nouvelles opportunités et à réaliser vos aspirations sur le marché international.',
    text40:
      "En choisissant notre solution de localisation de logiciels, vous bénéficiez d'un partenaire professionnel, flexible et fiable qui vous aidera à assurer le succès de vos produits logiciels dans le monde entier."
  },
  ru: {
    text1: 'Локализация программного обеспечения',
    text2:
      'Наше решение по локализации программного обеспечения Ultimate Information фокусируется на том, чтобы помочь вашему программному продукту добиться успеха во всем мире.Независимо от того, разрабатываете ли вы настольные приложения, мобильные приложения или облачные сервисы, мы предоставляем всестороннюю поддержку локализации, включая пользовательский интерфейс, документацию, маркетинговые тексты и многое другое.Наша цель - обеспечить, чтобы ваше программное обеспечение было последовательным, простым в использовании в различных языковых средах и соответствовало местной культуре.',
    text3: 'Консультативные услуги',
    text4: 'Особенности',
    text5: 'Локализация многоязычного интерфейса',
    text6:
      'Мы локализуем пользовательский интерфейс на нескольких языках, чтобы пользователи по всему миру могли использовать ваше программное обеспечение на своих предпочтительных языках',
    text7: 'Перевод документов',
    text8:
      'Предоставление услуг по переводу руководств пользователей, справочных документов и учебных пособий, чтобы помочь пользователям лучше понять функции и методы использования программного обеспечения',
    text9: 'Многоплатформенная поддержка',
    text10:
      'Независимо от того, на каких операционных системах, платформах и устройствах работает ваше программное обеспечение, мы предоставляем полную поддержку локализации.',
    text11: 'Локализация',
    text12:
      'Мы проводим комплексные тесты локализации, чтобы убедиться, что программное обеспечение работает нормально в различных языковых средах, текст не переполнен или не вызывает проблем с макетом',
    text13: 'Терминология',
    text14:
      'Мы поддерживаем терминологическую базу данных, обеспечивая последовательное использование терминов в различных языковых версиях и повышая согласованность пользовательского интерфейса',
    text15: 'Культурная адаптация',
    text16: 'Учитывайте различия в различных культурных контекстах, адаптируйте цвета, значки, форматы дат и т. Д. С учетом обычаев и культур каждого региона',
    text17: 'Стоимость',
    text18: 'Выход на мировые рынки',
    text19:
      'Благодаря локализации программного обеспечения вы можете легко выйти на глобальный рынок, привлечь больше пользователей и расширить свою долю на рынке.',
    text20: 'Удовлетворенность пользователей',
    text21: 'Локализованным пользователям легче понять и использовать программное обеспечение, что повышает удовлетворенность и лояльность пользователей.',
    text22: 'Увеличение объема продаж',
    text23: 'Удовлетворяя языковые потребности пользователей, вы можете увеличить их желание покупать, тем самым увеличивая продажи.',
    text24: 'Знание марки',
    text25: 'Доступ к локализованным версиям на различных языках помогает повысить осведомленность о брендах во всем мире.',
    text26: 'Рост пользователей',
    text27:
      'Предоставляя многоязычную поддержку, вы можете привлечь больше пользователей из разных стран и регионов для достижения устойчивого роста пользователей.',
    text28: 'Преимущество',
    text29: 'Профессиональная команда',
    text30:
      'У нас есть опытные специалисты по переводу и инженеры по локализации, которые могут обеспечить точность и последовательность перевода и локализации.',
    text31: 'Терминология',
    text32:
      'Мы управляем терминологической базой данных, обеспечивая последовательное использование терминов в различных языковых версиях и повышая согласованность пользовательского интерфейса.',
    text33: 'Культурная адаптация',
    text34: 'Мы понимаем различия в различных культурных контекстах и гарантируем, что содержание перевода и дизайн интерфейса подходят в разных регионах.',
    text35: 'Быстрый выпуск',
    text36: 'Наша команда поможет вам быстро локализовать программное обеспечение для удовлетворения быстро меняющихся потребностей рынка.',
    text37: 'Экстренная поддержка',
    text38:
      'Наша команда может обеспечить быстрое восстановление и поддержку в чрезвычайных ситуациях, гарантируя бесперебойную и стабильную работу вашего программного обеспечения.',
    text39: 'Мы стремимся помочь вам открыть новые возможности и реализовать свое желание выйти на международный рынок.',
    text40:
      'Выбрав наше программное решение для локализации, вы получите профессионального, гибкого и надежного партнера, который будет поддерживать успех ваших программных продуктов во всем мире.'
  },
  th: {
    text1: 'โซลูชันการแปลซอฟต์แวร์',
    text2:
      'โซลูชันการแปลซอฟต์แวร์ข้อมูลที่แปลได้ดีของเรามุ่งเน้นไปที่การช่วยให้ผลิตภัณฑ์ซอฟต์แวร์ของคุณประสบความสำเร็จในระดับโลกไม่ว่าคุณจะพัฒนาแอพเดสก์ท็อปแอพมือถือหรือบริการคลาวด์เราให้การสนับสนุนการแปลความหมายที่ครอบคลุมซึ่งรวมถึงส่วนติดต่อผู้ใช้เอกสารการเขียนคำโฆษณาการตลาดและอื่น ๆเป้าหมายของเราคือเพื่อให้แน่ใจว่าซอฟต์แวร์ของคุณสอดคล้องและใช้งานง่ายในสภาพแวดล้อมภาษาที่แตกต่างกันและสอดคล้องกับวัฒนธรรมท้องถิ่น',
    text3: 'บริการให้คำปรึกษา',
    text4: 'คุณสมบัติ',
    text5: 'การแปลอินเตอร์เฟซหลายภาษา',
    text6: 'เราแปลส่วนติดต่อผู้ใช้เป็นภาษาต่างๆช่วยให้ผู้ใช้ทั่วโลกสามารถใช้ซอฟต์แวร์ของคุณในภาษาที่ต้องการ',
    text7: 'การแปลเอกสาร',
    text8: 'บริการแปลเอกสารคู่มือผู้ใช้ เอกสารช่วยเหลือ และบทเรียน เพื่อช่วยให้ผู้ใช้เข้าใจการทำงานและวิธีการใช้งานของซอฟต์แวร์ได้ดียิ่งขึ้น',
    text9: 'รองรับหลายแพลตฟอร์ม',
    text10: 'ไม่ว่าซอฟต์แวร์ของคุณจะทำงานบนระบบปฏิบัติการแพลตฟอร์มและอุปกรณ์ใดเรามีการสนับสนุนด้านการแปลเป็นภาษาท้องถิ่นอย่างครอบคลุม',
    text11: 'การทดสอบการแปล',
    text12:
      'เราดำเนินการทดสอบการแปลที่ครอบคลุมเพื่อให้แน่ใจว่าซอฟต์แวร์ทำงานได้ดีในสภาพแวดล้อมทางภาษาต่างๆและข้อความจะไม่ล้นหรือทำให้เกิดปัญหาเกี่ยวกับเค้าโครง',
    text13: 'การจัดการคำศัพท์',
    text14: 'เรารักษาฐานข้อมูลคำศัพท์เพื่อให้แน่ใจว่ามีการใช้คำที่สอดคล้องกันในเวอร์ชันภาษาต่าง ๆ และปรับปรุงความสอดคล้องของส่วนติดต่อผู้ใช้',
    text15: 'การปรับตัวทางวัฒนธรรม',
    text16: 'พิจารณาความแตกต่างของภูมิหลังทางวัฒนธรรมที่แตกต่างกันปรับสีไอคอนรูปแบบวันที่และอื่น ๆ เพื่อให้เหมาะกับนิสัยและวัฒนธรรมของแต่ละพื้นที่',
    text17: 'ความคุ้มค่า',
    text18: 'การเข้าถึงตลาดโลก',
    text19: 'ด้วยการแปลซอฟต์แวร์คุณสามารถเข้าถึงตลาดโลกได้อย่างง่ายดายดึงดูดผู้ใช้มากขึ้นและขยายส่วนแบ่งการตลาด',
    text20: 'ความพึงพอใจของผู้ใช้',
    text21: 'การแปลภาษาช่วยให้ผู้ใช้เข้าใจและใช้งานซอฟต์แวร์ได้ง่ายขึ้นซึ่งจะช่วยเพิ่มความพึงพอใจและความภักดีของผู้ใช้',
    text22: 'เพิ่มยอดขาย',
    text23: 'โดยการตอบสนองความต้องการด้านภาษาของผู้ใช้คุณสามารถเพิ่มความตั้งใจในการซื้อของผู้ใช้และเพิ่มยอดขายได้',
    text24: 'การรับรู้แบรนด์',
    text25: 'การนำเสนอเวอร์ชันท้องถิ่นในตลาดในภาษาต่าง ๆ ช่วยเพิ่มการรับรู้แบรนด์ไปทั่วโลก',
    text26: 'การเติบโตของผู้ใช้',
    text27: 'ด้วยการให้การสนับสนุนหลายภาษาคุณสามารถดึงดูดผู้ใช้จากประเทศและภูมิภาคต่าง ๆ ได้มากขึ้นเพื่อการเติบโตของผู้ใช้อย่างต่อเนื่อง',
    text28: 'ข้อดี',
    text29: 'ทีมงานมืออาชีพ',
    text30: 'เรามีผู้เชี่ยวชาญด้านการแปลที่มีประสบการณ์และวิศวกรท้องถิ่นที่สามารถรับประกันความถูกต้องและความสอดคล้องของการแปลและการแปลเป็นภาษาท้องถิ่น',
    text31: 'การจัดการคำศัพท์',
    text32: 'เราจัดการฐานข้อมูลคำศัพท์เพื่อให้แน่ใจว่ามีการใช้คำที่สอดคล้องกันในเวอร์ชันภาษาต่าง ๆ และปรับปรุงความสอดคล้องของส่วนติดต่อผู้ใช้',
    text33: 'การปรับตัวทางวัฒนธรรม',
    text34: 'เราเข้าใจความแตกต่างของภูมิหลังทางวัฒนธรรมที่แตกต่างกันเพื่อให้แน่ใจว่าเนื้อหาที่แปลและการออกแบบอินเตอร์เฟซที่เหมาะสมในภูมิภาคที่แตกต่างกัน',
    text35: 'ปล่อยอย่างรวดเร็ว',
    text36: 'ทีมงานของเราจะช่วยให้คุณแปลซอฟต์แวร์ได้อย่างรวดเร็วเพื่อรองรับความต้องการของตลาดที่เปลี่ยนแปลงอย่างรวดเร็ว',
    text37: 'การสนับสนุนฉุกเฉิน',
    text38: 'ทีมงานของเราสามารถให้การแก้ไขและการสนับสนุนที่รวดเร็วในกรณีฉุกเฉินเพื่อให้แน่ใจว่าซอฟต์แวร์ของคุณทำงานได้อย่างต่อเนื่องและมีเสถียรภาพ',
    text39: 'เรามุ่งมั่นที่จะช่วยให้คุณเปิดโอกาสใหม่ ๆ และตระหนักถึงความปรารถนาที่จะก้าวเข้าสู่ตลาดต่างประเทศ',
    text40:
      'เลือกโซลูชันโลคัลไลเซชันซอฟต์แวร์ของเราและคุณจะได้รับพันธมิตรระดับมืออาชีพยืดหยุ่นและเชื่อถือได้เพื่อสนับสนุนผลิตภัณฑ์ซอฟต์แวร์ของคุณให้ประสบความสำเร็จทั่วโลก'
  },
  es: {
    text1: 'Soluciones de localización de software',
    text2:
      'Nuestras soluciones de localización de software de información Youyi se centran en ayudar a sus productos de software a tener éxito en todo el mundo.Ya sea que desarrolle aplicaciones de escritorio, aplicaciones móviles o servicios en la nube, ofrecemos un soporte de localización integral, incluyendo interfaz de usuario, documentos, copywriting de marketing, etc.Nuestro objetivo es garantizar que su software sea consistente, fácil de usar y acorde con la cultura local en diferentes entornos lingüísticos.',
    text3: 'Servicios de consultoría',
    text4: 'Características',
    text5: 'Localización de la interfaz multilingüe',
    text6: 'Localizamos la interfaz de usuario en varios idiomas para que los usuarios de todo el mundo puedan usar su software en su idioma preferido',
    text7: 'Traducción de documentos',
    text8:
      'Ofrece servicios de traducción de manuales de usuario, documentos de ayuda y tutoriales para ayudar a los usuarios a comprender mejor las funciones y el uso del software',
    text9: 'Soporte multiplataforma',
    text10:
      'Independientemente del sistema operativo, la Plataforma y el dispositivo en el que se ejecute su software, ofrecemos un soporte de localización completo.',
    text11: 'Prueba de localización',
    text12:
      'Realizamos pruebas completas de localización para garantizar que el software funcione correctamente en varios entornos lingüísticos, que el texto no se desborda ni causa problemas de diseño.',
    text13: 'Gestión de la terminología',
    text14:
      'Mantenemos una base de datos de términos para garantizar el uso de términos consistentes en diferentes versiones lingüísticas y mejorar la consistencia de la interfaz de usuario.',
    text15: 'Adaptabilidad cultural',
    text16:
      'Considere las diferencias en los diferentes contextos culturales y ajuste los colores, iconos, formatos de fecha, etc., para adaptarse a los hábitos y culturas de cada región.',
    text17: 'Valor',
    text18: 'Entrada en el mercado mundial',
    text19: 'A través de la localización de software, puede ingresar fácilmente al mercado global, atraer a más usuarios y expandir su cuota de mercado.',
    text20: 'Satisfacción del usuario',
    text21: 'La localización hace que sea más fácil para los usuarios entender y usar el software, mejorando así la satisfacción y lealtad de los usuarios.',
    text22: 'Aumentar las ventas',
    text23: 'Al satisfacer las necesidades lingüísticas de los usuarios, puede aumentar la voluntad de compra de los usuarios, aumentando así las ventas.',
    text24: 'Percepción de la marca',
    text25: 'Proporcionar versiones localizadas en el mercado en diferentes idiomas ayuda a aumentar la conciencia de la marca en todo el mundo.',
    text26: 'Crecimiento de los usuarios',
    text27:
      'Al proporcionar soporte multilingüe, puede atraer a más usuarios de diferentes países y regiones para lograr un crecimiento sostenido de los usuarios.',
    text28: 'Ventaja',
    text29: 'Equipo profesional',
    text30:
      'Tenemos expertos en traducción e ingenieros de localización experimentados que pueden garantizar la precisión y consistencia de la traducción y localización.',
    text31: 'Gestión de la terminología',
    text32:
      'Gestionamos una base de datos de términos para garantizar el uso de términos consistentes en diferentes versiones lingüísticas y mejorar la consistencia de la interfaz de usuario.',
    text33: 'Adaptabilidad cultural',
    text34:
      'Entendemos las diferencias entre los diferentes antecedentes culturales y nos aseguramos de que el contenido de la traducción y el diseño de la interfaz sean adecuados en diferentes regiones.',
    text35: 'Lanzamiento rápido',
    text36: 'Nuestro equipo le ayudará a localizar rápidamente el software para adaptarse a la rápida demanda del mercado.',
    text37: 'Apoyo urgente',
    text38:
      'Nuestro equipo puede proporcionar reparaciones y soporte rápidos en situaciones de emergencia para garantizar que su software pueda funcionar de manera continua y estable.',
    text39: 'Estamos comprometidos a ayudarle a abrir nuevas oportunidades y realizar su deseo de cruzar el mercado internacional.',
    text40:
      'Al elegir nuestra solución de localización de software, obtendrá un socio profesional, flexible y de confianza para apoyar el éxito de sus productos de software en todo el mundo.'
  },
  pt: {
    text1: 'Solução de localização de software',
    text2:
      'Nossa solução de localização de software de informação Youyi se concentra em ajudar seus produtos de software a alcançar o sucesso globalmente.Se você está desenvolvendo aplicativos desktop, aplicativos móveis ou serviços de nuvem, oferecemos suporte abrangente de localização, incluindo interfaces de usuário, documentos, redação de marketing e muito mais.Nosso objetivo é garantir que seu software seja consistente, fácil de usar e culturalmente apropriado em diferentes ambientes linguísticos.',
    text3: 'serviço de consultoria',
    text4: 'característica',
    text5: 'Localização de interfaces multilingues',
    text6: 'Localizamos a interface do usuário em vários idiomas, permitindo que usuários globais usem seu software em seus idiomas preferidos',
    text7: 'Tradução do documento',
    text8:
      'Fornecer serviços de tradução para manuais do usuário, documentos de ajuda e tutoriais para ajudar os usuários a entender melhor a funcionalidade e o uso do software',
    text9: 'Suporte a várias plataformas',
    text10: 'Não importa em qual sistema operacional, plataforma e dispositivo seu software é executado, nós fornecemos suporte abrangente de localização',
    text11: 'Teste de localização',
    text12:
      'Realizamos testes abrangentes de localização para garantir que o software seja executado normalmente em vários ambientes de linguagem e que o texto não transborde ou cause problemas de layout',
    text13: 'Gestão de prazos',
    text14:
      'Mantemos um banco de dados terminológico para garantir o uso consistente da terminologia em diferentes versões linguísticas e melhorar a consistência da interface do usuário',
    text15: 'Adaptabilidade cultural',
    text16:
      'Considerando as diferenças de origens culturais, ajustar cores, ícones, formatos de data, etc., para se adaptar aos costumes e culturas de diferentes regiões',
    text17: 'valor',
    text18: 'Entrada no mercado mundial',
    text19: 'Através da localização de software, você pode facilmente entrar no mercado global, atrair mais usuários e expandir a participação de mercado.',
    text20: 'Satisfação do utilizador',
    text21: 'A localização torna mais fácil para os usuários entenderem e usarem o software, melhorando assim a satisfação e fidelidade do usuário.',
    text22: 'Aumentar as vendas',
    text23: 'Atendendo às necessidades linguísticas dos usuários, você pode aumentar sua vontade de comprar, aumentando assim as vendas.',
    text24: 'Reconhecimento da marca',
    text25: 'Fornecer versões localizadas em mercados com diferentes idiomas pode ajudar a aumentar o reconhecimento da marca globalmente.',
    text26: 'Crescimento dos utilizadores',
    text27: 'Ao fornecer suporte multilíngue, você pode atrair mais usuários de diferentes países e regiões, alcançando um crescimento sustentado do usuário.',
    text28: 'vantagem',
    text29: 'Equipe profissional',
    text30: 'Temos experientes especialistas em tradução e engenheiros de localização que podem garantir a precisão e consistência da tradução e localização.',
    text31: 'Gestão de prazos',
    text32:
      'Gerenciamos um banco de dados terminológicos para garantir o uso consistente da terminologia em diferentes versões linguísticas e melhorar a consistência da interface do usuário.',
    text33: 'Adaptabilidade cultural',
    text34: 'Entendemos as diferenças de origens culturais e garantimos que o conteúdo traduzido e design de interface são adequados em diferentes regiões.',
    text35: 'Liberação Rápida',
    text36: 'Nossa equipe irá ajudá-lo a localizar rapidamente o software para se adaptar às demandas do mercado em rápida mudança.',
    text37: 'Apoio de emergência',
    text38:
      'Nossa equipe é capaz de fornecer reparos rápidos e suporte em situações de emergência, garantindo que seu software possa continuar funcionando de forma estável.',
    text39: 'Estamos empenhados em ajudá-lo a abrir novas oportunidades e realizar seu desejo de entrar no mercado internacional.',
    text40:
      'Ao escolher nossa solução de localização de software, você receberá um parceiro profissional, flexível e confiável para apoiar o sucesso de seus produtos de software em todo o mundo.'
  }
}
