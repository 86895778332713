// 搜狗引擎菜单
export const sogouNav = [
  // 首页
  {
    path: '/home-08',
    title: 'menu.text4'
  },
  // AI+人工翻译
  {
    path: '/personalTran',
    title: '人工翻译',
    link: '/client/order',
    children: [
      {
        path: '/docTran',
        title: '文档翻译',
        link: '/client/order'
      },
      {
        path: '/medicineTran',
        title: '医疗医药翻译',
        link: '/client/order'
      },
      {
        path: '/localTran',
        title: '软件/游戏本地化',
        link: '/client/order'
      },
      {
        path: '/idCardTran',
        title: '证件翻译',
        link: '/client/certificateOrder'
      }
    ]
  },
  // AI翻译
  {
    path: '/aiTran',
    title: 'AI翻译',
    link: '/client/machineOrder'
  },
  // 医药翻译
  {
    path: '/pharmaceutical',
    title: 'menu.text20'
  },
  // 软件游戏本地化
  {
    path: '/localization',
    title: 'menu.text21'
  },
  // 解决方案
  // {
  //   path: '/solution',
  //   title: 'menu.text5',
  //   subTitle:"menu.text23",
  //   children: [
  //     {
  //       path: '/solution/artificialIntelligence',
  //       title: 'menu.text7',
  //       icon: 'rgzn'
  //     },
  //     {
  //       path: '/solution/geologicalMetallurgy',
  //       title: 'menu.text8',
  //       icon: 'dkyj'
  //     }
  //   ]
  // },
  // 成功案例
  {
    path: '/successCase',
    title: 'menu.text9'
  },
  // 服务价格
  {
    path: '/servicePrice',
    title: 'menu.text18'
  },
  // 服务价格
  {
    path: '/aboutUs',
    title: 'menu.text11'
  }
]
