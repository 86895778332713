export default {
  zh: {
    text1: '人工智能行业翻译解决方案',
    text2:
      '我们的优译信息人工智能行业翻译解决方案专注于为人工智能领域的客户提供专业的翻译和本地化服务。无论是技术文档、算法说明、研究报告还是市场营销材料，我们都能够为您提供精准的翻译，确保信息在全球范围内传达一致和准确。',
    text3: '咨询服务',
    text4: '特点',
    text5: '技术专业知识',
    text6: '我们的翻译团队具备人工智能领域的专业知识，能够准确理解和翻译技术术语',
    text7: '技术文档翻译',
    text8: '为技术文档、白皮书、技术规格等提供翻译，确保技术信息在不同语言中保持一致性和准确性',
    text9: '算法翻译',
    text10: '提供算法说明、代码注释等的翻译支持，确保研究成果能够被国际同行理解和合作',
    text11: '市场营销材料翻译',
    text12: '为市场营销文案、演示文稿等提供翻译，帮助您在全球市场推广人工智能产品',
    text13: '术语管理',
    text14: '管理人工智能领域术语数据库，确保在不同语言版本中使用一致的技术术语',
    text15: '价值',
    text16: '全球市场扩展',
    text17: '通过翻译，您可以将人工智能产品扩展到全球市场，吸引更多潜在客户和合作伙伴。',
    text18: '技术传播',
    text19: '准确的技术翻译有助于将您的人工智能研究成果分享给国际同行，促进合作和创新。',
    text20: '品牌推广',
    text21: '在不同语言的市场上提供本地化版本，有助于提高品牌在全球范围内的认知度。',
    text22: '合作机会',
    text23: '通过翻译技术文档和算法说明，吸引更多合作伙伴和投资者的注意。',
    text24: '优势',
    text25: '技术专业团队',
    text26: '我们的团队由人工智能领域的专业翻译人员组成，能够确保技术翻译的准确性。',
    text27: '我们管理人工智能领域术语数据库，确保在不同语言版本中使用一致的技术术语。',
    text28: '本地化支持',
    text29: '考虑不同地区文化和技术背景的差异，确保翻译内容适应各种背景。',
    text30: '保密协议',
    text31: '在处理敏感技术信息时，我们严格遵守保密协议，确保信息安全。',
    text32: '紧急支持',
    text33: '我们的团队能够在紧急情况下提供快速的修复和支持，确保您的信息传达及时。',
    text34: '我们致力于为人工智能领域客户提供优秀的翻译支持，满足您的全球传播需求。',
    text35: '通过选择我们的人工智能行业翻译解决方案，您将获得专业、准确、保密性高的服务，有助于您的人工智能产品在全球范围内成功传播。'
  },
  en: {
    text1: 'Translation solutions for the artificial intelligence industry',
    text2:
      "Our Youyi Information Artificial Intelligence Industry Translation Solution focuses on providing professional translation and localization services to clients in the field of artificial intelligence.Whether it's technical documents, algorithm descriptions, research reports, or marketing materials, we can provide you with accurate translations to ensure that information is conveyed consistently and accurately on a global scale.",
    text3: 'consulting service',
    text4: 'characteristic',
    text5: 'Technical expertise',
    text6:
      'Our translation team has professional knowledge in the field of artificial intelligence and is able to accurately understand and translate technical terms',
    text7: 'Translation of technical documents',
    text8:
      'Provide translation for technical documents, white papers, technical specifications, etc., ensuring consistency and accuracy of technical information across different languages',
    text9: 'Algorithm translation',
    text10:
      'Provide translation support for algorithm explanations, code annotations, etc. to ensure that research results can be understood and collaborated with international peers',
    text11: 'Translation of marketing materials',
    text12: 'Provide translation for marketing copywriting, presentations, etc. to help you promote artificial intelligence products in global markets',
    text13: 'Term management',
    text14:
      'Manage the terminology database in the field of artificial intelligence to ensure consistent use of technical terminology across different language versions',
    text15: 'value',
    text16: 'Global market expansion',
    text17: 'Through translation, you can expand your artificial intelligence products to the global market and attract more potential customers and partners.',
    text18: 'Technology dissemination',
    text19:
      'Accurate technical translation helps to share your research findings on artificial intelligence with international peers, promoting cooperation and innovation.',
    text20: 'Brand promotion',
    text21: 'Providing localized versions in markets with different languages can help increase brand awareness globally.',
    text22: 'Cooperation opportunities',
    text23: "By translating technical documents and algorithm explanations, attract more partners and investors' attention.",
    text24: 'advantage',
    text25: 'Technical Professional Team',
    text26: 'Our team is composed of professional translators in the field of artificial intelligence, who can ensure the accuracy of technical translation.',
    text27:
      'We manage a terminology database in the field of artificial intelligence to ensure consistent use of technical terminology across different language versions.',
    text28: 'Localization support',
    text29:
      'Consider the differences in cultural and technological backgrounds among different regions to ensure that the translated content adapts to various backgrounds.',
    text30: 'Confidentiality Agreement',
    text31: 'When handling sensitive technical information, we strictly adhere to confidentiality agreements to ensure information security.',
    text32: 'Emergency support',
    text33: 'Our team is able to provide rapid repair and support in emergency situations, ensuring timely communication of your information.',
    text34:
      'We are committed to providing excellent translation support to clients in the field of artificial intelligence to meet your global communication needs.',
    text35:
      'By choosing our artificial intelligence industry translation solution, you will receive professional, accurate, and highly confidential services that help your artificial intelligence products successfully spread globally.'
  },
  ja: {
    text1: '人工知能業界翻訳ソリューション',
    text2:
      'デルの優訳情報人工知能業界翻訳ソリューションは、人工知能分野のお客様に専門的な翻訳とローカライズサービスを提供することに重点を置いています。テクニカルドキュメント、アルゴリズムの説明、研究報告書、マーケティング資料にかかわらず、世界中で情報が一貫して正確に伝達されるように、正確な翻訳を提供することができます。',
    text3: 'コンサルティングサービス',
    text4: '特徴',
    text5: '技術専門知識',
    text6: 'SDLの翻訳チームは、技術用語の正確な理解と翻訳を可能にする人工知能分野の専門知識を備えています',
    text7: 'テクニカルドキュメントの翻訳',
    text8: '技術文書、ホワイトペーパー、技術仕様などの翻訳を提供し、異なる言語での技術情報の一貫性と正確性を確保',
    text9: 'アルゴリズム翻訳',
    text10: 'アルゴリズムの説明、コード注釈などの翻訳サポートを提供し、研究成果が国際的な同業者に理解され、協力されることを確保する',
    text11: 'マーケティング資料翻訳',
    text12: 'マーケティング・シナリオ、プレゼンテーションなどの翻訳を行い、世界市場での人工知能製品の普及を支援',
    text13: '用語管理',
    text14: '人工知能分野の用語データベースを管理し、異なる言語バージョンで一貫した技術用語を使用できるようにする',
    text15: 'の価値判断を',
    text16: 'グローバル市場の拡大',
    text17: '翻訳を通じて、人工知能製品を世界市場に拡大し、より多くの潜在的な顧客とパートナーを引き付けることができます。',
    text18: '技術伝播',
    text19: '正確な技術翻訳は、人工知能の研究成果を国際的な同業者に共有し、協力と革新を促進するのに役立ちます。',
    text20: 'ブランド展開',
    text21: '異なる言語の市場でローカライズされたバージョンを提供することで、ブランドの世界的な認知度を高めることができます。',
    text22: 'パートナーシップの機会',
    text23: '技術文書とアルゴリズムの説明を翻訳することで、より多くのパートナーや投資家の注目を集めています。',
    text24: '利点',
    text25: '技術専門チーム',
    text26: '私たちのチームは人工知能分野の専門翻訳者で構成されており、技術翻訳の正確性を確保することができます。',
    text27: '人工知能分野の用語データベースを管理し、異なる言語バージョンで一貫した技術用語を使用できるようにしています。',
    text28: 'ローカリゼーションのサポート',
    text29: '地域によって文化や技術的背景の違いを考慮し、翻訳内容がさまざまな背景に適応するようにします。',
    text30: '秘密保持契約',
    text31: '機密技術情報を処理する際には、機密保持契約を厳守し、情報の安全を確保します。',
    text32: '緊急サポート',
    text33: 'デルのチームは、緊急時に迅速な修復とサポートを提供し、お客様の情報をタイムリーに伝えることができます。',
    text34: '私たちは、人工知能分野のお客様に優れた翻訳サポートを提供し、世界的な伝播ニーズに対応することに力を入れています。',
    text35:
      'デルの人工知能業界翻訳ソリューションを選択することで、専門性、正確性、機密性の高いサービスを受けることができ、世界中で人工知能製品の普及を成功させることができます。'
  },
  ko: {
    text1: '인공지능 업계 번역 솔루션',
    text2:
      '우리의 우수한 번역 정보 인공 지능 산업 번역 솔루션은 인공 지능 분야의 고객에게 전문적인 번역 및 현지화 서비스를 제공하는 데 중점을 둡니다.기술 문서, 알고리즘 지침, 연구 보고서 또는 마케팅 자료를 포함하여 전 세계적으로 일관성 있고 정확한 정보를 전달할 수 있도록 정확한 번역을 제공할 수 있습니다.',
    text3: '컨설팅 서비스',
    text4: '특징',
    text5: '기술 전문 지식',
    text6: '우리 번역팀은 인공지능 분야의 전문 지식을 갖추고 있어 기술 용어를 정확하게 이해하고 번역할 수 있다',
    text7: '기술 문서 번역',
    text8: '기술 문서, 백서, 기술 사양 등의 번역을 통해 다양한 언어에서 기술 정보의 일관성 및 정확성 보장',
    text9: '알고리즘 번역',
    text10: '알고리즘 설명, 코드 주석 등의 번역 지원을 제공하여 연구 성과가 국제 동업자에 의해 이해되고 협력될 수 있도록 한다',
    text11: '마케팅 자료 번역',
    text12: '마케팅 문서, 프레젠테이션 등을 번역하여 글로벌 시장에서 인공지능 제품을 홍보할 수 있도록 지원',
    text13: '용어 관리',
    text14: '인공지능 분야 용어 데이터베이스를 관리하여 다양한 언어 버전에서 일관된 기술 용어 사용 보장',
    text15: '가치',
    text16: '글로벌 시장 확장',
    text17: '번역을 통해 인공지능 제품을 글로벌 시장으로 확장하고 더 많은 잠재 고객과 파트너를 유치할 수 있습니다.',
    text18: '기술 전파',
    text19: '정확한 기술 번역은 귀하의 인공 지능 연구 성과를 국제 동료들에게 공유하고 협력과 혁신을 촉진하는 데 도움이 됩니다.',
    text20: '브랜드 홍보',
    text21: '다양한 언어의 시장에서 현지화된 버전을 제공하면 전 세계적으로 브랜드의 인지도를 높이는 데 도움이 된다.',
    text22: '협력 기회',
    text23: '기술 문서 및 알고리즘 설명을 번역하여 파트너와 투자자의 관심을 끌어 보십시오.',
    text24: '이점',
    text25: '기술 전문가 팀',
    text26: '우리 팀은 인공지능 분야의 전문 번역가로 구성돼 기술 번역의 정확성을 확보할 수 있다.',
    text27: '우리는 인공지능 분야 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일관된 기술 용어를 사용하도록 합니다.',
    text28: '현지화 지원',
    text29: '지역별 문화와 기술 배경의 차이를 고려하여 번역 내용이 다양한 배경에 적응할 수 있도록 한다.',
    text30: '비밀 유지 계약',
    text31: '중요한 기술 정보를 처리할 때 기밀 유지 계약을 철저히 준수하여 정보 보안을 보장합니다.',
    text32: '긴급 지원',
    text33: '우리 팀은 긴급 상황에서 신속한 복구와 지원을 제공하여 귀하의 정보가 적시에 전달되도록 보장할 수 있습니다.',
    text34: '우리는 인공지능 분야의 고객에게 우수한 번역 지원을 제공하여 당신의 글로벌 전파 수요를 만족시키기 위해 노력합니다.',
    text35:
      '당사의 인공지능 업계 번역 솔루션을 선택하면 전문적이고 정확하며 비밀이 높은 서비스를 받을 수 있으며 귀하의 인공지능 제품이 전 세계적으로 성공적으로 전파되는 데 도움이 될 것입니다.'
  },
  de: {
    text1: 'Übersetzungslösungen für die Künstliche Intelligenz',
    text2:
      'Unsere Youyi Information Artificial Intelligence Industry Translation Solution konzentriert sich auf die Bereitstellung professioneller Übersetzungs- und Lokalisierungsdienste für Kunden im Bereich künstlicher Intelligenz.Ob technische Dokumente, Algorithmenbeschreibungen, Forschungsberichte oder Marketingmaterialien – wir können Ihnen genaue Übersetzungen liefern, um sicherzustellen, dass Informationen weltweit einheitlich und präzise übermittelt werden.',
    text3: 'Beratung',
    text4: 'Charakteristik',
    text5: 'Technische Expertise',
    text6:
      'Unser Übersetzungsteam verfügt über Fachkenntnisse im Bereich künstlicher Intelligenz und ist in der Lage, Fachbegriffe präzise zu verstehen und zu übersetzen',
    text7: 'Übersetzung technischer Dokumente',
    text8:
      'Bereitstellung von Übersetzungen für technische Dokumente, Whitepaper, technische Spezifikationen usw., um Konsistenz und Genauigkeit technischer Informationen in verschiedenen Sprachen sicherzustellen',
    text9: 'Übersetzung von Algorithmen',
    text10:
      'Übersetzungsunterstützung für Algorithmen-Erklärungen, Code-Annotationen usw., um sicherzustellen, dass Forschungsergebnisse verstanden und mit internationalen Kollegen zusammengearbeitet werden können',
    text11: 'Übersetzung von Marketingmaterialien',
    text12:
      'Bereitstellung von Übersetzungen für Marketing-Texter, Präsentationen usw. zur Förderung von Produkten der künstlichen Intelligenz auf globalen Märkten',
    text13: 'Terminverwaltung',
    text14:
      'Verwaltung der Terminologiedatenbank im Bereich Künstliche Intelligenz, um eine konsistente Verwendung der Fachterminologie über verschiedene Sprachversionen hinweg zu gewährleisten',
    text15: 'Wert',
    text16: 'Globale Marktexpansion',
    text17:
      'Durch Übersetzungen können Sie Ihre Produkte für künstliche Intelligenz auf den globalen Markt ausweiten und mehr potenzielle Kunden und Partner gewinnen.',
    text18: 'Verbreitung von Technologien',
    text19:
      'Genaue technische Übersetzungen helfen dabei, Ihre Forschungsergebnisse über künstliche Intelligenz mit internationalen Kollegen zu teilen und die Zusammenarbeit und Innovation zu fördern.',
    text20: 'Markenförderung',
    text21: 'Die Bereitstellung lokalisierter Versionen in Märkten mit verschiedenen Sprachen kann dazu beitragen, die Markenbekanntheit weltweit zu erhöhen.',
    text22: 'Kooperationsmöglichkeiten',
    text23: 'Durch die Übersetzung technischer Dokumente und Algorithmen-Erklärungen gewinnen Sie mehr Aufmerksamkeit von Partnern und Investoren.',
    text24: 'Vorteil',
    text25: 'Technisches Fachteam',
    text26:
      'Unser Team besteht aus professionellen Übersetzern im Bereich der künstlichen Intelligenz, die die Genauigkeit der technischen Übersetzung sicherstellen können.',
    text27:
      'Wir verwalten eine Terminologiedatenbank im Bereich Künstliche Intelligenz, um eine konsistente Verwendung der Fachterminologie über verschiedene Sprachversionen hinweg zu gewährleisten.',
    text28: 'Unterstützung bei der Lokalisierung',
    text29:
      'Berücksichtigen Sie die Unterschiede in kulturellen und technologischen Hintergründen zwischen den verschiedenen Regionen, um sicherzustellen, dass sich die übersetzten Inhalte an verschiedene Hintergründe anpassen.',
    text30: 'Vertraulichkeitsvereinbarung',
    text31:
      'Beim Umgang mit sensiblen technischen Informationen halten wir uns strikt an Vertraulichkeitsvereinbarungen, um die Informationssicherheit zu gewährleisten.',
    text32: 'Nothilfe',
    text33:
      'Unser Team ist in der Lage, eine schnelle Reparatur und Unterstützung in Notsituationen zu bieten, um sicherzustellen, dass Ihre Informationen rechtzeitig kommuniziert werden.',
    text34:
      'Wir sind bestrebt, Kunden im Bereich künstlicher Intelligenz exzellente Übersetzungsunterstützung zu bieten, um Ihre globalen Kommunikationsanforderungen zu erfüllen.',
    text35:
      'Wenn Sie sich für unsere Branchenübersetzungslösung für künstliche Intelligenz entscheiden, erhalten Sie professionelle, genaue und vertrauliche Dienstleistungen, die Ihre Produkte für künstliche Intelligenz erfolgreich weltweit verbreiten.'
  },
  fr: {
    text1: "Solutions de traduction pour l'industrie de l'intelligence artificielle",
    text2:
      "Nos solutions de traduction pour l'industrie de l'intelligence artificielle se concentrent sur la fourniture de services professionnels de traduction et de localisation à nos clients dans le domaine de l'intelligence artificielle.Qu'il s'agisse de documents techniques, de notes d'algorithmes, de rapports de recherche ou de documents marketing, nous sommes en mesure de vous fournir des traductions précises, garantissant que les informations sont communiquées de manière cohérente et précise dans le monde entier.",
    text3: 'Services consultatifs',
    text4: 'Caractéristiques',
    text5: 'Expertise technique',
    text6:
      'Notre équipe de traducteurs possède une expertise dans le domaine de l’intelligence artificielle pour comprendre et traduire avec précision les termes techniques',
    text7: 'Traduction de documents techniques',
    text8:
      'Fournir des traductions pour des documents techniques, des livres blancs, des spécifications techniques, etc., garantissant la cohérence et la précision des informations techniques dans différentes langues',
    text9: 'Traduction algorithmique',
    text10:
      "Fournir un soutien à la traduction pour les descriptions d'algorithmes, les annotations de code, etc., en veillant à ce que les résultats de la recherche puissent être compris et collaboratifs par des pairs internationaux",
    text11: 'Traduction de matériel Marketing',
    text12:
      "Fournir des traductions pour la rédaction marketing, des présentations et plus encore pour vous aider à promouvoir vos produits d'intelligence artificielle sur le marché mondial",
    text13: 'Gestion terminologique',
    text14:
      "Gestion de bases de données terminologiques dans le domaine de l'intelligence artificielle, garantissant une terminologie technique cohérente dans les différentes versions linguistiques",
    text15: 'Valeur',
    text16: 'Expansion du marché mondial',
    text17:
      "Grâce à la traduction, vous pouvez étendre vos produits d'intelligence artificielle au marché mondial et attirer plus de clients et de partenaires potentiels.",
    text18: 'Diffusion de la technologie',
    text19:
      "Des traductions techniques précises aident à partager les résultats de vos recherches en ia avec vos homologues internationaux, favorisant ainsi la collaboration et l'innovation.",
    text20: 'Promotion de la marque',
    text21:
      'La disponibilité de versions localisées sur le marché dans différentes langues contribue à accroître la notoriété de la marque dans le monde entier.',
    text22: 'Opportunités de coopération',
    text23:
      "Attirez l'attention d'un plus grand nombre de partenaires et d'investisseurs en traduisant la documentation technique et les descriptions d'algorithmes.",
    text24: 'Avantages',
    text25: 'Équipe de professionnels techniques',
    text26:
      'Notre équipe est composée de traducteurs spécialisés dans le domaine de l’intelligence artificielle, capables d’assurer la précision des traductions techniques.',
    text27:
      "Nous gérons des bases de données terminologiques dans le domaine de l'intelligence artificielle, garantissant l'utilisation de termes techniques cohérents dans les différentes versions linguistiques.",
    text28: 'Soutien à la localisation',
    text29:
      'Prenez en compte les différences entre les contextes culturels et techniques des différentes régions et assurez - vous que le contenu traduit est adapté à chaque contexte.',
    text30: 'Accord de confidentialité',
    text31:
      'Lorsque nous traitons des informations techniques sensibles, nous veillons à la sécurité des informations en respectant strictement les accords de confidentialité.',
    text32: "Soutien d'urgence",
    text33:
      "Notre équipe est en mesure de fournir une réparation rapide et un soutien en cas d'urgence, en veillant à ce que votre message soit transmis en temps opportun.",
    text34:
      "Nous nous engageons à fournir un excellent support de traduction à nos clients dans le domaine de l'intelligence artificielle pour répondre à vos besoins de communication mondiale.",
    text35:
      "En choisissant nos solutions de traduction pour l'industrie de l'IA, vous bénéficiez d'un service professionnel, précis et hautement confidentiel qui contribue à la diffusion réussie de vos produits d'IA dans le monde entier."
  },
  ru: {
    text1: 'Решения для перевода в индустрии искусственного интеллекта',
    text2:
      'Наши переводческие решения для индустрии искусственного интеллекта ориентированы на предоставление профессиональных услуг по переводу и локализации для клиентов в области искусственного интеллекта.Независимо от того, является ли это технической документацией, инструкциями по алгоритмам, исследованиями или маркетинговыми материалами, мы можем предоставить вам точный перевод, чтобы информация передавалась последовательно и точно по всему миру.',
    text3: 'Консультативные услуги',
    text4: 'Особенности',
    text5: 'Технический опыт',
    text6: 'Наша команда переводчиков обладает опытом в области искусственного интеллекта, может точно понимать и переводить технические термины',
    text7: 'Перевод технической документации',
    text8:
      'Перевод технической документации, & lt; & lt; белых книг & gt; & gt;, технических спецификаций и т.д. для обеспечения согласованности и точности технической информации на различных языках',
    text9: 'Алгоритмический перевод',
    text10:
      'Предоставление поддержки перевода для описания алгоритмов, комментариев к коду и т. Д. Для обеспечения понимания и сотрудничества результатов исследований международными коллегами',
    text11: 'Перевод маркетинговых материалов',
    text12: 'Перевод маркетинговых текстов, презентаций и т. Д. Чтобы помочь вам продвигать продукты искусственного интеллекта на мировом рынке',
    text13: 'Терминология',
    text14:
      'Управление терминологической базой данных в области искусственного интеллекта для обеспечения последовательного использования технической терминологии в различных языковых версиях',
    text15: 'Стоимость',
    text16: 'Расширение глобальных рынков',
    text17: 'Благодаря переводу вы можете расширить продукты искусственного интеллекта на мировой рынок, привлекая больше потенциальных клиентов и партнеров.',
    text18: 'Распространение технологий',
    text19:
      'Точный технический перевод помогает делиться результатами своих исследований в области искусственного интеллекта с международными коллегами и способствует сотрудничеству и инновациям.',
    text20: 'Продвижение марки',
    text21: 'Доступ к локализованным версиям на различных языках помогает повысить осведомленность о брендах во всем мире.',
    text22: 'Возможности сотрудничества',
    text23: 'Перевод технических документов и алгоритмических инструкций привлекает внимание большего числа партнеров и инвесторов.',
    text24: 'Преимущество',
    text25: 'Профессионально - техническая группа',
    text26:
      'Наша команда состоит из профессиональных переводчиков в области искусственного интеллекта, которые могут обеспечить точность технического перевода.',
    text27:
      'Мы управляем терминологической базой данных в области искусственного интеллекта, обеспечивая последовательное использование технической терминологии в различных языковых версиях.',
    text28: 'Поддержка локализации',
    text29:
      'Принимая во внимание различия в культурном и техническом контексте в разных регионах, убедитесь, что содержание перевода адаптировано к различным контекстам.',
    text30: 'Соглашение о конфиденциальности',
    text31:
      'При работе с конфиденциальной технической информацией мы строго соблюдаем соглашение о конфиденциальности и обеспечиваем информационную безопасность.',
    text32: 'Экстренная поддержка',
    text33: 'Наша команда может обеспечить быстрое восстановление и поддержку в чрезвычайных ситуациях, гарантируя своевременную передачу вашей информации.',
    text34:
      'Мы стремимся обеспечить отличную поддержку перевода для клиентов в области искусственного интеллекта, чтобы удовлетворить ваши глобальные коммуникационные потребности.',
    text35:
      'Выбирая наши переводческие решения для индустрии искусственного интеллекта, вы получаете профессиональные, точные и конфиденциальные услуги, которые помогут вашему продукту искусственного интеллекта успешно распространяться по всему миру.'
  },
  th: {
    text1: 'โซลูชั่นการแปลอุตสาหกรรมปัญญาประดิษฐ์',
    text2:
      'โซลูชันการแปลอุตสาหกรรมปัญญาประดิษฐ์ของเรามุ่งเน้นการให้บริการแปลภาษาระดับมืออาชีพและการแปลภาษาสำหรับลูกค้าในสาขา AIไม่ว่าจะเป็นเอกสารทางเทคนิคคำแนะนำอัลกอริทึมรายงานการวิจัยหรือสื่อการตลาดเราสามารถให้การแปลที่แม่นยำเพื่อให้มั่นใจว่าข้อมูลจะถูกส่งออกไปทั่วโลกอย่างสม่ำเสมอและแม่นยำ',
    text3: 'บริการให้คำปรึกษา',
    text4: 'คุณสมบัติ',
    text5: 'ความเชี่ยวชาญทางเทคนิค',
    text6: 'ทีมนักแปลของเรามีความเชี่ยวชาญในด้าน AI และสามารถเข้าใจและแปลคำศัพท์ทางเทคนิคได้อย่างแม่นยำ',
    text7: 'การแปลเอกสารทางเทคนิค',
    text8: 'ให้การแปลเอกสารทางเทคนิคกระดาษสีขาวข้อกำหนดทางเทคนิคและอื่น ๆ เพื่อให้แน่ใจว่าข้อมูลทางเทคนิคมีความสอดคล้องและแม่นยำในภาษาที่แตกต่างกัน',
    text9: 'การแปลอัลกอริทึม',
    text10: 'ให้การสนับสนุนการแปลคำอธิบายอัลกอริทึมคำอธิบายโค้ด ฯลฯ เพื่อให้แน่ใจว่าผลการวิจัยสามารถเข้าใจและร่วมมือกันได้โดยเพื่อนร่วมงานระหว่างประเทศ',
    text11: 'แปลเอกสารการตลาด',
    text12: 'ให้บริการแปลคำโฆษณาการตลาดการนำเสนอและอื่น ๆ เพื่อช่วยให้คุณโปรโมตผลิตภัณฑ์ AI ในตลาดโลก',
    text13: 'การจัดการคำศัพท์',
    text14: 'จัดการฐานข้อมูลคำศัพท์ในสาขา AI เพื่อให้แน่ใจว่ามีการใช้คำศัพท์ทางเทคนิคที่สอดคล้องกันในเวอร์ชันภาษาต่างๆ',
    text15: 'ความคุ้มค่า',
    text16: 'การขยายตัวของตลาดโลก',
    text17: 'ด้วยการแปลภาษาคุณสามารถขยายผลิตภัณฑ์ AI ไปยังตลาดโลกและดึงดูดลูกค้าที่มีศักยภาพและคู่ค้าได้มากขึ้น',
    text18: 'การสื่อสารเทคโนโลยี',
    text19: 'การแปลทางเทคนิคที่แม่นยำช่วยแบ่งปันผลการวิจัย AI ของคุณให้กับเพื่อนร่วมงานระหว่างประเทศส่งเสริมความร่วมมือและนวัตกรรม',
    text20: 'การส่งเสริมแบรนด์',
    text21: 'การนำเสนอเวอร์ชันท้องถิ่นในตลาดในภาษาต่าง ๆ ช่วยเพิ่มการรับรู้แบรนด์ไปทั่วโลก',
    text22: 'โอกาสความร่วมมือ',
    text23: 'ดึงดูดความสนใจของคู่ค้าและนักลงทุนมากขึ้นด้วยการแปลเอกสารทางเทคนิคและคำแนะนำอัลกอริทึม',
    text24: 'ข้อดี',
    text25: 'ทีมงานมืออาชีพด้านเทคนิค',
    text26: 'ทีมงานของเราประกอบด้วยนักแปลมืออาชีพในสาขา AI ซึ่งสามารถรับประกันความถูกต้องของการแปลทางเทคนิค',
    text27: 'เราจัดการฐานข้อมูลคำศัพท์ในสาขา AI เพื่อให้แน่ใจว่ามีการใช้คำศัพท์ทางเทคนิคที่สอดคล้องกันในเวอร์ชันภาษาต่างๆ',
    text28: 'การสนับสนุนการแปล',
    text29: 'พิจารณาความแตกต่างของภูมิหลังทางวัฒนธรรมและเทคโนโลยีของภูมิภาคต่าง ๆ เพื่อให้แน่ใจว่าเนื้อหาที่แปลจะปรับให้เข้ากับบริบทที่หลากหลาย',
    text30: 'ข้อตกลงการรักษาความลับ',
    text31: 'เมื่อจัดการกับข้อมูลทางเทคนิคที่ละเอียดอ่อนเราปฏิบัติตามข้อตกลงการรักษาความลับอย่างเคร่งครัดเพื่อความปลอดภัยของข้อมูล',
    text32: 'การสนับสนุนฉุกเฉิน',
    text33: 'ทีมงานของเราสามารถให้การแก้ไขและการสนับสนุนที่รวดเร็วในกรณีฉุกเฉินเพื่อให้แน่ใจว่าข้อความของคุณได้รับการสื่อสารอย่างทันท่วงที',
    text34: 'เรามุ่งมั่นที่จะให้การสนับสนุนการแปลที่ยอดเยี่ยมแก่ลูกค้าในสาขา AI เพื่อตอบสนองความต้องการด้านการสื่อสารทั่วโลกของคุณ',
    text35:
      'ด้วยการเลือกโซลูชันการแปลอุตสาหกรรมปัญญาประดิษฐ์ของเราคุณจะได้รับบริการที่เป็นมืออาชีพถูกต้องและเป็นความลับซึ่งจะช่วยให้ผลิตภัณฑ์ปัญญาประดิษฐ์ของคุณประสบความสำเร็จในการเผยแพร่ไปทั่วโลก'
  },
  es: {
    text1: 'Soluciones de traducción para la industria de inteligencia artificial',
    text2:
      'Nuestras soluciones de traducción de la industria de inteligencia artificial de Youyi Information se centran en proporcionar servicios profesionales de traducción y localización a clientes en el campo de la inteligencia artificial.Ya sea documentos técnicos, instrucciones de algoritmos, informes de investigación o materiales de marketing, podemos proporcionarle una traducción precisa para garantizar que la información se transmita de manera coherente y precisa en todo el mundo.',
    text3: 'Servicios de consultoría',
    text4: 'Características',
    text5: 'Experiencia técnica',
    text6:
      'Nuestro equipo de traducción tiene experiencia en el campo de la inteligencia artificial y puede comprender y traducir términos técnicos con precisión.',
    text7: 'Traducción de documentos técnicos',
    text8:
      'Traducción de documentos técnicos, libros blancos, especificaciones técnicas, etc., para garantizar la coherencia y precisión de la información técnica en diferentes idiomas',
    text9: 'Traducción de algoritmos',
    text10:
      'Proporcionar soporte de traducción para instrucciones de algoritmos, anotaciones de código, etc., para garantizar que los resultados de la investigación puedan ser entendidos y cooperados por colegas internacionales.',
    text11: 'Traducción de materiales de marketing',
    text12: 'Traducción de copywriting de marketing, presentaciones, etc., para ayudarle a promover productos de inteligencia artificial en el mercado global',
    text13: 'Gestión de la terminología',
    text14:
      'Gestionar una base de datos de términos en el campo de la inteligencia artificial para garantizar el uso de términos técnicos consistentes en diferentes versiones lingüísticas',
    text15: 'Valor',
    text16: 'Expansión del mercado mundial',
    text17:
      'A través de la traducción, puede expandir los productos de inteligencia artificial al mercado global y atraer a más clientes potenciales y socios.',
    text18: 'Difusión de la tecnología',
    text19:
      'Una traducción técnica precisa ayuda a compartir los resultados de su investigación en inteligencia artificial con sus homólogos internacionales y promueve la cooperación y la innovación.',
    text20: 'Promoción de la marca',
    text21: 'Proporcionar versiones localizadas en el mercado en diferentes idiomas ayuda a aumentar la conciencia de la marca en todo el mundo.',
    text22: 'Oportunidades de cooperación',
    text23: 'A través de la traducción de documentos técnicos y descripciones de algoritmos, atrae la atención de más socios e inversores.',
    text24: 'Ventaja',
    text25: 'Equipo profesional técnico',
    text26:
      'Nuestro equipo está compuesto por traductores profesionales en el campo de la inteligencia artificial, que pueden garantizar la precisión de la traducción técnica.',
    text27:
      'Gestionamos una base de datos de términos en el campo de la inteligencia artificial para garantizar el uso de términos técnicos consistentes en diferentes versiones lingüísticas.',
    text28: 'Soporte localizado',
    text29:
      'Considerar las diferencias en los antecedentes culturales y técnicos de las diferentes regiones para garantizar que el contenido de la traducción se adapte a los diversos antecedentes.',
    text30: 'Acuerdo de confidencialidad',
    text31:
      'Al procesar información técnica sensible, cumplimos estrictamente con los protocolos de confidencialidad para garantizar la seguridad de la información.',
    text32: 'Apoyo urgente',
    text33: 'Nuestro equipo puede proporcionar reparaciones y apoyo rápidos en situaciones de emergencia para garantizar que su mensaje se transmita a tiempo.',
    text34:
      'Nos comprometemos a proporcionar un excelente apoyo de traducción a los clientes en el campo de la inteligencia artificial para satisfacer sus necesidades de comunicación global.',
    text35:
      'Al elegir nuestras soluciones de traducción de la industria de inteligencia artificial, obtendrá servicios profesionales, precisos y confidenciales que ayudarán a que sus productos de inteligencia artificial se difundan con éxito en todo el mundo.'
  },
  pt: {
    text1: 'Soluções de tradução para a indústria de inteligência artificial',
    text2:
      'Nossa solução Youyi Information Artificial Intelligence Industry Translation centra-se em fornecer serviços profissionais de tradução e localização para clientes no campo da inteligência artificial.Quer se trate de documentos técnicos, descrições de algoritmos, relatórios de pesquisa ou materiais de marketing, podemos fornecer traduções precisas para garantir que as informações sejam transmitidas de forma consistente e precisa em escala global.',
    text3: 'serviço de consultoria',
    text4: 'característica',
    text5: 'Peritos técnicos',
    text6:
      'Nossa equipe de tradução possui conhecimento profissional na área de inteligência artificial e é capaz de entender e traduzir com precisão termos técnicos',
    text7: 'Tradução de documentos técnicos',
    text8:
      'Fornecer tradução de documentos técnicos, white papers, especificações técnicas, etc., garantindo consistência e precisão das informações técnicas em diferentes idiomas',
    text9: 'Tradução do algoritmo',
    text10:
      'Fornecer suporte de tradução para explicações de algoritmos, anotações de código, etc., para garantir que os resultados da pesquisa possam ser compreendidos e colaborados com pares internacionais',
    text11: 'Tradução de materiais de marketing',
    text12:
      'Fornecer tradução para redação de textos de marketing, apresentações, etc., para ajudá-lo a promover produtos de inteligência artificial em mercados globais',
    text13: 'Gestão de prazos',
    text14:
      'Gerencie o banco de dados terminológicos no campo da inteligência artificial para garantir o uso consistente da terminologia técnica em diferentes versões linguísticas',
    text15: 'valor',
    text16: 'Expansão do mercado global',
    text17:
      'Através da tradução, você pode expandir seus produtos de inteligência artificial para o mercado global e atrair mais clientes e parceiros potenciais.',
    text18: 'Divulgação tecnológica',
    text19:
      'A tradução técnica precisa ajuda a compartilhar suas descobertas de pesquisa sobre inteligência artificial com colegas internacionais, promovendo cooperação e inovação.',
    text20: 'Promoção da marca',
    text21: 'Fornecer versões localizadas em mercados com diferentes idiomas pode ajudar a aumentar o reconhecimento da marca globalmente.',
    text22: 'Oportunidades de cooperação',
    text23: 'Ao traduzir documentos técnicos e explicações de algoritmos, atraia mais a atenção de parceiros e investidores.',
    text24: 'vantagem',
    text25: 'Equipa Técnica Profissional',
    text26: 'Nossa equipe é composta por tradutores profissionais na área de inteligência artificial, que podem garantir a precisão da tradução técnica.',
    text27:
      'Gerimos uma base de dados terminológica na área de inteligência artificial para garantir o uso consistente da terminologia técnica em diferentes versões linguísticas.',
    text28: 'Suporte à localização',
    text29:
      'Considere as diferenças de origens culturais e tecnológicas entre as diferentes regiões para garantir que o conteúdo traduzido se adapte a várias origens.',
    text30: 'Acordo de confidencialidade',
    text31: 'Ao lidar com informações técnicas sensíveis, aderimos estritamente a acordos de confidencialidade para garantir a segurança da informação.',
    text32: 'Apoio de emergência',
    text33: 'Nossa equipe é capaz de fornecer reparos rápidos e suporte em situações de emergência, garantindo a comunicação oportuna de suas informações.',
    text34:
      'Estamos empenhados em fornecer excelente suporte de tradução aos clientes no campo da inteligência artificial para atender às suas necessidades globais de comunicação.',
    text35:
      'Ao escolher nossa solução de tradução para o setor de inteligência artificial, você receberá serviços profissionais, precisos e altamente confidenciais que ajudam seus produtos de inteligência artificial a se espalhar globalmente com sucesso.'
  }
}
