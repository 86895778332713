import mitt from 'mitt'
import { uuid } from './util'

// 定义分页类
export class Pagination {
  private Page: StoreState.Pagination = {
    position: 'bottom',
    showQuickJumper: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total: number) => `总 ${total} 条`
  }

  constructor(params?: StoreState.Pagination) {
    Object.assign(this.Page, params)
  }

  init() {
    return this.Page
  }
}

// modal公共属性
export const modalProps = {
  maskClosable: false
}

// 跨组建通信（类似EventBus）
export const $mitt = mitt()

// 注册事件总站
export const $bus = {
  emit: <T>(key: string, params?: T) => {
    $mitt.emit(key, params)
  },
  on: (key: string, func: any) => {
    $mitt.off(key)
    $mitt.on(key, func)
  },
  off: (key: string) => {
    $mitt.off(key)
  }
}

// 单独导航显示黑名单
export const MenuBlackList = ['/login', '/login/', '/register', '/forgotPassword']

// 底部显示 “关于我们” 黑名单
export const FooterBlackList = ['/login', '/login/', '/register', '/forgotPassword']

// 国际化映射
export const i18nMap = {
  'zh-cn': 'zh-cn',
  'zh-Cn': 'zh-cn',
  zh: 'zh-cn',
  cn: 'zh-cn',
  zhCN: 'zh-cn',
  zhCn: 'zh-cn',
  zhcn: 'zh-cn',
  zh_cn: 'zh-cn',
  zh_Cn: 'zh-cn',
  en: 'en',
  enUS: 'en',
  ja: 'ja',
  ko: 'ko',
  de: 'de',
  fr: 'fr',
  th: 'th',
  es: 'es',
  pt: 'pt',
  'ru-RU': 'ru-RU',
  ru: 'ru-RU',
  ruRU: 'ru-RU'
}

// 热门服务
export const HotService = [
  {
    title: '文档翻译',
    desc: '适用于合同标书、专业论文、简历简介、留学移民材料、产品手册、技术文档、机械、电力、水利、土建工程类',
    price: 59,
    value: 1,
    unit: '元/千字',
    linkParams: { purpose: 1 }
  },
  {
    title: '医疗医药翻译',
    desc: '例：医学翻译、医药翻译、医疗器械翻译、注册申报资料翻译、GMP认证材料翻译、产品说明书翻译、医学及医药相关文献翻译',
    price: 90,
    value: 2,
    unit: '元/千字起',
    linkParams: { purpose: 2 }
  },
  {
    title: '软件/游戏本地化',
    desc: '例：软件UI、游戏UI、网站UI、APP、小程序、发表专利、专著等',
    price: 300,
    value: 3,
    unit: '元/千字起',
    linkParams: { purpose: 3 }
  },
  {
    title: '证件翻译',
    desc: '拥有证件翻译机构必备资质，可盖章 ，证件译文受到官方认可。身份证、户口本、成绩单、学位证书、毕业证、驾驶执照、出生证、各国结婚证、病例、营业执照等',
    price: 50,
    isHide: true,
    unit: '元/份',
    link: '/client/certificateOrder',
    linkParams: null
  }
]
