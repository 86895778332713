import qs from 'qs'
import fetch from '@/utils/fetch'
import { ApiConfig } from '@/utils/apiConfig'
import { IAccountApi } from './types/account_types'

const BaseUrl = ApiConfig[window.env]

const AccountApi: IAccountApi = {
  // 获取微信登录扫码
  getWechatQR: (params) => {
    return fetch({
      method: 'get', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: `/frame/getWechatQR`, // 请求地址
      params
    })
  },
  // 获取钉钉登录扫码
  getDingtalkQR: (params) => {
    return fetch({
      method: 'get', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/getDingtalkQR', // 请求地址
      params
    })
  },
  // 手机号+验证码登录
  loginWidthPhone: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/loginWithPhone', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 手机号+验证码登录
  loginWithScan: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/loginWithScan', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 修改绑定信息
  modifyBinding: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/modifyBinding', // 请求地址
      data: qs.stringify(params)
    })
  },
  dingtalkAuth: (params) => {
    return fetch({
      method: 'get', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: 'listener/dingtalkAuth', // 请求地址
      params
    })
  },
  // 账号密码登录
  loginWidthPass: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/loginWithPass', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 发送登录验证码
  sendYZCode: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/sendYZCode', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 发送注册验证码
  sendVerificationCode: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/sendVerificationCode', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 忘记密码的确认修改
  resetPasswd: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/resetPasswd', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 发送找回密码验证码
  sendFindPsdMsg: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/sendFindPsdMsg', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 获取图片验证码
  verifyYzmCode: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/verifyYzmCode', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 手机号是否注册
  checkPhoneRegister: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/checkPhoneRegister', // 请求地址
      data: qs.stringify(params)
    })
  },
  // 注册
  register: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/register', // 请求地址
      data: params
    })
  },
  // 获取图片验证码
  generatePic: (params) => {
    const query = qs.stringify(params)
    return `${BaseUrl['OrderPortalFront']}/frame/generatePic?${query}`
  },
  // 注销登录
  loginOut: (params) => {
    return fetch({
      method: 'post', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/user/loginOut', // 请求地址
      data: params
    })
  },
  // 判断用户是否登录
  ifLoginUser: (params) => {
    return fetch({
      noShowErr: true,
      method: 'get', // 请求方式
      prefix: 'OrderPortalFront', // 前缀
      url: '/frame/ifLoginUser', // 请求地址
      params
    })
  }
}

export default AccountApi
