export default {
  zh: {
    text1: '医药行业翻译解决方案',
    text2:
      '我们的优译信息医药行业翻译解决方案致力于为医药领域的客户提供高质量的翻译和本地化服务。无论是医疗记录、药品说明书、临床试验文件还是医疗设备文档，我们都可以为您提供专业的翻译支持，确保信息的准确性、一致性和符合法规要求。',
    text3: '咨询服务',
    text4: '特点',
    text5: '医学专业知识',
    text6: '我们拥有医学领域的专业翻译人员，了解医学术语和领域知识，确保翻译内容准确无误',
    text7: '药品说明书翻译',
    text8: '提供药品说明书、标签、包装等翻译服务，确保药品信息在不同语言中的一致性和准确性',
    text9: '临床试验文件翻译',
    text10: '为临床试验文件、病例报告等提供翻译支持，确保研究结果在国际范围内传达一致',
    text11: '医疗设备文档翻译',
    text12: '为医疗设备的使用手册、技术规格和操作指南等提供翻译服务，以支持全球用户',
    text13: '法规合规性',
    text14: '我们确保翻译内容符合当地和国际的法规要求，避免违规问题',
    text15: '价值',
    text16: '我们的翻译解决方案确保医药文件在各种语言中符合当地和国际法规的要求。',
    text17: '全球市场进入',
    text18: '通过翻译，您可以轻松进入全球市场，为不同地区的医疗专业人员和患者提供信息。',
    text19: '医学准确性',
    text20: '我们的医学专业翻译团队确保翻译内容准确无误，避免可能的误解。',
    text21: '患者安全',
    text22: '准确的药品说明书和标签翻译有助于确保患者正确使用药品，提高患者安全性。',
    text23: '临床合作',
    text24: '通过为临床试验文件提供翻译支持，促进国际间的合作和信息共享。',
    text25: '优势',
    text26: '医学专业团队',
    text27: '我们的团队由医学翻译专家组成，确保翻译内容准确、一致，并符合医学标准。',
    text28: '术语管理',
    text29: '管理医学术语数据库，确保在不同语言版本中使用一致的医学术语。',
    text30: '我们了解医药法规要求，确保翻译内容符合各国法规标准。',
    text31: '保密性',
    text32: '在处理敏感医疗信息时，我们严格遵守保密协议，确保信息安全。',
    text33: '本地化支持',
    text34: '考虑不同地区文化和法规的差异，确保翻译内容适应各种背景。',
    text35: '我们致力于为医药领域客户提供优秀的翻译支持，满足您的全球传播需求。',
    text36: '通过选择我们的医药行业翻译解决方案，您将获得专业、法规合规、保密性高的服务，有助于您的医药产品在国际市场上成功传播。'
  },
  en: {
    text1: 'Translation solutions for the pharmaceutical industry',
    text2:
      "Our Youyi Information Pharmaceutical Industry Translation Solution is committed to providing high-quality translation and localization services to customers in the pharmaceutical industry.Whether it's medical records, drug instructions, clinical trial files, or medical device documents, we can provide professional translation support to ensure the accuracy, consistency, and compliance with regulatory requirements of the information.",
    text3: 'consulting service',
    text4: 'characteristic',
    text5: 'Medical professional knowledge',
    text6:
      'We have professional translators in the medical field who understand medical terminology and domain knowledge to ensure the accuracy of translation content',
    text7: 'Translation of drug instructions',
    text8:
      'Provide translation services for drug instructions, labels, packaging, etc. to ensure consistency and accuracy of drug information in different languages',
    text9: 'Translation of clinical trial documents',
    text10:
      'Provide translation support for clinical trial documents, case reports, etc. to ensure consistent communication of research results internationally',
    text11: 'Translation of medical equipment documents',
    text12: 'Provide translation services for medical equipment manuals, technical specifications, and operating guidelines to support global users',
    text13: 'Regulatory compliance',
    text14: 'We ensure that the translated content complies with local and international regulatory requirements to avoid violations',
    text15: 'value',
    text16: 'Our translation solution ensures that medical documents comply with local and international regulatory requirements in various languages.',
    text17: 'Global market entry',
    text18: 'Through translation, you can easily enter the global market and provide information for medical professionals and patients in different regions.',
    text19: 'Medical accuracy',
    text20: 'Our medical professional translation team ensures the accuracy of the translation content and avoids potential misunderstandings.',
    text21: 'Patient safety',
    text22: 'Accurate translation of drug instructions and labels helps to ensure that patients use drugs correctly and improve patient safety.',
    text23: 'Clinical collaboration',
    text24: 'Promote international cooperation and information sharing by providing translation support for clinical trial documents.',
    text25: 'advantage',
    text26: 'Medical Professional Team',
    text27: 'Our team is composed of medical translation experts to ensure accurate, consistent, and compliant translation content with medical standards.',
    text28: 'Term management',
    text29: 'Manage a medical terminology database to ensure consistent use of medical terminology across different language versions.',
    text30: 'We understand the requirements of medical regulations and ensure that the translated content meets the regulatory standards of various countries.',
    text31: 'Confidentiality',
    text32: 'When dealing with sensitive medical information, we strictly adhere to confidentiality agreements to ensure information security.',
    text33: 'Localization support',
    text34: 'Consider the cultural and regulatory differences in different regions to ensure that the translated content adapts to various backgrounds.',
    text35: 'We are committed to providing excellent translation support to pharmaceutical clients to meet your global communication needs.',
    text36:
      'By choosing our pharmaceutical industry translation solution, you will receive professional, regulatory compliant, and highly confidential services, which will help your pharmaceutical products successfully spread in the international market.'
  },
  ja: {
    text1: '医薬業界翻訳ソリューション',
    text2:
      'デルの優訳情報医薬業界翻訳ソリューションは、医薬分野のお客様に質の高い翻訳とローカライズサービスを提供することに力を入れています。医療記録、医薬品説明書、治験文書、医療機器文書のいずれにおいても、情報の正確性、一貫性、および規制要件への適合を保証するための専門的な翻訳サポートを提供します。',
    text3: 'コンサルティングサービス',
    text4: '特徴',
    text5: '医学専門知識',
    text6: '私たちは医学分野の専門翻訳者を持ち、医学用語と分野の知識を理解し、翻訳内容の正確さを確保しています',
    text7: '薬品説明書の翻訳',
    text8: '医薬品の説明書、ラベル、包装などの翻訳サービスを提供し、異なる言語における医薬品情報の一致性と正確性を確保する',
    text9: '治験文書翻訳',
    text10: '臨床試験文書、症例報告などの翻訳サポートを提供し、研究結果が国際的に一貫して伝達されることを確保する',
    text11: '医療機器ドキュメント翻訳',
    text12: '医療機器の使用マニュアル、技術仕様、操作ガイドなどの翻訳サービスを提供し、世界中のユーザーをサポートする',
    text13: '法令順守',
    text14: 'EMCは、翻訳内容が地域および国際的な規制要件に適合していることを確認し、違反の問題を回避します',
    text15: 'の価値判断を',
    text16: 'EMCの翻訳ソリューションは、医薬文書がさまざまな言語で現地および国際規制の要件に適合していることを保証します。',
    text17: 'グローバル市場参入',
    text18: '翻訳を通じて、世界市場に簡単に参入し、地域の医療専門家や患者に情報を提供することができます。',
    text19: '医学的正確性',
    text20: '私たちの医学専門翻訳チームは、翻訳内容が正確であることを確認し、誤解を避けることができます。',
    text21: '患者の安全',
    text22: '正確な薬品説明書とラベル翻訳は患者が薬品を正しく使用することを確保し、患者の安全性を高めるのに役立つ。',
    text23: '臨床協力',
    text24: '治験文書の翻訳サポートを提供することにより、国際間の協力と情報共有を促進する。',
    text25: '利点',
    text26: '医学専門チーム',
    text27: '私たちのチームは医学翻訳の専門家で構成されており、翻訳内容が正確で一致し、医学基準に合致することを確保しています。',
    text28: '用語管理',
    text29: '医学用語データベースを管理し、異なる言語バージョンで一貫した医学用語を使用することを確認します。',
    text30: '私たちは医薬法規の要件を理解し、翻訳内容が各国の法規基準に合致することを確保します。',
    text31: '機密性',
    text32: '敏感な医療情報を処理する際には、機密保持契約を厳格に遵守し、情報の安全を確保します。',
    text33: 'ローカリゼーションのサポート',
    text34: '地域によって異なる文化や規制の違いを考慮し、翻訳内容がさまざまな背景に適していることを確認します。',
    text35: 'EMCでは、グローバルな普及ニーズに対応するために、医薬分野のお客様に優れた翻訳サポートを提供することに力を入れています。',
    text36:
      'EMCの医薬業界翻訳ソリューションを選択することで、専門性、法令順守、機密性の高いサービスを受けることができ、国際市場における医薬品の普及に役立ちます。'
  },
  ko: {
    text1: '의약업계 번역 솔루션',
    text2:
      '우리의 우수한 번역 정보 의약 업계 번역 솔루션은 의약 분야의 고객에게 고품질의 번역과 현지화 서비스를 제공하기 위해 노력한다.의료 기록, 의약품 설명서, 임상 시험 문서 또는 의료 장비 문서에 관계없이 Dell은 정보의 정확성, 일관성 및 규정 준수를 보장하는 전문적인 번역 지원을 제공합니다.',
    text3: '컨설팅 서비스',
    text4: '특징',
    text5: '의학 전문 지식',
    text6: '우리는 의학 분야의 전문 번역 인원을 보유하고 있으며, 의학 용어와 분야 지식을 이해하여 번역 내용이 정확하고 틀림없음을 확보한다',
    text7: '약품 설명서 번역',
    text8: '약품설명서, 라벨, 포장 등 번역서비스를 제공하여 약품정보가 부동한 언어에서 일치성과 정확성을 확보하여야 한다.',
    text9: '임상 시험 파일 번역',
    text10: '임상시험 문서, 병례 보고 등에 번역 지원을 제공하여 연구 결과가 국제적으로 일관되게 전달되도록 확보한다',
    text11: '의료 장비 문서 번역',
    text12: '전 세계 사용자를 지원하기 위해 의료 장비 사용 설명서, 기술 사양 및 운영 지침 등에 대한 번역 서비스 제공',
    text13: '규정 준수',
    text14: '우리는 번역 내용이 현지 및 국제 법규 요구에 부합되고 규정 위반 문제를 피할 수 있도록 보장합니다',
    text15: '가치',
    text16: '우리의 번역 솔루션은 의료 문서가 다양한 언어로 현지 및 국제 법규의 요구 사항을 충족하는지 확인합니다.',
    text17: '글로벌 시장 진출',
    text18: '번역을 통해 전 세계 시장에 쉽게 진출하여 다양한 지역의 의료 전문가와 환자에게 정보를 제공할 수 있습니다.',
    text19: '의학적 정확성',
    text20: '우리의 의학 전문 번역 팀은 번역 내용이 정확하고 오류가 없으며 가능한 오해를 피할 수 있도록 보장합니다.',
    text21: '환자 안전',
    text22: '정확한 약품설명서와 라벨번역은 환자가 약품을 정확하게 사용하고 환자의 안전성을 높이는데 도움이 된다.',
    text23: '임상 협력',
    text24: '임상 시험 파일에 대한 번역 지원을 통해 국제 간의 협력 및 정보 공유를 촉진합니다.',
    text25: '이점',
    text26: '의학 전문팀',
    text27: '우리 팀은 의학 번역 전문가로 구성되어 번역 내용이 정확하고 일치하며 의학 표준에 부합하는지 확인합니다.',
    text28: '용어 관리',
    text29: '의학 용어 데이터베이스를 관리하여 서로 다른 언어 버전에서 일관된 의학 용어를 사용하도록 합니다.',
    text30: '우리는 의약 법규의 요구를 이해하고 번역 내용이 각국의 법규 표준에 부합되도록 확보한다.',
    text31: '비밀성',
    text32: '민감한 의료 정보를 처리할 때 저희는 비밀 유지 협의를 엄격히 준수하여 정보 안전을 확보합니다.',
    text33: '현지화 지원',
    text34: '지역별 문화와 법규의 차이를 고려하여 번역 내용이 다양한 배경에 적응하도록 한다.',
    text35: '우리는 의약 분야의 고객에게 우수한 번역 지원을 제공하여 당신의 글로벌 전파 수요를 만족시키기 위해 노력합니다.',
    text36:
      '당사의 의약업계 번역 솔루션을 선택함으로써 귀하는 전문적이고 법규에 부합되며 보안성이 높은 서비스를 받게 될 것이며 귀하의 의약제품이 국제시장에서 성공적으로 전파되는 데 도움이 될 것입니다.'
  },
  de: {
    text1: 'Übersetzungslösungen für die Pharmaindustrie',
    text2:
      'Unsere Youyi Information Pharma Industry Translation Solution ist bestrebt, Kunden in der pharmazeutischen Industrie qualitativ hochwertige Übersetzungs- und Lokalisierungsdienste anzubieten.Unabhängig davon, ob es sich um medizinische Unterlagen, Arzneimittelanweisungen, klinische Studienakten oder Medizinproduktedokumente handelt, können wir professionelle Übersetzungsunterstützung bieten, um die Genauigkeit, Konsistenz und Einhaltung der gesetzlichen Anforderungen der Informationen zu gewährleisten.',
    text3: 'Beratung',
    text4: 'Charakteristik',
    text5: 'Medizinische Fachkenntnisse',
    text6:
      'Wir verfügen über professionelle Übersetzer im medizinischen Bereich, die medizinische Terminologie und Fachwissen verstehen, um die Genauigkeit der Übersetzungsinhalte sicherzustellen',
    text7: 'Übersetzung von Drogenanweisungen',
    text8:
      'Bereitstellung von Übersetzungsdiensten für Arzneimittelanweisungen, Etiketten, Verpackungen usw., um Konsistenz und Genauigkeit von Arzneimittelinformationen in verschiedenen Sprachen zu gewährleisten',
    text9: 'Übersetzung von Dokumenten zur klinischen Prüfung',
    text10:
      'Übersetzungsunterstützung für klinische Studiendokumente, Fallberichte usw. zur Gewährleistung einer konsistenten internationalen Kommunikation von Forschungsergebnissen',
    text11: 'Übersetzung von Dokumenten für medizinische Geräte',
    text12:
      'Übersetzungsdienste für Handbücher, technische Spezifikationen und Betriebsrichtlinien für medizinische Geräte zur Unterstützung globaler Anwender',
    text13: 'Einhaltung der Vorschriften',
    text14:
      'Wir stellen sicher, dass die übersetzten Inhalte den lokalen und internationalen regulatorischen Anforderungen entsprechen, um Verstöße zu vermeiden',
    text15: 'Wert',
    text16:
      'Unsere Übersetzungslösung stellt sicher, dass medizinische Dokumente den lokalen und internationalen regulatorischen Anforderungen in verschiedenen Sprachen entsprechen.',
    text17: 'Markteintritt weltweit',
    text18:
      'Durch Übersetzungen können Sie ganz einfach in den globalen Markt eintreten und Informationen für medizinische Fachkräfte und Patienten in verschiedenen Regionen bereitstellen.',
    text19: 'Medizinische Genauigkeit',
    text20: 'Unser medizinisch professionelles Übersetzungsteam sorgt für die Richtigkeit der Übersetzungsinhalte und vermeidet mögliche Missverständnisse.',
    text21: 'Patientensicherheit',
    text22:
      'Die genaue Übersetzung von Arzneimittelanweisungen und -etiketten trägt dazu bei, dass Patienten Arzneimittel korrekt anwenden und die Patientensicherheit verbessern.',
    text23: 'Klinische Zusammenarbeit',
    text24:
      'Förderung der internationalen Zusammenarbeit und des Informationsaustauschs durch Übersetzungsunterstützung von Dokumenten aus klinischen Studien.',
    text25: 'Vorteil',
    text26: 'Team medizinischer Fachkräfte',
    text27:
      'Unser Team besteht aus medizinischen Übersetzungsexperten, um genaue, konsistente und konforme Übersetzungsinhalte mit medizinischen Standards sicherzustellen.',
    text28: 'Terminverwaltung',
    text29:
      'Verwalten Sie eine medizinische Terminologiedatenbank, um eine konsistente Verwendung medizinischer Terminologie in verschiedenen Sprachversionen sicherzustellen.',
    text30:
      'Wir verstehen die Anforderungen medizinischer Vorschriften und stellen sicher, dass die übersetzten Inhalte den regulatorischen Standards verschiedener Länder entsprechen.',
    text31: 'Vertraulichkeit',
    text32:
      'Beim Umgang mit sensiblen medizinischen Informationen halten wir uns strikt an Vertraulichkeitsvereinbarungen, um die Informationssicherheit zu gewährleisten.',
    text33: 'Unterstützung bei der Lokalisierung',
    text34:
      'Berücksichtigen Sie die kulturellen und regulatorischen Unterschiede in den verschiedenen Regionen, um sicherzustellen, dass sich die übersetzten Inhalte an verschiedene Hintergründe anpassen.',
    text35:
      'Wir sind bestrebt, pharmazeutischen Kunden exzellente Übersetzungsunterstützung zu bieten, um Ihre globalen Kommunikationsanforderungen zu erfüllen.',
    text36:
      'Wenn Sie sich für unsere Übersetzungslösung für die pharmazeutische Industrie entscheiden, erhalten Sie professionelle, regulatorische und vertrauliche Dienstleistungen, die Ihre pharmazeutischen Produkte erfolgreich auf dem internationalen Markt verbreiten.'
  },
  fr: {
    text1: "Solutions de traduction pour l'industrie pharmaceutique",
    text2:
      "Nos solutions de traduction pour l'industrie pharmaceutique visent à fournir des services de traduction et de localisation de haute qualité à nos clients du secteur pharmaceutique.Qu'il s'agisse d'un dossier médical, d'une notice pharmaceutique, d'un document d'essai clinique ou d'un document relatif à un dispositif médical, nous pouvons vous fournir un soutien professionnel en traduction qui garantit l'exactitude, la cohérence et la conformité de l'information aux exigences réglementaires.",
    text3: 'Services consultatifs',
    text4: 'Caractéristiques',
    text5: 'Expertise médicale',
    text6:
      'Nous avons des traducteurs spécialisés dans le domaine médical qui connaissent la terminologie médicale et la connaissance du domaine, garantissant que le contenu traduit est exact',
    text7: 'Traduction des instructions pharmaceutiques',
    text8:
      "Fournir des services de traduction de la notice, de l'étiquetage, de l'emballage, etc., pour assurer la cohérence et l'exactitude des informations sur les médicaments dans les différentes langues",
    text9: "Traduction de documents d'essais cliniques",
    text10:
      "Fournir un soutien à la traduction de documents d'essais cliniques, de rapports de cas, etc., en veillant à ce que les résultats de la recherche soient communiqués de manière cohérente à l'échelle internationale",
    text11: 'Traduction de documents pour dispositifs médicaux',
    text12:
      "Fournir des services de traduction pour les manuels d'utilisation, les spécifications techniques et les guides d'utilisation des dispositifs médicaux, entre autres, pour soutenir les utilisateurs du monde entier",
    text13: 'Conformité réglementaire',
    text14:
      'Nous nous assurons que le contenu traduit est conforme aux exigences réglementaires locales et internationales et évitons les problèmes de violation',
    text15: 'Valeur',
    text16:
      'Nos solutions de traduction garantissent que les documents médicaux répondent aux exigences des réglementations locales et internationales dans différentes langues.',
    text17: 'Entrée sur le marché mondial',
    text18:
      'Grâce à la traduction, vous avez un accès facile aux marchés mondiaux pour informer les professionnels de la santé et les patients dans différentes régions.',
    text19: 'Précision médicale',
    text20: "Notre équipe de traducteurs professionnels de la santé veille à ce que le contenu traduit soit précis et évite d'éventuels malentendus.",
    text21: 'Sécurité des patients',
    text22:
      "La traduction précise de la notice et de l'étiquette du médicament peut aider à assurer l'utilisation correcte du médicament par les patients et à améliorer la sécurité des patients.",
    text23: 'Coopération clinique',
    text24:
      "Faciliter la collaboration et le partage d'informations entre les pays en fournissant un soutien à la traduction des documents d'essais cliniques.",
    text25: 'Avantages',
    text26: 'Équipe de professionnels médicaux',
    text27:
      'Notre équipe est composée d’experts en traduction médicale qui veillent à ce que le contenu traduit soit précis, cohérent et conforme aux normes médicales.',
    text28: 'Gestion terminologique',
    text29:
      "Gérer une base de données terminologiques médicales pour assurer l'utilisation de termes médicaux cohérents dans les différentes versions linguistiques.",
    text30:
      'Nous comprenons les exigences de la réglementation pharmaceutique et nous nous assurons que le contenu traduit est conforme aux normes réglementaires de chaque pays.',
    text31: 'Confidentialité',
    text32:
      'Lorsque nous traitons des informations médicales sensibles, nous veillons à la sécurité des informations en respectant strictement les accords de confidentialité.',
    text33: 'Soutien à la localisation',
    text34:
      'Tenir compte des différences de culture et de réglementation dans les différentes régions et veiller à ce que le contenu traduit soit adapté à tous les contextes.',
    text35:
      'Nous nous engageons à fournir un excellent support de traduction à nos clients du secteur pharmaceutique pour répondre à vos besoins de communication dans le monde entier.',
    text36:
      "En choisissant nos solutions de traduction pour l'industrie pharmaceutique, vous bénéficiez d'un service professionnel, conforme à la réglementation et hautement confidentiel qui contribue à la diffusion réussie de vos médicaments sur les marchés internationaux."
  },
  ru: {
    text1: 'Переводческие решения для фармацевтической промышленности',
    text2:
      'Наши решения по переводу для фармацевтической промышленности направлены на предоставление высококачественных услуг по переводу и локализации для клиентов в области медицины.Независимо от того, является ли это медицинской записью, инструкцией по лекарствам, документацией клинических испытаний или документацией медицинского оборудования, мы можем предоставить вам профессиональную переводческую поддержку для обеспечения точности, последовательности и соответствия информации нормативным требованиям.',
    text3: 'Консультативные услуги',
    text4: 'Особенности',
    text5: 'Медицинская экспертиза',
    text6: 'У нас есть профессиональные переводчики в области медицины, которые знают медицинские термины и области знаний, чтобы обеспечить точный перевод',
    text7: 'Перевод лекарственных препаратов.',
    text8:
      'Предоставление услуг по переводу инструкций, этикеток, упаковки и т.д. для обеспечения согласованности и точности информации о лекарствах на разных языках',
    text9: 'Перевод документов клинических испытаний',
    text10:
      'Обеспечить перевод документов клинических испытаний, отчетов о случаях и т. Д. Для обеспечения согласованности результатов исследований в международном масштабе',
    text11: 'Перевод медицинской документации',
    text12:
      'Предоставление услуг по переводу руководств по использованию медицинского оборудования, технических спецификаций и инструкций по эксплуатации для поддержки пользователей во всем мире',
    text13: 'Соблюдение нормативных требований',
    text14: 'Мы гарантируем, что переводы соответствуют местным и международным нормативным требованиям и избегают нарушений',
    text15: 'Стоимость',
    text16: 'Наши переводческие решения обеспечивают соответствие медицинских документов местным и международным нормам на различных языках.',
    text17: 'Выход на мировые рынки',
    text18: 'Благодаря переводу вы можете легко выйти на мировой рынок, предоставляя информацию медицинским специалистам и пациентам в разных регионах.',
    text19: 'Медицинская точность',
    text20: 'Наша команда медицинских переводчиков обеспечивает точный перевод и избегает возможных недоразумений.',
    text21: 'Безопасность пациентов',
    text22: 'Точный перевод инструкций и этикеток помогает обеспечить правильное использование лекарств пациентами и повысить безопасность пациентов.',
    text23: 'Клиническое сотрудничество',
    text24: 'Содействие международному сотрудничеству и обмену информацией путем обеспечения перевода документов клинических испытаний.',
    text25: 'Преимущество',
    text26: 'Команда медицинских специалистов',
    text27:
      'Наша команда состоит из специалистов по медицинскому переводу, чтобы убедиться, что содержание перевода является точным, последовательным и соответствует медицинским стандартам.',
    text28: 'Терминология',
    text29:
      'Управление базами данных медицинской терминологии для обеспечения последовательного использования медицинской терминологии в различных языковых версиях.',
    text30: 'Мы понимаем требования фармацевтического законодательства и гарантируем, что содержание перевода соответствует национальным стандартам.',
    text31: 'Конфиденциальность',
    text32:
      'При работе с конфиденциальной медицинской информацией мы строго соблюдаем соглашение о конфиденциальности и обеспечиваем информационную безопасность.',
    text33: 'Поддержка локализации',
    text34: 'Принимая во внимание культурные и нормативные различия в разных регионах, убедитесь, что содержание перевода адаптировано к различным контекстам.',
    text35:
      'Мы стремимся обеспечить отличную переводческую поддержку для клиентов в области медицины, чтобы удовлетворить ваши глобальные коммуникационные потребности.',
    text36:
      'Выбирая наши переводческие решения для фармацевтической промышленности, вы получите профессиональные, регулируемые и конфиденциальные услуги, которые помогут вам успешно распространять свою фармацевтическую продукцию на международном рынке.'
  },
  th: {
    text1: 'โซลูชั่นการแปลสำหรับอุตสาหกรรมยา',
    text2:
      'โซลูชั่นการแปลอุตสาหกรรมยาของเราสำหรับข้อมูลการแปลที่ดีเยี่ยมมุ่งมั่นที่จะให้บริการการแปลและการแปลคุณภาพสูงแก่ลูกค้าในสาขาเภสัชกรรมไม่ว่าจะเป็นเวชระเบียน คำแนะนำเกี่ยวกับยา เอกสารการทดลองทางคลินิก หรือเอกสารอุปกรณ์ทางการแพทย์ เราสามารถให้การสนับสนุนด้านการแปลอย่างมืออาชีพเพื่อรับรองความถูกต้อง ความสอดคล้อง และการปฏิบัติตามข้อกำหนดของข้อมูล',
    text3: 'บริการให้คำปรึกษา',
    text4: 'คุณสมบัติ',
    text5: 'ความเชี่ยวชาญทางการแพทย์',
    text6: 'เรามีนักแปลมืออาชีพในสาขาการแพทย์ที่มีความรู้ความเข้าใจเกี่ยวกับคำศัพท์ทางการแพทย์และสาขาวิชาเพื่อให้แน่ใจว่าการแปลมีความถูกต้องและถูกต้อง',
    text7: 'การแปลคำแนะนำเกี่ยวกับยา',
    text8: 'ให้บริการแปลเอกสาร เช่น คู่มือยา ฉลาก บรรจุภัณฑ์ ฯลฯ เพื่อให้มั่นใจว่าข้อมูลยามีความสอดคล้องและแม่นยำในภาษาต่างๆ',
    text9: 'การแปลเอกสารการทดลองทางคลินิก',
    text10: 'สนับสนุนการแปลเอกสารการทดลองทางคลินิกรายงานกรณีและอื่น ๆ เพื่อให้แน่ใจว่าผลการวิจัยได้รับการสื่อสารอย่างสม่ำเสมอในระดับสากล',
    text11: 'การแปลเอกสารเครื่องมือแพทย์',
    text12: 'บริการแปลเอกสารคู่มือการใช้งานเครื่องมือแพทย์ ข้อกำหนดทางเทคนิค และแนวทางการดำเนินงาน ฯลฯ เพื่อรองรับผู้ใช้ทั่วโลก',
    text13: 'การปฏิบัติตามกฎระเบียบ',
    text14: 'เรามั่นใจว่าเนื้อหาที่แปลนั้นสอดคล้องกับข้อกำหนดของกฎระเบียบในประเทศและต่างประเทศและหลีกเลี่ยงปัญหาการละเมิด',
    text15: 'ความคุ้มค่า',
    text16: 'โซลูชันการแปลของเรามั่นใจว่าเอกสารทางการแพทย์สอดคล้องกับข้อกำหนดของกฎระเบียบท้องถิ่นและระหว่างประเทศในภาษาต่างๆ',
    text17: 'การเข้าถึงตลาดโลก',
    text18: 'ด้วยการแปลภาษาคุณสามารถเข้าถึงตลาดโลกได้อย่างง่ายดายเพื่อให้ข้อมูลแก่บุคลากรทางการแพทย์และผู้ป่วยในภูมิภาคต่างๆ',
    text19: 'ความถูกต้องทางการแพทย์',
    text20: 'ทีมนักแปลผู้เชี่ยวชาญทางการแพทย์ของเราตรวจสอบให้แน่ใจว่าเนื้อหาที่แปลนั้นถูกต้องและหลีกเลี่ยงความเข้าใจผิดที่อาจเกิดขึ้น',
    text21: 'ความปลอดภัยของผู้ป่วย',
    text22: 'คำแนะนำและการแปลฉลากยาที่ถูกต้องจะช่วยให้มั่นใจได้ว่าผู้ป่วยใช้ยาอย่างถูกต้องและเพิ่มความปลอดภัยของผู้ป่วย',
    text23: 'ความร่วมมือทางคลินิก',
    text24: 'ส่งเสริมความร่วมมือและการแบ่งปันข้อมูลระหว่างประเทศโดยการให้การสนับสนุนการแปลเอกสารการทดลองทางคลินิก',
    text25: 'ข้อดี',
    text26: 'ทีมแพทย์ผู้เชี่ยวชาญ',
    text27: 'ทีมงานของเราประกอบด้วยผู้เชี่ยวชาญด้านการแปลทางการแพทย์เพื่อให้มั่นใจว่าเนื้อหาการแปลมีความถูกต้องสอดคล้องและเป็นไปตามมาตรฐานทางการแพทย์',
    text28: 'การจัดการคำศัพท์',
    text29: 'จัดการฐานข้อมูลคำศัพท์ทางการแพทย์เพื่อให้แน่ใจว่ามีการใช้คำศัพท์ทางการแพทย์ที่สอดคล้องกันในเวอร์ชันภาษาต่างๆ',
    text30: 'เราเข้าใจข้อกำหนดของกฎระเบียบด้านเภสัชกรรมเพื่อให้มั่นใจว่าเนื้อหาที่แปลนั้นสอดคล้องกับมาตรฐานข้อบังคับของประเทศต่างๆ',
    text31: 'การรักษาความลับ',
    text32: 'เมื่อจัดการกับข้อมูลทางการแพทย์ที่ละเอียดอ่อนเราปฏิบัติตามข้อตกลงการรักษาความลับอย่างเคร่งครัดเพื่อรับรองความปลอดภัยของข้อมูล',
    text33: 'การสนับสนุนการแปล',
    text34: 'พิจารณาความแตกต่างของวัฒนธรรมและกฎระเบียบในภูมิภาคต่าง ๆ เพื่อให้แน่ใจว่าเนื้อหาที่แปลสามารถปรับให้เข้ากับบริบทที่หลากหลาย',
    text35: 'เรามุ่งมั่นที่จะให้การสนับสนุนการแปลที่ยอดเยี่ยมแก่ลูกค้าในสาขาเภสัชกรรมเพื่อตอบสนองความต้องการด้านการสื่อสารทั่วโลกของคุณ',
    text36:
      'ด้วยการเลือกโซลูชันการแปลอุตสาหกรรมยาของเราคุณจะได้รับบริการที่เป็นมืออาชีพปฏิบัติตามกฎระเบียบและเป็นความลับซึ่งจะช่วยให้ผลิตภัณฑ์ยาของคุณประสบความสำเร็จในการเผยแพร่ในตลาดต่างประเทศ'
  },
  es: {
    text1: 'Soluciones de traducción para la industria farmacéutica',
    text2:
      'Nuestra solución de traducción de la industria farmacéutica de información Youyi se compromete a proporcionar servicios de traducción y localización de alta calidad a los clientes en el campo farmacéutico.Ya sea registros médicos, instrucciones de medicamentos, documentos de ensayos clínicos o documentos de equipos médicos, podemos proporcionarle apoyo profesional de traducción para garantizar la precisión, consistencia y cumplimiento de la información con los requisitos regulatorios.',
    text3: 'Servicios de consultoría',
    text4: 'Características',
    text5: 'Experiencia médica',
    text6:
      'Tenemos traductores profesionales en el campo de la medicina, entendemos la terminología médica y el conocimiento del campo, y garantizamos que el contenido de la traducción sea preciso.',
    text7: 'Traducción de instrucciones del medicamento',
    text8:
      'Proporcionar servicios de traducción de instrucciones, etiquetas y envases de medicamentos para garantizar la coherencia y precisión de la información de medicamentos en diferentes idiomas.',
    text9: 'Traducción de documentos de ensayos clínicos',
    text10:
      'Proporcionar apoyo de traducción para documentos de ensayos clínicos, informes de casos, etc., para garantizar que los resultados de la investigación se transmitan de manera coherente a nivel internacional.',
    text11: 'Traducción de documentos de equipos médicos',
    text12:
      'Servicios de traducción para manuales de uso, especificaciones técnicas y guías de operación de dispositivos médicos para apoyar a usuarios de todo el mundo',
    text13: 'Cumplimiento de la normativa',
    text14: 'Garantizamos que el contenido de la traducción cumpla con los requisitos de las regulaciones locales e internacionales y evite violaciones.',
    text15: 'Valor',
    text16:
      'Nuestra solución de traducción garantiza que los documentos médicos cumplan con los requisitos de las regulaciones locales e internacionales en varios idiomas.',
    text17: 'Entrada en el mercado mundial',
    text18:
      'A través de la traducción, puede acceder fácilmente al mercado global, proporcionando información a profesionales médicos y pacientes en diferentes regiones.',
    text19: 'Precisión médica',
    text20: 'Nuestro equipo de traducción profesional médica se asegura de que el contenido de la traducción sea preciso y evite posibles malentendidos.',
    text21: 'Seguridad del paciente',
    text22:
      'La traducción precisa de las instrucciones y etiquetas del medicamento ayuda a garantizar el uso correcto del medicamento por parte del paciente y mejorar la seguridad del paciente.',
    text23: 'Cooperación clínica',
    text24: 'Promover la cooperación internacional y el intercambio de información mediante el apoyo a la traducción de documentos de ensayos clínicos.',
    text25: 'Ventaja',
    text26: 'Equipo profesional médico',
    text27:
      'Nuestro equipo está compuesto por expertos en traducción médica para garantizar que el contenido de la traducción sea preciso, consistente y cumpla con los estándares médicos.',
    text28: 'Gestión de la terminología',
    text29: 'Gestionar una base de datos de términos médicos para garantizar el uso de términos médicos consistentes en diferentes versiones lingüísticas.',
    text30:
      'Entendemos los requisitos de las regulaciones médicas y nos aseguramos de que el contenido de la traducción cumpla con las regulaciones y estándares nacionales.',
    text31: 'Confidencialidad',
    text32:
      'Al procesar información médica sensible, cumplimos estrictamente con los protocolos de confidencialidad para garantizar la seguridad de la información.',
    text33: 'Soporte localizado',
    text34:
      'Considerar las diferencias culturales y regulatorias entre las diferentes regiones para garantizar que el contenido de la traducción se adapte a los diversos contextos.',
    text35:
      'Nos comprometemos a proporcionar un excelente apoyo de traducción a nuestros clientes en el campo farmacéutico para satisfacer sus necesidades de comunicación global.',
    text36:
      'Al elegir nuestras soluciones de traducción de la industria farmacéutica, obtendrá servicios profesionales, regulatorios y confidenciales que ayudarán a la difusión exitosa de sus productos farmacéuticos en el mercado internacional.'
  },
  pt: {
    text1: 'Soluções de tradução para a indústria farmacêutica',
    text2:
      'Nossa solução de tradução da indústria farmacêutica Youyi Information está comprometida em fornecer serviços de tradução e localização de alta qualidade aos clientes da indústria farmacêutica.Quer se trate de registros médicos, instruções de medicamentos, arquivos de ensaios clínicos ou documentos de dispositivos médicos, podemos fornecer suporte profissional de tradução para garantir a precisão, consistência e conformidade com os requisitos regulamentares das informações.',
    text3: 'serviço de consultoria',
    text4: 'característica',
    text5: 'Conhecimento profissional médico',
    text6:
      'Temos tradutores profissionais na área médica que entendem terminologia médica e conhecimento de domínio para garantir a precisão do conteúdo da tradução',
    text7: 'Tradução de instruções sobre medicamentos',
    text8:
      'Fornecer serviços de tradução de instruções sobre medicamentos, rótulos, embalagens, etc., para garantir consistência e precisão das informações sobre medicamentos em diferentes idiomas',
    text9: 'Tradução de documentos de ensaios clínicos',
    text10:
      'Fornecer suporte de tradução para documentos de ensaios clínicos, relatórios de casos, etc., para garantir uma comunicação consistente dos resultados da pesquisa internacionalmente',
    text11: 'Tradução de documentos de equipamento médico',
    text12:
      'Fornecer serviços de tradução para manuais de equipamentos médicos, especificações técnicas e diretrizes operacionais para apoiar usuários globais',
    text13: 'Cumprimento regulamentar',
    text14: 'Garantimos que o conteúdo traduzido esteja em conformidade com os requisitos regulamentares locais e internacionais para evitar violações',
    text15: 'valor',
    text16: 'A nossa solução de tradução garante que os documentos médicos cumpram os requisitos regulamentares locais e internacionais em vários idiomas.',
    text17: 'Entrada no mercado mundial',
    text18:
      'Através da tradução, você pode facilmente entrar no mercado global e fornecer informações para profissionais médicos e pacientes em diferentes regiões.',
    text19: 'Precisão médica',
    text20: 'Nossa equipe de tradução profissional médica garante a precisão do conteúdo da tradução e evita possíveis mal-entendidos.',
    text21: 'Segurança do doente',
    text22:
      'A tradução precisa de instruções e rótulos de medicamentos ajuda a garantir que os pacientes usem medicamentos corretamente e melhorem a segurança do paciente.',
    text23: 'Colaboração clínica',
    text24: 'Promover a cooperação internacional e o compartilhamento de informações fornecendo suporte de tradução para documentos de ensaios clínicos.',
    text25: 'vantagem',
    text26: 'Equipe de profissionais médicos',
    text27:
      'Nossa equipe é composta por especialistas em tradução médica para garantir conteúdo de tradução preciso, consistente e compatível com os padrões médicos.',
    text28: 'Gestão de prazos',
    text29: 'Gerencie um banco de dados de terminologia médica para garantir o uso consistente da terminologia médica em diferentes versões linguísticas.',
    text30: 'Entendemos os requisitos das regulamentações médicas e garantimos que o conteúdo traduzido atenda às normas regulatórias de vários países.',
    text31: 'Confidencialidade',
    text32: 'Ao lidar com informações médicas sensíveis, aderimos estritamente a acordos de confidencialidade para garantir a segurança da informação.',
    text33: 'Suporte à localização',
    text34: 'Considere as diferenças culturais e regulatórias em diferentes regiões para garantir que o conteúdo traduzido se adapte a várias origens.',
    text35: 'Estamos empenhados em fornecer excelente suporte de tradução aos clientes farmacêuticos para atender às suas necessidades globais de comunicação.',
    text36:
      'Ao escolher nossa solução de tradução para a indústria farmacêutica, você receberá serviços profissionais, em conformidade com a regulamentação e altamente confidenciais, que ajudarão seus produtos farmacêuticos a se espalhar com sucesso no mercado internacional.'
  }
}
