import { createI18n } from 'vue-i18n'
import messages from './lang'
import { i18nLangBySystem } from '@/utils/util'
import { i18nMap } from '@/utils/const'

localStorage.setItem('languageKey', localStorage.getItem('languageKey') || 'zh-cn')

const localLang = i18nMap[localStorage.getItem('languageKey')] || 'zh-cn'

const i18n = createI18n({
  messages,
  legacy: false,
  fallbackLocale: 'zh-cn',
  silentFallbackWarn: true, //控制台上不打印警告
  globalInjection: true, // 全局模式，可以直接使用 $t
  locale: localLang
})

export default i18n
