export default {
  zh: {
    text1: '优译',
    text2: '始于翻译，不止于翻译',
    text3: '领先的语言服务与语言技术综合解决方案提供商',
    text4: '公司简介',
    text5: '全球先进水平的语言服务与语言技术综合解决方案提供商',
    text6:
      '成都优译信息技术股份有限公司是全球先进水平的语言服务与语言技术综合解决方案提供商，基于17年的语言数据积累，融合人工智能、内容管理、云计算等新兴技术，创造性地提供通达细腻的语言科技服务、打造贯穿供需的语言服务平台，致力于成为全球语言产业链运营商。',
    text7:
      '公司先后获得赛创、德商、界石、新译、技转、百度等机构的多轮投资，先后入选腾讯AI加速器、百度AI加速器、普华永道中国成都新经济创业企业加速营。获得授权和受理授权发明专利36项、28项软著，已通过国家高新技术企业认证，四川省“专精特新”企业、成都市种子企业、成都市知识产权优势单位及高新区瞪羚企业。',
    text8:
      '公司陆续承担成都市技术创新研发项目、成都市重大科技创新计划项目、成都市人工智能专项项目、成都市数据服务平台建设项目、四川省科技计划研发项目；是四川省重点支持高精度多模态机翻项目单位。',
    text9: '服务企业数',
    text10: '使用用户数',
    text11: '省科技厅成果',
    text12: '商标授权',
    text13: '软件著作权',
    text14: '发明专利',
    text15: '企业文化',
    text16: '通过科技，让客户获得性价比高的人工翻译服务',
    text17: '企业使命',
    text18: '通过科技，让客户获得性价比高的翻译服务',
    text19: '企业愿景',
    text20: '成为全球领先的语言服务与语言技术综合解决方案提供商',
    text21: '价值观',
    text22: '团结、进取、有担当、专业、严谨、效率高',
    text23: '企业荣誉',
    text24: '我们立足翻译行业，深耕其中',
    text25: '联系我们',
    text26: '如有任何问题，请联系我们',
    text27: '翻译业务咨询',
    text28: '翻译服务范围、服务价格咨询、免费报价，欢迎联系我们',
    text29: '业务咨询',
    text30: '技术产品咨询',
    text31: '如果您在使用产品的过程中有任何疑问、建议或意见，欢迎向我们提出',
    text32: '产品咨询'
  },
  en: {
    text1: 'Excellent translation',
    text2: 'Starting from translation, not just translation',
    text3: 'Leading provider of comprehensive solutions for language services and language technology',
    text4: 'Company Introduction',
    text5: 'A globally advanced language service and language technology comprehensive solution provider',
    text6:
      'Chengdu Youyi Information Technology Co., Ltd. is a globally advanced provider of language services and comprehensive language technology solutions. Based on 17 years of language data accumulation, integrating emerging technologies such as artificial intelligence, content management, and cloud computing, we creatively provide accessible and delicate language technology services, create a language service platform that runs through supply and demand, and strive to become a global language industry chain operator.',
    text7:
      'The company has received multiple rounds of investment from institutions such as Saichuang, Deshang, Jieshi, Xinyi, Jizhuan, Baidu, and has been selected as Tencent AI Accelerator, Baidu AI Accelerator, and PwC Chengdu New Economy Entrepreneurship Enterprise Accelerator Camp in China.36 invention patents and 28 software works have been authorized and accepted, and have passed the national high-tech enterprise certification. It is a "specialized, refined, and innovative" enterprise in Sichuan Province, a seed enterprise in Chengdu, an intellectual property advantage unit in Chengdu, and a gazelle enterprise in the high-tech zone.',
    text8:
      'The company has successively undertaken technology innovation research and development projects in Chengdu, major technology innovation plan projects in Chengdu, artificial intelligence special projects in Chengdu, data service platform construction projects in Chengdu, and science and technology plan research and development projects in Sichuan Province; It is a key support unit for high-precision multimodal machine flipping projects in Sichuan Province.',
    text9: 'Number of service enterprises',
    text10: 'Number of users used',
    text11: 'Achievements of the Provincial Department of Science and Technology',
    text12: 'Trademark Authorization',
    text13: 'Software copyright',
    text14: 'Invention Patent',
    text15: 'corporate culture',
    text16: 'Through technology, customers can obtain cost-effective manual translation services',
    text17: 'Corporate Mission',
    text18: 'Through technology, customers can obtain cost-effective translation services',
    text19: 'Corporate Vision',
    text20: 'Becoming a leading global provider of comprehensive language services and language technology solutions',
    text21: 'Values',
    text22: 'Unity, enterprising, responsible, professional, rigorous, and efficient',
    text23: 'Corporate Honors',
    text24: 'We are based in the translation industry and deeply rooted in it',
    text25: 'contact us',
    text26: 'If you have any questions, please contact us',
    text27: 'Translation Business Consulting',
    text28: 'Translation service scope, service price consultation, free quotation, welcome to contact us',
    text29: 'Business consulting',
    text30: 'Technical product consultation',
    text31: 'If you have any questions, suggestions or opinions during the use of the product, please feel free to contact us',
    text32: 'Product Consultation'
  },
  ja: {
    text1: 'ユーティリティ',
    text2: '翻訳から始まり、翻訳だけではありません',
    text3: '先進的な言語サービスと言語テクノロジーの統合ソリューションプロバイダ',
    text4: '会社概要',
    text5: '世界的に先進的な言語サービスと言語テクノロジーの統合ソリューションプロバイダ',
    text6:
      '成都優訳情報技術株式会社は世界先進レベルの言語サービスと言語技術総合ソリューションプロバイダであり、17年間の言語データ蓄積に基づいて、人工知能、コンテンツ管理、クラウドコンピューティングなどの新興技術を融合し、繊細な言語科学技術サービスを創造的に提供し、需給を貫く言語サービスプラットフォームを構築し、世界言語産業チェーンの運営者になることに力を入れている。',
    text7:
      '会社は前後して賽創、徳商、界石、新訳、技転、百度などの機関の多ラウンド投資を獲得し、前後してテンセントAI加速器、百度AI加速器、普華永道中国成都新経済創業企業加速営に入選した。授権と受理授権された発明特許36件、28件のソフト著作権を獲得し、すでに国家ハイテク企業の認証を通過し、四川省の「専精特新」企業、成都市種子企業、成都市知的財産権優勢企業及びハイテク区のカモシカ企業。',
    text8:
      '会社は続々と成都市技術革新研究開発プロジェクト、成都市重大科学技術革新計画プロジェクト、成都市人工知能特別プロジェクト、成都市データサービスプラットフォーム建設プロジェクト、四川省科学技術計画研究開発プロジェクトを担当している、四川省が重点的に支持する高精度多モード機翻プロジェクト単位である。',
    text9: 'サービス企業数',
    text10: '使用ユーザー数',
    text11: '省科学技術庁の成果',
    text12: '商標ライセンス',
    text13: 'ソフトウェア著作権',
    text14: '発明特許',
    text15: '企業文化',
    text16: 'テクノロジーを利用して、お客様に高い人件費で翻訳サービスを提供',
    text17: '企業の使命',
    text18: 'テクノロジーを利用して、お客様に高コストの翻訳サービスを提供',
    text19: '企業のビジョン',
    text20: '世界をリードする言語サービスと言語テクノロジーの総合ソリューションプロバイダになる',
    text21: '価値観',
    text22: '団結、進取、責任、専門、厳格、効率が高い',
    text23: '企業の名誉',
    text24: '私たちは翻訳業界に立脚し、その中を深く耕しています',
    text25: '連絡先',
    text26: '何か問題があれば、私たちに連絡してください。',
    text27: '翻訳業務コンサルティング',
    text28: '翻訳サービスの範囲、サービス価格のコンサルティング、無料見積もり、ご連絡ください',
    text29: 'ビジネスコンサルティング',
    text30: '技術製品コンサルティング',
    text31: 'もしあなたが製品を使用する過程で何か疑問、提案、意見があれば、私たちに提案してください。',
    text32: '製品コンサルティング'
  },
  ko: {
    text1: '우역',
    text2: '번역에서 시작하여 번역에 그치지 않다',
    text3: '선도적인 언어 서비스 및 언어 기술 통합 솔루션 공급업체',
    text4: '회사 소개',
    text5: '세계 최고 수준의 언어 서비스 및 언어 기술 통합 솔루션 공급업체',
    text6:
      '청두유역정보기술주식유한공사는 전 세계 선진수준의 언어서비스와 언어기술 종합솔루션 제공업체로서 17년간의 언어데이터 축적을 바탕으로 인공지능, 내용관리, 클라우드 컴퓨팅 등 신흥기술을 융합하여 섬세한 언어과학기술서비스를 창조적으로 제공하고 공급과 수요를 관통하는 언어서비스플랫폼을 구축하여 전 세계 언어산업사슬운영업체로 발돋움하는데 주력한다.',
    text7:
      '회사는 선후로 싸이창, 덕상, 계석, 신역, 기전, 바이두 등 기구의 여러 차례의 투자를 받았고, 선후로 텐센트 AI 가속기, 바이두 AI 가속기, 보화영도 중국 청두 신경제 창업 기업 가속 경영에 선정되었다.수권과 수리 수권 발명 특허 36개, 28개의 연저를 획득하였으며, 이미 국가 하이테크 기업 인증을 통과하였으며, 쓰촨성"전문정특신"기업, 청두시 종자기업, 청두시 지식재산권 우세단위 및 고신구 가젤기업.',
    text8:
      '회사는 청두시 기술혁신 연구개발 프로젝트, 청두시 중대과학기술혁신계획 프로젝트, 청두시 인공지능 전문 프로젝트, 청두시 데이터 서비스 플랫폼 건설 프로젝트, 쓰촨성 과학기술계획 연구개발 프로젝트를 속속 담당하고 있다;쓰촨성에서 고정밀 다중모드기 전복을 중점적으로 지원하는 프로젝트 단위이다.',
    text9: '서비스 업체 수',
    text10: '사용자 수 사용',
    text11: '성 과학기술청 성과',
    text12: '상표 허가',
    text13: '소프트웨어 저작권',
    text14: '발명 특허',
    text15: '기업 문화',
    text16: '기술 을 통해 고객 에게 성비 가 높은 인공 번역 서비스 를 제공 하다',
    text17: '기업의 사명',
    text18: '기술 을 통해 고객 에게 성비 가 높은 번역 서비스 를 제공 하다',
    text19: '엔터프라이즈 비전',
    text20: '세계 최고의 언어 서비스 및 언어 기술 통합 솔루션 공급업체',
    text21: '가치관',
    text22: '단결, 진취, 책임감, 전문, 엄밀, 효율',
    text23: '기업 명예',
    text24: '우리는 번역업에 입각하여 그 속에 깊이 파고들었다',
    text25: '연락처',
    text26: '질문 있으시면 연락 주세요.',
    text27: '번역 업무 컨설팅',
    text28: '번역 서비스 범위, 서비스 가격 문의, 무료 견적, 연락처 환영',
    text29: '비즈니스 컨설팅',
    text30: '기술 제품 컨설팅',
    text31: '제품을 사용하는 동안 질문이나 제안 또는 의견이 있는 경우',
    text32: '제품 컨설팅'
  },
  de: {
    text1: 'Hervorragende Übersetzung',
    text2: 'Von der Übersetzung ausgehend, nicht nur von der Übersetzung',
    text3: 'Führender Anbieter von umfassenden Lösungen für Sprachdienstleistungen und Sprachtechnologie',
    text4: 'Einführung des Unternehmens',
    text5: 'Ein weltweit führender Anbieter von umfassenden Sprachdienstleistungen und Sprachtechnologie',
    text6:
      'Chengdu Youyi Information Technology Co., Ltd. ist ein weltweit fortschrittlicher Anbieter von Sprachdienstleistungen und umfassenden Sprachtechnologielösungen. Basierend auf 17-jähriger Sprachdatenakkumulation, Integration neuer Technologien wie künstlicher Intelligenz, Content Management und Cloud Computing, bieten wir auf kreative Weise zugängliche und empfindliche Sprachtechnologiedienste, schaffen eine Sprachdienstleistungsplattform, die durch Angebot und Nachfrage verläuft und streben danach, ein globaler Sprachindustrie-Kettenbetreiber zu werden.',
    text7:
      'Das Unternehmen hat mehrere Investitionsrunden von Institutionen wie Saichuang, Deshang, Jieshi, Xinyi, Jizhuan, Baidu erhalten und wurde als Tencent AI Accelerator, Baidu AI Accelerator und PwC Chengdu New Economy Entrepreneurship Enterprise Accelerator Camp in China ausgewählt.36-Erfindungspatente und 28-Softwarearbeiten wurden genehmigt und akzeptiert und haben die nationale High-Tech-Unternehmenszertifizierung bestanden.Es ist ein "spezialisiertes, raffiniertes und innovatives" Unternehmen in der Provinz Sichuan, ein Saatgutunternehmen in Chengdu, eine Vorteilseinheit für geistiges Eigentum in Chengdu und ein Gazellenunternehmen in der High-Tech-Zone.',
    text8:
      'Das Unternehmen hat sukzessive Technologieinnovationsforschungs- und Entwicklungsprojekte in Chengdu, große Technologieinnovationsplanprojekte in Chengdu, Spezialprojekte für künstliche Intelligenz in Chengdu, Bauprojekte für Datendienstplattformen in Chengdu und Forschungs- und Entwicklungsprojekte in der Provinz Sichuan durchgeführt; Es ist eine Schlüsselunterstützungseinheit für hochpräzise multimodale Maschinendrehprojekte in der Provinz Sichuan.',
    text9: 'Zahl der Dienstleistungsunternehmen',
    text10: 'Anzahl der verwendeten Benutzer',
    text11: 'Leistungen der Provinzabteilung für Wissenschaft und Technologie',
    text12: 'Markenzulassung',
    text13: 'Urheberrecht der Software',
    text14: 'Erfindungspatent',
    text15: 'Unternehmenskultur',
    text16: 'Durch Technologie können Kunden kostengünstige manuelle Übersetzungsdienste erhalten',
    text17: 'Unternehmensmission',
    text18: 'Durch Technologie können Kunden kostengünstige Übersetzungsdienstleistungen in Anspruch nehmen',
    text19: 'Corporate Vision',
    text20: 'Zu einem weltweit führenden Anbieter von umfassenden Sprachdienstleistungen und Sprachtechnologielösungen werden',
    text21: 'Werte',
    text22: 'Einheit, unternehmerisch, verantwortungsbewusst, professionell, streng und effizient',
    text23: 'Corporate Honors',
    text24: 'Wir sind in der Übersetzungsbranche angesiedelt und tief in ihr verwurzelt',
    text25: 'Kontaktieren Sie uns',
    text26: 'Wenn Sie Fragen haben, kontaktieren Sie uns bitte',
    text27: 'Übersetzungsbüroberatung',
    text28: 'Übersetzungsdienstumfang, Service Preisberatung, kostenloses Angebot, willkommen, mit uns in Verbindung zu treten',
    text29: 'Unternehmensberatung',
    text30: 'Technische Produktberatung',
    text31: 'Wenn Sie Fragen, Anregungen oder Meinungen während der Verwendung des Produkts haben, wenden Sie sich bitte an uns',
    text32: 'Produktberatung'
  },
  fr: {
    text1: 'Traduction optimale',
    text2: 'Commencer par la traduction, plus que la traduction',
    text3: 'Fournisseur leader de solutions intégrées de services linguistiques et de technologies linguistiques',
    text4: 'Profil de la société',
    text5: 'Fournisseur mondial de services linguistiques de niveau avancé et de solutions intégrées de technologie linguistique',
    text6:
      "Chengdu Translation Information Technology Co., Ltd. Est un fournisseur mondial de services linguistiques de haut niveau et de solutions complètes de technologie linguistique, basé sur 17 ans d'accumulation de données linguistiques, intégrant des technologies émergentes telles que l'intelligence artificielle, la gestion de contenu, le cloud computing, etc., afin de fournir de manière créative des services technologiques linguistiques accessibles et raffinés, de créer une plate - forme de services linguistiques couvrant l'offre et la demande, et de devenir un opérateur mondial de la chaîne de l'industrie linguistique.",
    text7:
      "La société a successivement reçu plusieurs séries d'investissements de la part d'institutions telles que saitcong, germano - business, Landmark, xintranslation, Technology conversion, baidu, et a été sélectionnée successivement pour l'accélérateur Tencent ai, l'accélérateur Baidu ai, le camp d'accélération de la nouvelle économie de PWC à Chengdu, en Chine.L'autorisation et l'acceptation des brevets d'invention autorisés 36, 28 doux, ont passé la certification d'entreprise nationale de haute technologie, l'entreprise de « spécialité nouvelle» de la province du Sichuan, l'entreprise de semences de Chengdu, l'unité d'avantage de propriété intellectuelle de Chengdu et l'entreprise de Gazelle de la zone de haute technologie.",
    text8:
      "La société a successivement entrepris le projet de recherche et développement d'innovation technologique de la ville de Chengdu, le grand projet de programme d'innovation scientifique et technologique de la ville de Chengdu, le projet spécial d'intelligence artificielle de la ville de Chengdu, le projet de construction de la plate - forme de services de données de la ville de Chengdu, le projet de recherche et développement du programme scientifique et technologique de la province du Sichuan C'est la province du Sichuan qui se concentre sur le soutien de l'unité de projet de renversement de machine multimodale de haute précision.",
    text9: "Nombre d'entreprises de services",
    text10: "Nombre d'utilisateurs utilisés",
    text11: 'Résultats du Département provincial des sciences et de la technologie',
    text12: 'Autorisation de marque',
    text13: "Droit d'auteur logiciel",
    text14: "Brevets d'invention",
    text15: "Culture d'entreprise",
    text16: "Permettre aux clients d'obtenir des services de traduction humaine rentables grâce à la technologie",
    text17: "Mission de l'entreprise",
    text18: "Permettre aux clients d'obtenir des services de traduction rentables grâce à la technologie",
    text19: "Vision d'entreprise",
    text20: 'Devenir le premier fournisseur mondial de solutions intégrées de services linguistiques et de technologies linguistiques',
    text21: 'Valeurs',
    text22: 'Solidarité, entreprenant, entreprenant, professionnel, rigoureux et efficace',
    text23: "Honneur d'entreprise",
    text24: 'Nous sommes basés dans le secteur de la traduction, en profondeur',
    text25: 'Contactez - nous',
    text26: 'Contactez - nous pour toute question',
    text27: 'Conseil aux entreprises de traduction',
    text28: 'Gamme de services de traduction, demande de prix de service, devis gratuit, bienvenue à nous contacter',
    text29: 'Consultation opérationnelle',
    text30: 'Conseil en produits techniques',
    text31: "Si vous avez des questions, des suggestions ou des commentaires lors de l'utilisation du produit, n'hésitez pas à nous les faire parvenir",
    text32: 'Consultation sur les produits'
  },
  ru: {
    text1: 'Ваш перевод',
    text2: 'Начинается с перевода, а не только с перевода.',
    text3: 'Ведущий поставщик языковых услуг и комплексных языковых решений',
    text4: 'Краткая информация о компании',
    text5: 'Поставщики языковых услуг и комплексных решений для языковых технологий мирового уровня',
    text6:
      'Компания Chengdu Uijiang Information Technology Co., Ltd. является поставщиком интегрированных решений для языковых услуг и языковых технологий мирового уровня, основанных на 17 - летнем накоплении языковых данных, интеграции новых технологий, таких как искусственный интеллект, управление контентом и облачные вычисления, творческом предоставлении хорошо доступных и сложных языковых и технологических услуг, создании платформы языковых услуг, охватывающей спрос и предложение, и стремится стать оператором глобальной языковой индустрии.',
    text7:
      'Компания получила несколько раундов инвестиций от Sai Chuang, Deutsche Business, пограничных столбов, нового перевода, передачи технологий, Baidu и других учреждений, была выбрана Tencent AI акселератор, Baidu AI акселератор, PwC Chengdu New Economic Startup Enterprise Express Camp.Получил разрешение и принял 36 патентов на патенты на изобретения, 28 мягких работ, прошел государственную сертификацию высокотехнологичных предприятий, провинции Сычуань « специализировался на новых предприятиях», семенных предприятиях в Чэнду, превосходных подразделениях интеллектуальной собственности в Чэнду и предприятиях газели в высокотехнологичной зоне.',
    text8:
      'Компания последовательно осуществляла научно - исследовательские и опытно - конструкторские проекты в области технологических инноваций в городе Чэнду, крупные научно - технические инновационные проекты в городе Чэнду, специальные проекты в области искусственного интеллекта в городе Чэнду, проекты по строительству платформы обслуживания данных в городе Чэнду, научно - исследовательские и опытно - технические проекты в провинции Сычуань; В провинции Сычуань основное внимание уделяется поддержке высокоточных многомодальных машин.',
    text9: 'Число обслуживающих предприятий',
    text10: 'Количество пользователей',
    text11: 'Результаты провинциального научно - технического управления',
    text12: 'Лицензия на товарный знак',
    text13: 'Авторские права на программное обеспечение',
    text14: 'Патент на изобретение',
    text15: 'Корпоративная культура',
    text16: 'Предоставление клиентам рентабельных услуг по письменному переводу с помощью технологий',
    text17: 'Корпоративная миссия',
    text18: 'Предоставление клиентам экономически эффективных услуг перевода с помощью технологий',
    text19: 'Корпоративное видение',
    text20: 'Станьте ведущим мировым поставщиком языковых услуг и комплексных решений для языковых технологий',
    text21: 'Ценности',
    text22: 'Солидарность, предприимчивость, ответственность, профессионализм, строгость, высокая эффективность',
    text23: 'Корпоративная честь',
    text24: 'Мы опираемся на переводческую индустрию,',
    text25: 'Свяжитесь с нами',
    text26: 'Если у вас есть вопросы, пожалуйста, свяжитесь с нами',
    text27: 'Консультации по переводу',
    text28: 'Сфера услуг перевода, консультации по ценам на услуги, бесплатные предложения, пожалуйста, свяжитесь с нами',
    text29: 'Оперативное консультирование',
    text30: 'Консультации по техническим продуктам',
    text31: 'Если у вас есть какие - либо вопросы, предложения или замечания по использованию продукта, пожалуйста, обратитесь к нам.',
    text32: 'Консультации по продуктам'
  },
  th: {
    text1: 'การแปลที่ยอดเยี่ยม',
    text2: 'เริ่มต้นด้วยการแปลมากกว่าแปล',
    text3: 'ผู้ให้บริการด้านภาษาและเทคโนโลยีด้านภาษาที่ครอบคลุมชั้นนำ',
    text4: 'ข้อมูล บริษัท',
    text5: 'บริการภาษาขั้นสูงระดับโลกและผู้ให้บริการโซลูชั่นครบวงจรด้านเทคโนโลยีภาษา',
    text6:
      'เฉิงตู You Translation เทคโนโลยีสารสนเทศ จำกัด เป็นระดับโลกขั้นสูงของบริการภาษาและผู้ให้บริการโซลูชั่นที่ครอบคลุมเทคโนโลยีภาษาบนพื้นฐานของการสะสมข้อมูลภาษา 17 ปีรวมเทคโนโลยีที่เกิดขึ้นใหม่เช่นปัญญาประดิษฐ์การจัดการเนื้อหาคลาวด์คอมพิวติ้งสร้างสรรค์ให้บริการภาษาและเทคโนโลยีที่ละเอียดอ่อนของ Tongda สร้างแพลตฟอร์มบริการภาษาผ่านอุปสงค์และอุปทานและมุ่งมั่นที่จะเป็นผู้ประกอบการห่วงโซ่อุตสาหกรรมภาษาทั่วโลก',
    text7:
      'บริษัท ได้รับการลงทุนอย่างต่อเนื่องหลายรอบจาก Saichuang, Deutsch, Bordership, New Translation, Technological Translation, Baidu และสถาบันอื่น ๆ และได้รับเลือกให้เป็น Tencent AI Accelerator, Baidu AI Accelerator และ PricewaterhouseCoopers เฉิงตูประเทศจีนเฉิงตู New Economic Startup Enterprise Accelerator Campได้รับอนุญาตและยอมรับสิทธิบัตรการประดิษฐ์ที่ได้รับอนุญาต 36 รายการและ 28 ผลงานที่ได้รับการรับรองจากองค์กรที่มีเทคโนโลยีสูงแห่งชาติองค์กร" Specialty New " ในมณฑลเสฉวน บริษัท เมล็ดพันธุ์เฉิงตูหน่วยความได้เปรียบด้านทรัพย์สินทางปัญญาของเฉิงตูและองค์กรละมั่งโซนไฮเทค',
    text8:
      'บริษัท ยังคงดำเนินการโครงการวิจัยและพัฒนานวัตกรรมเทคโนโลยีเฉิงตูโครงการนวัตกรรมวิทยาศาสตร์และเทคโนโลยีที่สำคัญของเฉิงตูโครงการพิเศษเฉิงตูปัญญาประดิษฐ์เฉิงตูโครงการก่อสร้างแพลตฟอร์มบริการข้อมูลเฉิงตูโครงการวิทยาศาสตร์และเทคโนโลยีเสฉวนโครงการวิจัยและพัฒนา เป็นมณฑลเสฉวนที่มุ่งเน้นการสนับสนุนหน่วยโครงการพลิกคว่ำหลายโหมดที่มีความแม่นยำสูง',
    text9: 'จำนวนธุรกิจที่ให้บริการ',
    text10: 'ใช้จำนวนผู้ใช้',
    text11: 'ผลการปฏิบัติงานของสำนักงานวิทยาศาสตร์และเทคโนโลยีจังหวัด',
    text12: 'การอนุญาตเครื่องหมายการค้า',
    text13: 'ลิขสิทธิ์ซอฟต์แวร์',
    text14: 'สิทธิบัตรการประดิษฐ์',
    text15: 'วัฒนธรรมองค์กร',
    text16: 'ด้วยเทคโนโลยีเพื่อให้ลูกค้าได้รับบริการแปลภาษาด้วยตนเองที่คุ้มค่า',
    text17: 'พันธกิจองค์กร',
    text18: 'ด้วยเทคโนโลยีเพื่อให้ลูกค้าได้รับบริการแปลที่คุ้มค่า',
    text19: 'วิสัยทัศน์องค์กร',
    text20: 'เป็นผู้นำระดับโลกในการให้บริการด้านภาษาและเทคโนโลยีด้านภาษาที่ครบวงจร',
    text21: 'ค่านิยม',
    text22: 'ความสามัคคีกล้าได้กล้าเสียมีความรับผิดชอบเป็นมืออาชีพเข้มงวดและมีประสิทธิภาพสูง',
    text23: 'เกียรติขององค์กร',
    text24: 'เราตั้งอยู่ในอุตสาหกรรมการแปลและปลูกฝังอย่างลึกซึ้ง',
    text25: 'ติดต่อเรา',
    text26: 'กรุณาติดต่อเราสำหรับคำถามใด ๆ',
    text27: 'ที่ปรึกษาด้านการแปลภาษา',
    text28: 'บริการแปลเอกสาร สอบถามราคาค่าบริการ ใบเสนอราคาฟรี ยินดีต้อนรับติดต่อเรา',
    text29: 'ที่ปรึกษาทางธุรกิจ',
    text30: 'การให้คำปรึกษาเกี่ยวกับผลิตภัณฑ์ทางเทคนิค',
    text31: 'หากคุณมีคำถามข้อเสนอแนะหรือความคิดเห็นใด ๆ ในระหว่างการใช้ผลิตภัณฑ์ของคุณยินดีที่จะส่งให้เรา',
    text32: 'ปรึกษาผลิตภัณฑ์'
  },
  es: {
    text1: 'Excelente traducción',
    text2: 'Comienza con la traducción, no solo con la traducción',
    text3: 'Proveedor líder de soluciones integrales de servicios lingüísticos y tecnología lingüística',
    text4: 'Perfil de la empresa',
    text5: 'Proveedor mundial de soluciones integradas de servicios lingüísticos y tecnología lingüística de nivel avanzado',
    text6:
      'Chengdu Youyi Information Technology co., Ltd. es un proveedor global avanzado de soluciones integrales de servicios lingüísticos y tecnología lingüística. basado en 17 años de acumulación de datos lingüísticos, integra tecnologías emergentes como inteligencia artificial, gestión de contenidos y computación en la nube, proporciona creativamente servicios lingüísticos delicados y delicados, crea una plataforma de servicios lingüísticos que atraviesa la oferta y la demanda, y Se compromete a convertirse en un operador de la cadena mundial de la industria lingüística.',
    text7:
      'La compañía ha ganado varias rondas de inversión de saichuang, negocios alemanes, jieshi, xinyi, transferencia de tecnología, Baidu y otras instituciones, y ha sido seleccionada sucesivamente como Tencent AI accelerator, Baidu ai Accelerator y PricewaterhouseCoopers China Chengdu New Economic Venture Enterprises Acceleration camp.Ha obtenido 36 patentes de invención autorizadas y aceptadas, 28 obras blandas, ha pasado la certificación nacional de empresas de alta tecnología, empresas "especializadas y nuevas" en la provincia de sichuan, empresas de semillas en chengdu, unidades superiores de propiedad intelectual en Chengdu y empresas de gacela en zonas de alta tecnología.',
    text8:
      'La compañía ha llevado a cabo sucesivamente proyectos de I + D de innovación tecnológica en chengdu, grandes proyectos de planes de innovación científica y tecnológica en chengdu, proyectos especiales de inteligencia artificial en chengdu, proyectos de construcción de plataformas de servicios de datos en Chengdu y proyectos de I + D de planes de Ciencia y tecnología en sichuan; Es una unidad clave de la provincia de Sichuan para apoyar proyectos de vuelco de máquinas multimodal de alta precisión.',
    text9: 'Número de empresas de servicios',
    text10: 'Número de usuarios utilizados',
    text11: 'Resultados del Departamento Provincial de Ciencia y tecnología',
    text12: 'Autorización de marca',
    text13: 'Derechos de autor de software',
    text14: 'Patente de invención',
    text15: 'Cultura corporativa',
    text16: 'A través de la tecnología, los clientes pueden obtener servicios de traducción manual rentables.',
    text17: 'Misión corporativa',
    text18: 'A través de la tecnología, los clientes pueden obtener servicios de traducción rentables.',
    text19: 'Visión corporativa',
    text20: 'Convertirse en el proveedor líder mundial de soluciones integrales de servicios lingüísticos y tecnología lingüística',
    text21: 'Valores',
    text22: 'Solidaridad, emprendedora, responsable, profesional, rigurosa y eficiente',
    text23: 'Honor corporativo',
    text24: 'Nos basamos en la industria de la traducción y la Cultivamos profundamente.',
    text25: 'Contáctanos',
    text26: 'Si tiene alguna pregunta, Póngase en contacto con nosotros.',
    text27: 'Consultoría empresarial de traducción',
    text28: 'Alcance del Servicio de traducción, consulta de precios del servicio, cotización gratuita, bienvenido a contactarnos',
    text29: 'Consultoría empresarial',
    text30: 'Consultoría de productos técnicos',
    text31: 'Si tiene alguna duda, sugerencia o opinión sobre el uso del producto, por favor háganoslo saber.',
    text32: 'Consulta de productos'
  },
  pt: {
    text1: 'Excelente tradução',
    text2: 'A partir da tradução, não apenas da tradução',
    text3: 'Fornecedor líder de soluções abrangentes para serviços linguísticos e tecnologia linguística',
    text4: 'Introdução da Empresa',
    text5: 'Um provedor de soluções abrangentes de serviços linguísticos e tecnologia linguística globalmente avançado',
    text6:
      'Chengdu Youyi Information Technology Co., Ltd. é um fornecedor globalmente avançado de serviços linguísticos e soluções abrangentes de tecnologia linguística. Com base em 17 anos de acumulação de dados linguísticos, integrando tecnologias emergentes, como inteligência artificial, gerenciamento de conteúdo e computação em nuvem, criamos serviços de tecnologia linguística acessíveis e delicados, criamos uma plataforma de serviços linguísticos que funcione através da oferta e demanda e nos esforçamos para se tornar um operador global da cadeia da indústria linguística.',
    text7:
      'A empresa recebeu várias rodadas de investimento de instituições como Saichuang, Deshang, Jieshi, Xinyi, Jizhuan, Baidu, e foi selecionada como Tencent AI Accelerator, Baidu AI Accelerator e PwC Chengdu New Economy Entrepreneurship Enterprise Accelerator Camp na China.36 patentes de invenção e 28 trabalhos de software foram autorizados e aceitos, e passaram a certificação nacional de empresa de alta tecnologia.É uma empresa "especializada, refinada e inovadora" na província de Sichuan, uma empresa de sementes em Chengdu, uma unidade de vantagem de propriedade intelectual em Chengdu, e uma empresa gazela na zona de alta tecnologia.',
    text8:
      'A empresa realizou sucessivamente projetos de pesquisa e desenvolvimento de inovação tecnológica em Chengdu, grandes projetos de plano de inovação tecnológica em Chengdu, projetos especiais de inteligência artificial em Chengdu, projetos de construção de plataformas de serviços de dados em Chengdu e projetos de pesquisa e desenvolvimento de plano de ciência e tecnologia na província de Sichuan; É uma unidade de suporte chave para projetos multimodais de alta precisão que viram máquinas na província de Sichuan.',
    text9: 'Número de empresas de serviços',
    text10: 'Número de utilizadores utilizados',
    text11: 'Realizações do Departamento Provincial de Ciência e Tecnologia',
    text12: 'Autorização de Marca Registrada',
    text13: 'Direitos de autor do software',
    text14: 'Patente de Invenção',
    text15: 'cultura empresarial',
    text16: 'Através da tecnologia, os clientes podem obter serviços de tradução manual rentáveis',
    text17: 'Missão Corporativa',
    text18: 'Através da tecnologia, os clientes podem obter serviços de tradução econômicos',
    text19: 'Visão Corporativa',
    text20: 'Tornar-se um fornecedor global líder de serviços linguísticos abrangentes e soluções de tecnologia linguística',
    text21: 'Valores',
    text22: 'Unidade, empreendedora, responsável, profissional, rigorosa e eficiente',
    text23: 'Honras Corporativas',
    text24: 'Estamos baseados na indústria da tradução e profundamente enraizados nela',
    text25: 'entre em contato conosco',
    text26: 'Se você tiver alguma dúvida, entre em contato conosco',
    text27: 'Tradução Consultoria Empresarial',
    text28: 'Âmbito do serviço de tradução, consulta do preço do serviço, cotação livre, boa vinda para entrar em contato conosco',
    text29: 'Consultoria empresarial',
    text30: 'Consulta técnica dos produtos',
    text31: 'Se você tiver alguma dúvida, sugestão ou opinião durante o uso do produto, não hesite em contactar-nos',
    text32: 'Consulta do produto'
  }
}
