export default {
  zh: {
    text1: '说明',
    text2: '1、目前支持中英互译在线下单，其它语种翻译可以咨询客服下单：028-83316191。',
    text3:
      '2、证件证书翻译按份数报价，中译英：标准化证件证书，如：身份证、户口本、营业执照、四六级证书等 100元/份；非标准化证件证书，如成绩单、工作证明、财产证明、组织机构代码证等（不支持涉及到重要安全性的证件或材料） 100元/份起。翻译件均可免费加盖公司翻译章（具备证件翻译必备资质），全国包邮（不含港澳台地区）。',
    text4:
      '3、专业翻译特色服务：提供公司名称、产品名称、广告语、古诗文、菜单等创意型内容翻译，按件计费，298元/件。提供2-3种精心翻译备选方案，及译文依据供参考。如有任何问题，请咨询客服电话：028-83316191。',
    text5:
      '2、字数统计与计价：字数以 Microsoft Word 文档统计为标准，按照“字符数（不计空格）”计价；原文是中文，按照中文字数计算价格；原文是英文，按照英文单词数统计价格。',
    text6: '翻译服务价格',
    text7: '翻译方向',
    text8: '翻译用途',
    text9: '标准级(元/千字)',
    text10: '专业级(元/千字)',
    text11: '资深级(元/千字)',
    text12: '母语级(元/千字)',
    text13: '翻译质量服务等级',
    text14: '级别',
    text15: '翻译流程',
    text16: '中译英',
    text17: '英译中',
    text18: '自己阅读使用',
    text19: '普通用户阅读使用',
    text20: '重点客户阅读使用',
    text21: '医药注册申报',
    text22: '产品UI、营销、专利、发表类',
    text23: '标准级',
    text24: '人工翻译+中级审校+排版+质检',
    text25: '价格实惠，在人工翻译的基础上，采用中级审校，保证信息的完整性和准确性。',
    text26: '专业级',
    text27: '人工翻译+高级审校+排版+质检',
    text28: '性价比高，在标准级的基础上，采用高级审校，保障遣词造句的严谨性和专业性。',
    text29: '资深级',
    text30: '人工翻译+高级审校+语言润色+排版+质检',
    text31: '质量更好，在专业级的基础上，增加语言润色环节，提高译文的流畅性和可读性。',
    text32: '母语级',
    text33: '人工翻译+母语审校+润色+排版+质检',
    text34: '更加地道，在资深级的基础上，采用母语审校和润色，保障译文的地道性和目标市场的文化适应性。'
  },
  en: {
    text1: 'explain',
    text2:
      '1. At present, we support online order placement for Chinese English translation. For other language translations, please consult customer service at 028-83316191.',
    text3:
      '2. Translation of ID certificates is quoted based on the number of copies. Chinese to English: Standardized ID certificates, such as ID cards, household registration books, business licenses, Level 4 and 6 certificates, etc., are priced at 100 yuan per copy; Non standardized documents and certificates, such as transcripts, work certificates, property certificates, organizational code certificates, etc. (documents or materials related to important safety are not supported), starting at 100 yuan per copy.All translated documents can be stamped with the company translation seal for free (with the necessary qualifications for document translation), and are free of charge nationwide (excluding Hong Kong, Macao, and Taiwan regions).',
    text4:
      '3. Professional translation services: Provide creative content translation for company names, product names, advertising slogans, ancient poems, menus, etc., with a per piece fee of 298 yuan.Provide 2-3 carefully translated alternative options and translation basis for reference.If you have any questions, please consult customer service at 028-83316191.',
    text5:
      '2. Word count and pricing: Word count is based on Microsoft Word document statistics and priced according to "character count (excluding spaces)"; The original text is in Chinese, and the price is calculated based on the number of Chinese characters; The original text is in English, and prices are calculated based on the number of English words.',
    text6: 'Translation service price',
    text7: 'Translation direction',
    text8: 'Translation purpose',
    text9: 'Standard level (yuan/thousand words)',
    text10: 'Professional level (yuan/thousand words)',
    text11: 'Senior level (yuan/thousand words)',
    text12: 'Mother tongue level (yuan/thousand words)',
    text13: 'Translation quality service level',
    text14: 'level',
    text15: 'Translation process',
    text16: 'Chinese to English translation',
    text17: 'Translation from English to Chinese',
    text18: 'Read and use on your own',
    text19: 'Regular user reading and usage',
    text20: 'Key customer reading and usage',
    text21: 'Medical registration application',
    text22: 'Product UI, marketing, patents, publications',
    text23: 'Standard level',
    text24: 'Manual translation+intermediate proofreading+typesetting+quality inspection',
    text25:
      'The price is low.Intermediate proofreading: Read the translation thoroughly, check the fluency, logicality, professionalism, etc. of the sentences',
    text26: 'Professional level',
    text27: 'Manual translation+advanced proofreading+typesetting+quality inspection',
    text28:
      'High cost performance ratio.Advanced proofreading: Check the original text word by word and sentence by sentence, covering all aspects of translation quality.',
    text29: 'Senior level',
    text30: 'Manual translation+advanced proofreading+language polishing+typesetting+quality inspection',
    text31:
      'Better quality.Language polishing service: Improve the accuracy and fluency of language expression, enhance the naturalness and authenticity of language, and make translation more vivid and charming.',
    text32: 'Mother tongue level',
    text33: 'Manual translation+native language proofreading+polishing+typesetting+quality inspection',
    text34:
      'Pure manual translation, with more refined language; Mother tongue level proofreading further ensures the authenticity of the translation and better fits the language environment and cultural background of the target language.'
  },
  ja: {
    text1: '説明',
    text2: '1、現在、中英対訳オンライン注文をサポートしており、その他の言語翻訳はカスタマーサービス注文に問い合わせることができる：028-83316191。',
    text3:
      '2、証明書証明書翻訳は部数に応じてオファーし、中訳英：標準化証明書証明書、例えば：身分証明書、戸籍簿、営業許可証、四六級証明書など100元/部、成績表、作業証明書、財産証明書、組織機構コード証明書などの非標準化証明書（重要な安全性に関わる証明書や資料はサポートされていない）は100元/部からです。翻訳物はすべて無料で会社の翻訳印（証明書の翻訳に必要な資格を備えている）を押すことができて、全国は（香港・マカオ・台湾地区を含まない）郵送することができます。',
    text4:
      '3、専門翻訳特色サービス：会社名、製品名、広告語、古詩文、メニューなどの創意的なコンテンツ翻訳を提供し、件に応じて料金を計算し、298元/件。2～3種類の入念な翻訳オプションを提供し、参考のために翻訳文の根拠を提供します。何か問題があれば、カスタマーサービスの電話番号：028-83316191にお問い合わせください。',
    text5:
      '2、文字数統計と価格計算：文字数はMicrosoft Word文書統計を基準とし、「文字数（スペースを問わない）」に基づいて価格を計算する、原文は中国語で、中国語の文字数に基づいて価格を計算する。原文は英語で、英語の単語数に基づいて価格を統計します。',
    text6: '翻訳サービス価格',
    text7: '翻訳方向',
    text8: '翻訳の用途',
    text9: '標準レベル（元/千字）',
    text10: '専門レベル（元/千字）',
    text11: 'シニアレベル（元/千字）',
    text12: '母国語レベル（元/千字）',
    text13: '翻訳品質サービスレベル',
    text14: 'レベル＃レベル＃',
    text15: '翻訳プロセス',
    text16: '中国語と英語',
    text17: '英訳中',
    text18: '自分で読んで使う',
    text19: '一般ユーザー読取り使用',
    text20: '重点顧客の読書使用',
    text21: '医薬品登録届出',
    text22: '製品UI、マーケティング、特許、発表類',
    text23: '標準レベル',
    text24: '手動翻訳+中級審査+組版+品質検査',
    text25: '価格が安い。中級審査：訳文を通読し、語句の順序、論理性、専門性などを検査する',
    text26: '専門レベル',
    text27: '手動翻訳+高度な審査+組版+品質検査',
    text28: '性価比が高い。高級審査：原文と照らし合わせて一字一句審査し、翻訳文の品質の各方面をカバーする。',
    text29: 'シニアレベル',
    text30: '手動翻訳+高度な審査+言語潤色+レイアウト+品質検査',
    text31: '品質がもっといい。言語潤色サービス：言語表現の正確性と流暢性を高め、言語の自然性と本場性を高め、翻訳をより生き生きと魅力的にする。',
    text32: '母国語レベル',
    text33: '手動翻訳+母国語レビュー+潤色+組版+品質検査',
    text34: '純粋な人工翻訳で、用語はもっと洗練されている。母語レベルの審査、翻訳文の本場性をさらに保証し、目標言語の言語環境と文化背景により適合させる。'
  },
  ko: {
    text1: '설명',
    text2: '1. 현재 중영 상호 번역 온라인 주문을 지원하며, 기타 언어 번역은 고객센터 주문에 문의할 수 있다: 028-83316191.',
    text3:
      '2. 증명서 번역은 부수에 따라 가격을 제시하고, 중역영: 표준화 증명서, 예를 들면: 신분증, 호적등본, 영업허가증, 46급 증서 등 100원/부;성적표, 업무증명, 재산증명, 조직기구코드증 등 비표준화된 증명서 증서 (중요한 안전성과 관련된 증명서 또는 자료는 지원되지 않음) 100원/부.번역 서류는 모두 무료로 회사 번역 도장 (증명서 번역 필수 자질 구비), 전국 소포 (홍콩, 마카오, 대만 지역 제외) 을 찍을 수 있다.',
    text4:
      '3. 전문번역특색서비스: 회사명, 제품명, 광고어, 고시문, 메뉴 등 창의형내용번역을 제공하고 건당 비용계산, 298원/건.2-3가지 정성스러운 번역 대안과 번역문 근거를 참고로 제공한다.문의 사항은 028-83316191로 문의하십시오.',
    text5:
      '2. 단어 수 통계 및 가격 계산: 단어 수는 Microsoft Word 문서 통계를 기준으로 하며,"문자 수(공백 제외)"에 따라 가격을 계산합니다.원문은 중국어이며, 중국어 글자 수에 따라 가격을 계산한다;원문은 영어로 영어 단어 수에 따라 가격을 집계한다.',
    text6: '번역 서비스 가격',
    text7: '번역 방향',
    text8: '번역 용도',
    text9: '표준 레벨 (메타/천자)',
    text10: '전문가 수준 (메타/킬로바이트)',
    text11: '베테랑 레벨 (메타 / 킬로바이트)',
    text12: '원어민 레벨 (원/천자)',
    text13: '번역 품질 서비스 등급',
    text14: '수준',
    text15: '번역 프로세스',
    text16: '중역영',
    text17: '영역 중',
    text18: '스스로 읽기 사용',
    text19: '일반 사용자 읽기 사용',
    text20: '주요 고객 읽기 및 사용',
    text21: '의약 등록 신고',
    text22: '제품 UI, 마케팅, 특허, 발표 클래스',
    text23: '표준 수준',
    text24: '인공 번역 + 중급 심사 + 조판 + 품질 검사',
    text25: '가격이 낮다.중급 심사: 번역문을 통독하고 문장의 매끄러움, 논리성, 전문성 등을 검사한다',
    text26: '전문가급',
    text27: '인공 번역 + 고급 심사 + 조판 + 품질 검사',
    text28: '성비가 높다.고급 심사: 원문과 대조하여 한 글자 한 글자 심사하여 번역문의 품질의 모든 면을 커버한다.',
    text29: '베테랑급',
    text30: '인공 번역 + 고급 심사 + 언어 윤색 + 조판 + 품질 검사',
    text31: '품질이 더 좋습니다.언어윤색서비스: 언어표현의 정확성과 류창도를 높이고 언어의 자연성과 땅굴성을 강화하여 번역을 더욱 생동하고 매력적으로 한다.',
    text32: '모국어급',
    text33: '인공번역 + 모국어 교정 + 윤색 + 조판 + 품질 검사',
    text34: '순수 인공 번역으로 용어를 더욱 중시한다;모국어급의 심사교정은 번역문의 땅굴성을 한층 더 보장하고 목표언어의 언어환경과 문화배경에 더욱 부합된다.'
  },
  de: {
    text1: 'erklären',
    text2:
      '1. Derzeit unterstützen wir Online-Auftragserteilung für Chinesisch Englisch Übersetzung. Für andere Sprachübersetzungen wenden Sie sich bitte an den Kundendienst unter 028-83316191.',
    text3:
      '2. Übersetzung von ID-Zertifikaten wird basierend auf der Anzahl der Kopien zitiert. Chinesisch zu Englisch: Standardisierte ID-Zertifikate, wie Personalausweise, Haushaltsregistrierungsbücher, Geschäftslizenzen, Level 4 und 6 Zertifikate, etc., werden zu 100 Yuan pro Kopie berechnet; Nicht standardisierte Dokumente und Zertifikate, wie Transkripte, Arbeitszeugnisse, Eigentumszertifikate, Organisationskodizenzertifikate usw. (Dokumente oder Materialien, die wichtige Sicherheit betreffen, werden nicht unterstützt), ab 100 Yuan pro Kopie.Alle übersetzten Dokumente können kostenlos mit dem Firmenübersetzungssiegel abgestempelt werden (mit den erforderlichen Qualifikationen für die Dokumentenübersetzung) und sind landesweit kostenlos (ausgenommen Regionen Hongkong, Macao und Taiwan).',
    text4:
      '3. Professionelle Übersetzungsdienstleistungen: Bieten Sie kreative Inhaltsübersetzung für Firmennamen, Produktnamen, Werbeslogans, alte Gedichte, Menüs usw. mit einer Stückgebühr von 298 Yuan.Stellen Sie 2-3 sorgfältig übersetzte alternative Optionen und Übersetzungsbasis als Referenz zur Verfügung.Bei Fragen wenden Sie sich bitte an den Kundenservice unter 028-83316191.',
    text5:
      '2. Wortanzahl und Preisgestaltung: Wortanzahl basiert auf Microsoft Word Dokumentstatistiken und berechnet nach "Zeichenanzahl (ohne Leerzeichen)"; Der Originaltext ist auf Chinesisch, und der Preis wird basierend auf der Anzahl der chinesischen Zeichen berechnet; Der Originaltext ist in englischer Sprache und die Preise werden anhand der Anzahl der englischen Wörter berechnet.',
    text6: 'Preis für Übersetzungsdienste',
    text7: 'Übersetzungsrichtung',
    text8: 'Übersetzungszweck',
    text9: 'Standard Level (Yuan/tausend Wörter)',
    text10: 'Berufsstufe (Yuan/tausend Wörter)',
    text11: 'Senior Level (Yuan/tausend Wörter)',
    text12: 'Muttersprache Niveau (Yuan/tausend Wörter)',
    text13: 'Niveau der Übersetzungsqualität',
    text14: 'Ebene',
    text15: 'Übersetzungsprozess',
    text16: 'Chinesisch nach Englisch Übersetzung',
    text17: 'Übersetzung aus dem Englischen ins Chinesische',
    text18: 'Lesen und selbst verwenden',
    text19: 'Regelmäßiges Lesen und Benutzen von Benutzern',
    text20: 'Lesen und Nutzen der wichtigsten Kunden',
    text21: 'Antrag auf ärztliche Zulassung',
    text22: 'Produkt UI, Marketing, Patente, Publikationen',
    text23: 'Standardniveau',
    text24: 'Manuelle Übersetzung+Zwischenkorrektur+Satz+Qualitätsprüfung',
    text25:
      'Der Preis ist niedrig.Intermediate Korrekturlesen: Lesen Sie die Übersetzung gründlich, überprüfen Sie die Fließfähigkeit, Logizität, Professionalität usw. der Sätze',
    text26: 'Berufsstand',
    text27: 'Manuelle Übersetzung+fortgeschrittenes Korrekturlesen+Satz+Qualitätsprüfung',
    text28:
      'Hohe Kosteneffizienz.Erweitertes Korrekturlesen: Überprüfen Sie den Originaltext Wort für Wort und Satz für Satz und decken Sie alle Aspekte der Übersetzungsqualität ab.',
    text29: 'Oberste Ebene',
    text30: 'Manuelle Übersetzung+fortgeschrittenes Korrekturlesen+Sprachpolieren+Satz+Qualitätsprüfung',
    text31:
      'Bessere Qualität.Sprachpolitur-Service: Verbessern Sie die Genauigkeit und Fließfähigkeit des Sprachausdrucks, verbessern Sie die Natürlichkeit und Authentizität der Sprache und machen Sie die Übersetzung lebendiger und charmanter.',
    text32: 'Niveau der Muttersprache',
    text33: 'Manuelle Übersetzung+Korrekturlesen in der Muttersprache+Polieren+Satz+Qualitätsprüfung',
    text34:
      'Reine manuelle Übersetzung mit verfeinerterer Sprache; Das Korrekturlesen auf muttersprachlicher Ebene stellt zudem die Authentizität der Übersetzung sicher und passt sich besser dem Sprachumfeld und dem kulturellen Hintergrund der Zielsprache an.'
  },
  fr: {
    text1: 'Description',
    text2:
      "1, actuellement soutenir la traduction mutuelle chinois - anglais pour passer des commandes en ligne, d'autres traductions linguistiques peuvent consulter le service à la clientèle pour passer des commandes: 028 - 83316191.",
    text3:
      "2, la traduction du certificat de document est cotée par nombre d'exemplaires, traduction chinoise et anglaise: Certificat de document normalisé, tel que: carte d'identité, livret de ménage, licence d'entreprise, certificat de niveau IV - VI, etc. 100 yuans / exemplaire; Certificats de documents non normalisés, tels que relevés de notes, certificats de travail, certificats de propriété, certificats de code d'organisme, etc. (les documents ou les matériaux impliquant une sécurité importante ne sont pas pris en charge) à partir de 100 RMB / exemplaire.Les traductions peuvent être estampillées gratuitement avec le sceau de traduction de l'entreprise (avec les qualifications requises pour la traduction de documents), par courrier postal national (sans Hong Kong et Macao).",
    text4:
      "3, Service spécial de traduction professionnelle: fournir le nom de l'entreprise, le nom du produit, la langue publicitaire, le verset ancien, le menu et d'autres traductions créatives de contenu, facturées par pièce, 298 yuans / pièce.Fournir 2 - 3 options de traduction minutieuses, ainsi que la base de la traduction pour référence.Pour toute question, veuillez contacter le service clientèle au 028 - 83316191.",
    text5:
      "Statistiques et tarification du nombre de mots: le nombre de mots est basé sur les statistiques de document Microsoft Word standard, selon le « nombre de caractères (sans espaces) »; Le texte original est chinois, calculez le prix en fonction du nombre de mots chinois; L'original est en anglais, les prix sont calculés en fonction du nombre de mots anglais.",
    text6: 'Prix des services de traduction',
    text7: 'Direction de la traduction',
    text8: 'Utilisation de la traduction',
    text9: 'Niveau standard (Meta / mille mots)',
    text10: 'Niveau professionnel (RMB / mille mots)',
    text11: 'Niveau supérieur (RMB / mille mots)',
    text12: 'Niveau de langue maternelle (RMB / mille mots)',
    text13: 'Niveau de service de qualité de traduction',
    text14: 'Niveau',
    text15: 'Processus de traduction',
    text16: 'Traduction anglaise',
    text17: 'Traduction anglaise',
    text18: 'Lisez vous - même pour utiliser',
    text19: 'Utilisation de la lecture par les utilisateurs ordinaires',
    text20: "Clients ciblés lire l'utilisation",
    text21: "Déclaration d'enregistrement médical",
    text22: 'Produits ui, marketing, brevets, catégorie publication',
    text23: 'Niveau standard',
    text24: 'Traduction humaine + réviseur intermédiaire + typographie + contrôle de qualité',
    text25: 'Les prix sont bas.Réviseur intermédiaire: lire les traductions, vérifier la conformité des déclarations, la logique, le professionnalisme, etc.',
    text26: 'Niveau professionnel',
    text27: 'Traduction humaine + réviseur Senior + typographie + contrôle qualité',
    text28:
      'Rapport qualité - prix élevé.Réviseur Senior: réviseur mot pour mot par rapport au texte original, couvrant tous les aspects de la qualité de la traduction.',
    text29: 'Niveau supérieur',
    text30: 'Traduction humaine + réviseur Senior + retouche linguistique + typographie + contrôle qualité',
    text31:
      "La qualité est meilleure.Services de retouche linguistique: améliorer la précision et la fluidité de l'expression linguistique, améliorer la Naturalité et l'authenticité de la langue et rendre la traduction plus vivante et attrayante.",
    text32: 'Niveau de langue maternelle',
    text33: 'Traduction humaine + réviseur de langue maternelle + retouche + typographie + contrôle de qualité',
    text34:
      "Traduction purement humaine, avec des termes plus étudiés; Réviseur au niveau de la langue maternelle, garantissant davantage l'authenticité de la traduction et une meilleure adéquation avec l'environnement linguistique et le contexte culturel de la langue cible."
  },
  ru: {
    text1: 'Примечания',
    text2:
      '1. В настоящее время поддерживается онлайн - заказ на китайский и английский переводы, перевод на другие языки можно проконсультироваться с заказом на обслуживание клиентов: 028 - 83316191.',
    text3:
      '2. Перевод сертификата документа по количеству предложений, китайский перевод на английский язык: стандартизированный сертификат документа, например: удостоверение личности, учетная книжка, лицензия на предпринимательскую деятельность, сертификат уровня 4 - 6 и т. Д. 100 юаней / копия; Нестандартизированные сертификаты документов, такие как стенограммы, справки о работе, сертификаты имущества, сертификаты кода организации и т. Д. (не поддерживаются документы или материалы, связанные с важной безопасностью) от 100 юаней / копий.Перевод может быть бесплатным с печатью перевода компании (с необходимой квалификацией для перевода документов), национальным пакетом почтовых отправлений (за исключением Гонконга, Макао и Тайваня).',
    text4:
      '3, Профессиональный перевод Специфические услуги: Предоставляет название компании, название продукта, рекламный язык, древние стихи, меню и другие креативные переводы контента, по частям, 298 юаней / шт.Предоставляет 2 - 3 варианта тщательного перевода, а также основу перевода для справки.По любым вопросам обратитесь за консультацией по гостевому телефону: 028 - 83316191.',
    text5:
      '2. Статистика и оценка количества слов: количество слов в соответствии со статистикой документов Microsoft Word в качестве стандарта, в соответствии с « количеством символов (без учета пробелов) »; Подлинный текст на китайском языке, цена рассчитывается по количеству слов на китайском языке; Подлинный текст на английском языке, статистика цен по количеству слов на английском языке.',
    text6: 'Цены на переводческие услуги',
    text7: 'Направление перевода',
    text8: 'Использование перевода',
    text9: 'Стандартный уровень (в долларах / тысячах слов)',
    text10: 'Профессиональный уровень (в долларах / тысячах слов)',
    text11: 'Старший уровень (юань / тыс. слов)',
    text12: 'Уровень родного языка (юань / тысяча слов)',
    text13: 'Уровень качества перевода',
    text14: 'Уровень',
    text15: 'Процесс перевода',
    text16: 'Китайский перевод',
    text17: 'Перевод на английский язык',
    text18: 'Читайте сами',
    text19: 'Обычные пользователи читают',
    text20: 'Ключевые клиенты читают',
    text21: 'Медицинская регистрация',
    text22: 'Продукт UI, маркетинг, патенты, публикации класса',
    text23: 'Стандартный уровень',
    text24: 'Письменный перевод + Среднее редактирование + типография + проверка качества',
    text25: 'Цены низкие.Средняя редакция: общее чтение перевода, проверка плавности оператора, логика, профессионализм и т. Д.',
    text26: 'Категория специалистов',
    text27: 'Письменный перевод + Старший редактор + Набор + проверка качества',
    text28: 'Высокая рентабельность.Старший редактор: дословное редактирование по сравнению с оригиналом, охватывающее все аспекты качества перевода.',
    text29: 'Старший разряд',
    text30: 'Письменный перевод + Advanced Editor + языковая ретушировка + типография + проверка качества',
    text31:
      'Качество лучше.Услуги по смазке языка: повышение точности и плавности выражения языка, повышение естественности и аутентичности языка, чтобы сделать перевод более ярким и привлекательным.',
    text32: 'Уровень родного языка',
    text33: 'Перевод + редактирование на родном языке + ретуширование + типография + проверка качества',
    text34:
      'Чисто ручной перевод, более изысканный язык; Редакторы на уровне родного языка еще больше гарантируют аутентичность перевода и больше соответствуют языковой среде и культурному контексту целевого языка.'
  },
  th: {
    text1: 'คำแนะนำ',
    text2: '1. ปัจจุบันสนับสนุนการแปลภาษาจีนและภาษาอังกฤษสำหรับการสั่งซื้อออนไลน์การแปลภาษาอื่น ๆ สามารถปรึกษาฝ่ายบริการลูกค้าเพื่อสั่งซื้อ: 028-83316191',
    text3:
      '2, การแปลใบรับรองเอกสารอ้างอิงตามจำนวนฉบับแปลภาษาจีนและภาษาอังกฤษ: ใบรับรองเอกสารมาตรฐานเช่นบัตรประชาชนทะเบียนบ้านใบอนุญาตประกอบธุรกิจใบรับรองระดับ 4-6 ฯลฯ 100 หยวน / ชุด; ใบรับรองเอกสารที่ไม่ได้มาตรฐานเช่นใบรับรองผลการเรียนใบรับรองการทำงานใบรับรองทรัพย์สินใบรับรองรหัสสถาบันขององค์กร ฯลฯ (เอกสารหรือวัสดุที่เกี่ยวข้องกับความปลอดภัยที่สำคัญไม่ได้รับการสนับสนุน) เริ่มต้นที่ 100 หยวน / ชุดการแปลสามารถประทับตราแปลของบริษัทได้ฟรี (มีคุณสมบัติที่จําเป็นสําหรับการแปลเอกสาร) และไปรษณีย์ทั่วประเทศ (ไม่รวมฮ่องกงมาเก๊าและไต้หวัน)',
    text4:
      '3, บริการแปลมืออาชีพพิเศษ: ให้ชื่อ บริษัท ชื่อผลิตภัณฑ์สโลแกนโฆษณาบทกวีโบราณเมนูและการแปลเนื้อหาที่สร้างสรรค์อื่น ๆ เรียกเก็บเงินตามชิ้น 298 หยวน / ชิ้นเสนอทางเลือกในการแปลอย่างรอบคอบ 2-3 แบบและพื้นฐานการแปลเพื่อการอ้างอิงหากมีข้อสงสัย กรุณาติดต่อฝ่ายบริการลูกค้า โทร 028-83316191',
    text5:
      '2 สถิติคำและการนับ: จำนวนคำตามสถิติเอกสาร Microsoft Word เป็นมาตรฐานตาม "จำนวนตัวอักษร (ไม่นับช่องว่าง)" ข้อความต้นฉบับเป็นภาษาจีนคำนวณราคาตามจำนวนคำภาษาจีน ข้อความต้นฉบับเป็นภาษาอังกฤษนับราคาตามจำนวนคำภาษาอังกฤษ',
    text6: 'ราคาบริการแปลเอกสาร',
    text7: 'ทิศทางการแปล',
    text8: 'วัตถุประสงค์การแปล',
    text9: 'ระดับมาตรฐาน (meta / พันคำ)',
    text10: 'ระดับวิชาชีพ (meta / พันคำ)',
    text11: 'ระดับอาวุโส (Meta / พันคำ)',
    text12: 'ระดับภาษาแม่ (meta / พันคำ)',
    text13: 'คุณภาพการแปล ระดับบริการ',
    text14: 'ระดับ',
    text15: 'กระบวนการแปล',
    text16: 'แปลภาษาจีนและภาษาอังกฤษ',
    text17: 'แปลเป็นภาษาอังกฤษ',
    text18: 'อ่านใช้เอง',
    text19: 'การใช้งานการอ่านสำหรับผู้ใช้ทั่วไป',
    text20: 'เน้นการใช้งานของลูกค้า',
    text21: 'ประกาศขึ้นทะเบียนยา',
    text22: 'UI ผลิตภัณฑ์การตลาดสิทธิบัตรการเผยแพร่คลาส',
    text23: 'เกรดมาตรฐาน',
    text24: 'การแปลมนุษย์ + การตรวจสอบระดับกลาง + การพิมพ์ + การตรวจสอบคุณภาพ',
    text25: 'ราคาต่ำการตรวจสอบระดับกลาง: อ่านผ่านการแปลตรวจสอบความถูกต้องของคำสั่งตรรกะความเป็นมืออาชีพ ฯลฯ',
    text26: 'ระดับวิชาชีพ',
    text27: 'การแปลมนุษย์ + การตรวจสอบขั้นสูง + การพิมพ์ + การตรวจสอบคุณภาพ',
    text28: 'ประสิทธิภาพด้านต้นทุนสูงการสอบทานขั้นสูง: สอบทานคำต่อคำเมื่อเทียบกับต้นฉบับครอบคลุมทุกแง่มุมของคุณภาพการแปล',
    text29: 'ระดับอาวุโส',
    text30: 'การแปลมนุษย์ + การตรวจสอบขั้นสูง + การตกแต่งภาษา + การพิมพ์ + การตรวจสอบคุณภาพ',
    text31:
      'คุณภาพดีกว่าบริการขัดเกลาภาษา: ปรับปรุงความถูกต้องและความคล่องแคล่วในการแสดงออกทางภาษาเพิ่มความเป็นธรรมชาติและอุดมคติของภาษาทำให้การแปลมีชีวิตชีวาและมีเสน่ห์มากขึ้น',
    text32: 'ระดับภาษาแม่',
    text33: 'การแปลเทียม + การตรวจสอบภาษาแม่ + การขัดเกลา + การพิมพ์ + การตรวจสอบคุณภาพ',
    text34:
      'การแปลมนุษย์ล้วน ๆ ภาษาที่ประณีตมากขึ้น การตรวจสอบระดับภาษาแม่รับประกันการแปลที่แท้จริงและสอดคล้องกับสภาพแวดล้อมทางภาษาและภูมิหลังทางวัฒนธรรมของภาษาเป้าหมาย'
  },
  es: {
    text1: 'Explicación',
    text2:
      '1. en la actualidad, se admite la traducción chino - británica para hacer pedidos en línea, y las traductoras de otros idiomas pueden consultar al servicio al cliente para hacer pedidos: 028 - 83316191.',
    text3:
      '2. la traducción del certificado de documento se cotiza por número de copias, traducción al chino e inglés: certificado de documento estandarizado, como: tarjeta de identificación, libro de registro de hogares, licencia comercial, certificado de nivel cuatro o seis, etc., 100 yuanes por copia; Certificados de documentos no estandarizados, como transcripciones, certificados de trabajo, certificados de propiedad, certificados de código de la organización, etc. (no se admiten documentos o materiales relacionados con la seguridad importante) de 100 yuanes por pieza.Los traductores pueden sellar el sello de traducción de la empresa de forma gratuita (con las calificaciones necesarias para la traducción de documentos), y el correo gratis nacional (excluyendo Hong kong, Macao y taiwán).',
    text4:
      '3. servicios especiales de traducción profesional: proporciona traducción de contenido creativo como nombre de la empresa, nombre del producto, eslogan publicitario, poesía Antigua y menú, que se cobra por pieza, 298 yuanes por pieza.Proporcionar 2 - 3 opciones de traducción cuidadosa y una base de traducción para referencia.Para cualquier pregunta, consulte el teléfono de atención al cliente: 028 - 83316191.',
    text5:
      '2. estadísticas de número de palabras y valoración: el número de palabras se basa en las estadísticas de documentos de Microsoft Word y se valora de acuerdo con el "número de caracteres (independientemente del espacio)"; El texto original es chino, y el precio se calcula de acuerdo con el número de palabras chinas; El texto original está en inglés y el precio se cuenta de acuerdo con el número de palabras en inglés.',
    text6: 'Precio del Servicio de traducción',
    text7: 'Dirección de la traducción',
    text8: 'Uso de la traducción',
    text9: 'Nivel estándar (yuan / mil palabras)',
    text10: 'Nivel profesional (yuan / mil palabras)',
    text11: 'Nivel superior (yuan / mil palabras)',
    text12: 'Nivel de lengua materna (yuan / mil palabras)',
    text13: 'Nivel de servicio de calidad de la traducción',
    text14: 'Nivel',
    text15: 'Proceso de traducción',
    text16: 'Traducción al chino - inglés',
    text17: 'Traducción al inglés',
    text18: 'Leer y usar por sí mismo',
    text19: 'Lectura y uso por parte de los usuarios comunes',
    text20: 'Uso de lectura de clientes clave',
    text21: 'Declaración de registro médico',
    text22: 'Productos ui, marketing, patentes, publicaciones',
    text23: 'Nivel estándar',
    text24: 'TTraducción manual + revisión intermedia + tipografía + inspección de calidad',
    text25: 'El precio es bajo.Revisión intermedia: leer a través de la traducción, comprobar la fluidez, lógica, profesionalidad, etc.',
    text26: 'Nivel profesional',
    text27: 'TTraducción manual + revisión avanzada + tipografía + inspección de calidad',
    text28:
      'La relación calidad - precio es alta.Revisión avanzada: revisión palabra por palabra en comparación con el texto original, cubriendo todos los aspectos de la calidad de la traducción.',
    text29: 'Nivel superior',
    text30: 'TTraducción manual + revisión avanzada + pulido del lenguaje + composición tipográfica + inspección de calidad',
    text31:
      'La calidad es mejor.Servicio de pulido del lenguaje: mejorar la precisión y fluidez de la expresión del lenguaje, mejorar la naturaleza y la autenticidad del lenguaje y hacer que la traducción sea más vívida y atractiva.',
    text32: 'Nivel de lengua materna',
    text33: 'Traducción manual + revisión de la lengua materna + pulido + tipografía + inspección de calidad',
    text34:
      'Traducción manual pura, términos más exquisitos; El revisor a nivel de lengua materna garantiza aún más la autenticidad de la traducción y se ajusta más al entorno lingüístico y al contexto cultural del idioma objetivo.'
  },
  pt: {
    text1: 'explicar',
    text2:
      '1. Atualmente, apoiamos a colocação de pedidos on-line para a tradução do Chinês Inglês. Para outras traduções de idiomas, consulte o serviço ao cliente em 028-83316191.',
    text3:
      '2. Tradução de certificados de identificação é citado com base no número de cópias. chinês para inglês: Certificados de identificação padronizados, tais como cartões de identificação, livros de registro doméstico, licenças de negócios, certificados de nível 4 e 6, etc., são preços de 100 yuan por cópia; Documentos e certificados não padronizados, como transcrições, certificados de trabalho, certificados de propriedade, certificados de código organizacional, etc. (documentos ou materiais relacionados a segurança importante não são suportados), a partir de 100 yuans por cópia.Todos os documentos traduzidos podem ser carimbados com o selo de tradução da empresa gratuitamente (com as qualificações necessárias para a tradução de documentos) e são gratuitos em todo o país (excluindo Hong Kong, Macau e Taiwan).',
    text4:
      '3. serviços de tradução profissionais: Fornecer tradução de conteúdo criativo para nomes de empresas, nomes de produtos, slogans publicitários, poemas antigos, menus, etc., com uma taxa por peça de 298 yuan.Fornecer 2-3 opções alternativas cuidadosamente traduzidas e base de tradução para referência.Se você tiver alguma dúvida, consulte o serviço ao cliente em 028-83316191.',
    text5:
      '2. contagem de palavras e preços: contagem de palavras é baseada em estatísticas de documentos do Microsoft Word e preço de acordo com "contagem de caracteres (excluindo espaços)"; O texto original é em chinês, e o preço é calculado com base no número de caracteres chineses; O texto original está em inglês e os preços são calculados com base no número de palavras em inglês.',
    text6: 'Preço do serviço de tradução',
    text7: 'Direcção da tradução',
    text8: 'Objectivo da tradução',
    text9: 'Nível padrão (yuan/mil palavras)',
    text10: 'Nível profissional (yuan/mil palavras)',
    text11: 'Nível superior (yuan/mil palavras)',
    text12: 'Nível da língua materna (yuan/mil palavras)',
    text13: 'Nível de qualidade do serviço de tradução',
    text14: 'nível',
    text15: 'Processo de tradução',
    text16: 'Tradução do Chinês para o Inglês',
    text17: 'Tradução de Inglês para Chinês',
    text18: 'Leia e use por conta própria',
    text19: 'Leitura e utilização regulares do utilizador',
    text20: 'Leitura e utilização dos principais clientes',
    text21: 'Pedido de registo médico',
    text22: 'UI do produto, marketing, patentes, publicações',
    text23: 'Nível padrão',
    text24: 'Tradução manual + revisão intermediária + tipografia + inspeção de qualidade',
    text25: 'O preço é baixo.Revisão intermediária: Leia atentamente a tradução, verifique a fluência, lógica, profissionalismo, etc. das frases',
    text26: 'Nível profissional',
    text27: 'Tradução manual + revisão avançada + tipografia + inspeção de qualidade',
    text28:
      'Elevada relação custo-desempenho.Revisão avançada: Verifique o texto original palavra por palavra e frase por frase, cobrindo todos os aspectos da qualidade da tradução.',
    text29: 'Nível superior',
    text30: 'Tradução manual + revisão avançada + polimento de idiomas + tipsetting + inspeção de qualidade',
    text31:
      'Melhor qualidade.Serviço de polimento de idiomas: Melhore a precisão e a fluência da expressão linguística, melhore a naturalidade e a autenticidade da linguagem e torne a tradução mais vívida e encantadora.',
    text32: 'Nível da língua materna',
    text33: 'Tradução manual + revisão da língua nativa + polimento + tipseting + inspeção de qualidade',
    text34:
      'Tradução manual pura, com linguagem mais refinada; A revisão ao nível da língua materna garante ainda mais a autenticidade da tradução e melhor se adapta ao ambiente linguístico e ao contexto cultural da língua de destino.'
  }
}
